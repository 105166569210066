import React from 'react';
import { Row } from 'react-bootstrap';
import './style.css';

export default function Location(){
    return (
        <Row id="location">
            <h1>Local</h1>
            <p>Rua Dr. Renato Paes de Barros 33- Itaim Bibi, 04530-904</p>
        </Row>
    );
}