import utils from "../../../utils";

const INITIAL_STATE = {
    isLoading: false,
    plans: [],
    formatted: function(){
        return this.map(plan=>({
            id: plan.id,
            name: plan.name,
            description: plan.description,
            pricePresential: utils.formatPrice(plan.pricePresential),
            priceOnline: utils.formatPrice(plan.priceOnline)
        }));
    }
}

const planReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'LOAD_PLAN':
            return {
                ...state, 
                plans: payload,
                isLoading: false
            };
        case 'IS_LOADING_PLAN':
            return {
                ...state,
                isLoading: payload
            };
        case 'UPDATE_PLAN':
            return {
                ...state, 
                plans: state.plans.map(plan=>(plan.id === payload.id) ? payload : plan)
            };
        default:
            return state;
    }
}

export default planReducer;