let INITIAL_STATE = {
    isLogged: false,
    user: null,
    socket: null,
    token: null,
    accessTokenExpired: null,
    refreshToken: null,
    fnSetInterval: null
}

const accountReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'LOGIN':
            return { ...state,
                isLogged: true,
                user: payload.user,
                token: payload.token,
                refreshToken: payload.refreshToken
            };
        case 'SET_INTERVAL_REFRESH_TOKEN':
            return { ...state,
                setInterval: payload.fnSetInterval
            };
        case 'ACCESS_TOKEN_EXPIRED':
            return { ...state,
                accessTokenExpired: payload
            };
        case 'REFRESH_TOKEN':
            const reduxLocalstorageSimpleAccountState = JSON.parse(localStorage.getItem('redux_localstorage_simple_accountState'));
            reduxLocalstorageSimpleAccountState.refreshToken = payload.refreshToken;
            reduxLocalstorageSimpleAccountState.token = payload.token;
            
            localStorage.setItem('redux_localstorage_simple_accountState', JSON.stringify(reduxLocalstorageSimpleAccountState));
            return { ...state,
                token: payload.token,
                refreshToken: payload.refreshToken
            };
        case 'UPDATE_ACCOUNT':
            return { ...state,
                user: payload
            };
        case 'LOGOUT':
            clearInterval(state.fnSetInterval);
            return { ...state,
                isLogged: false,
                user: {},
                socket: {},
                token: {},
                refreshToken: {}
            };
        case 'SET_SOCKET':
            return { ...state,
                socket: payload
            };
        default:
            return state;
    }
}

export default accountReducer;