import { 
    faChartLine,
    faUser,
    faHandshake,
    faLink,
    faBuilding,
    faStethoscope,
    faFaceMehBlank,
    faBook,
    faUsersBetweenLines,
    faMoneyBill,
    faCalendar
} from '@fortawesome/free-solid-svg-icons';

export default {
    pages: {
        admin: [
            {
                title: 'Dashboard',
                icon: faChartLine,
                url: '/admin/dashboard'
            },
            {
                title: 'Atendimentos',
                icon: faStethoscope,
                url: '/admin/atendimentos'
            },
            {
                title: 'Planos',
                icon: faBook,
                url: '/admin/planos'
            },
            {
                title: 'Unidades',
                icon: faBuilding,
                url: '/admin/unidades'
            },
            {
                title: 'Motivos',
                icon: faFaceMehBlank,
                url: '/admin/motivos'
            },
            {
                title: 'Match',
                icon: faLink,
                url: '/admin/match'
            },
            {
                title: 'Políticas',
                icon: faHandshake,
                url: '/admin/politicas'
            },
            {
                title: 'Usuários',
                icon: faUser,
                url: '/admin/usuarios'
            }
        ],
        paciente: [
            {
                title: 'Agenda',
                icon: faCalendar,
                url: '/paciente/agenda'
            },
            {
                title: 'Dependentes',
                icon: faUsersBetweenLines,
                url: '/paciente/dependentes'
            },
            {
                title: 'Pagamentos',
                icon: faMoneyBill,
                url: '/paciente/historico-de-pagamentos'
            }
        ],
        psicologo: [
            {
                title: 'Dashboard',
                icon: faChartLine,
                url: '/psicologo/dashboard'
            },
            {
                title: 'Agenda',
                icon: faUsersBetweenLines,
                url: '/psicologo/agenda'
            }
        ]
    },
    host: '',
    phone: '5511988204721'
}

/*
    //O host é o backend que o frontend está enxergando
    Host usado quando react roda em modo de desenvolvimento => 'http://localhost:8888'
    Host usado quando gera a build do react e é colocado para rodar junto com o backend => ''
*/