import React, { useState, useEffect } from 'react';
import Edit from '../../Components/Edit';
import { Tab, Tabs, Button, Spinner, Container, Row, Col } from 'react-bootstrap';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { http } from '../../services/index';
import utils from './../../utils';

export default function Policy(){

    const dispatch = useDispatch();

    const { privacy, term, isLoadingPrivacy, isLoadingTerm } = useSelector(state=>state.policyState);
    const [btnPrivacyDisabled, setBtnPrivacyDisabled] = useState(true);
    const [btnTermDisabled, setBtnTermDisabled] = useState(true);

    const [privacyContent, setPrivacyContent] = useState(privacy);
    const [termContent, setTermContent] = useState(term);

    useEffect(()=>{
        setPrivacyContent(privacy);
        setTermContent(term);
    }, [privacy, term]);

    const changePrivacy = function(value){
        setBtnPrivacyDisabled(false);
        setPrivacyContent(value);
    }

    const changeTerm = function(value){
        setBtnTermDisabled(false);
        setTermContent(value);
    }

    const discardPrivacy = ()=>{
        setBtnPrivacyDisabled(true);
        window.location.reload(false);
    }

    const discardTerm = ()=>{
        setBtnTermDisabled(true);
        window.location.reload(false);
    }

    const savePrivacy = ()=>{
        setBtnPrivacyDisabled(true);
        http.policy.privacy.update({content: privacyContent})
            .then(()=>{
                utils.createNotification({
                    type: 'success',
                    title: 'Políticas de privacidade atualizadas!'
                })
            })
            .catch(error=>{
                setBtnPrivacyDisabled(false);
                utils.createNotification({
                    type: 'error',
                    title: 'Erro ao editar políticas de privacidade!',
                    message: error.response.data
                })
            });
    }

    const saveTerm = ()=>{
        setBtnTermDisabled(true);
        http.policy.term.update({content: termContent})
            .then(()=>{
                utils.createNotification({
                    type: 'success',
                    title: 'Termos de uso atualizados!'
                })
            })
            .catch(error=>{
                setBtnTermDisabled(false);
                utils.createNotification({
                    type: 'error',
                    title: 'Erro ao editar termos de uso!',
                    message: error.response.data
                })
            });
    }

    useEffect(()=>{
        dispatch({type: "SET_PAGE", payload: window.location.pathname});
        http.policy.privacy.findAll()
            .then(result=>{
                dispatch({
                    type: 'LOAD_PRIVACY',
                    payload: {
                        privacy: result && result.data ? result.data : ''
                    }
                });
            })
            .catch(error=>{
                utils.createNotification({
                    type: 'error',
                    title: 'Erro ao buscar políticas de privacidade!',
                    message: error.response.data
                })
            });
        http.policy.term.findAll()
            .then(result=>{
                dispatch({
                    type: 'LOAD_TERM',
                    payload: {
                        term: result && result.data ? result.data : ''
                    }
                });
            })
            .catch(error=>{
                utils.createNotification({
                    type: 'error',
                    title: 'Erro ao buscar termos de uso!',
                    message: error.response.data
                })
            });
    }, []);

    return (
        <div id="policy">
            <Container id="policy" >
            <Row>
                <Col>
                    <Tabs
                        defaultActiveKey="privacy"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        >
                        <Tab eventKey="privacy" title="Privacidade">
                            {
                                privacyContent === null || isLoadingPrivacy ? <Spinner animation="border" /> : 
                                <>
                                    <Edit changePolicy={ changePrivacy } content={ privacyContent } />
                                    <Button onClick={savePrivacy} disabled={ btnPrivacyDisabled }>Salvar</Button>
                                    <Button onClick={discardPrivacy} disabled={ btnPrivacyDisabled }>Descartar alterações</Button>
                                </>
                            }
                        </Tab>
                        <Tab eventKey="terms" title="Termos de Uso">
                            {
                                termContent === null || isLoadingTerm ? <Spinner animation="border" /> : 
                                <>
                                    <Edit changePolicy={ changeTerm } content={ termContent } />
                                    <Button onClick={saveTerm} disabled={ btnTermDisabled }>Salvar</Button>
                                    <Button onClick={discardTerm} disabled={ btnTermDisabled }>Descartar alterações</Button>
                                </>
                            }
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </Container>
        </div>
    );
}