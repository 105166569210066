import React, { useState } from 'react';
import { websocket } from './../../services';
import { ListGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import './style.css';
import { useSelector, useDispatch } from 'react-redux';
import utils from './../../utils';

export default function NotificationBell(){

    const notifications = useSelector(state=>state.notificationState.notifications);
    const dispatch = useDispatch();
    const isOpen = useSelector(state=>state.notificationState.open);

    function viewNotification(id) {

        let notification = notifications.find(item=>item.id === id);

        dispatch({type: 'OPEN_MODAL_INFO', payload: { title: notification.title, description: notification.description }});
        dispatch({type: 'VIEW_NOTIFICATION', payload: { id }});

        websocket.notification.view(id)
            .catch(error=>{
                dispatch({type: 'INCLUDE_NOTIFICATION', payload: notification});
                utils.createNotification({
                    type: 'error',
                    title: 'Erro ao visualizar notificação',
                    message: error.message
                });
            });

    }

    return (
        <div id='notification-bell'>
                <Button
                    className='icon-bell'
                    onClick={ ()=>{
                        if(notifications.length) dispatch({type: 'TOGGLE_BELL_NOTIFICATION'});
                    } }
                >
                    { notifications.length ? <div className='amount'>{ notifications.length }</div> : '' }
                    <FontAwesomeIcon
                        icon={ faBell }
                    />
                </Button>
            <ListGroup style={{display: isOpen ? "block" : "none"}} as="ol">
                {
                    notifications.map(({id, title, description})=>(
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                            onClick={() => viewNotification(id)}
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">{ title }</div>
                            {
                                utils.formatNotification(description)
                            }
                            </div>
                        </ListGroup.Item>
                    ))
                }
            </ListGroup>
        </div>
    );
}