import React from 'react';
import { Row } from 'react-bootstrap';
import config from '../../config';
import utils from '../../utils';
import './style.css';

export default function Social(){
    return (
        <Row id='phone'>
            <h1>Telefone</h1>
            <p>{ utils.formatPhone(config.phone) }</p>
        </Row>
    );
}