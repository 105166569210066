import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Form, Button } from 'react-bootstrap';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import utils from '../../utils';

export default function Plan({id, name, description, pricePresential, priceOnline, image, imageSelected}){

    const dispatch = useDispatch();
    const { planId, modality } = useSelector(state=>state.registerPatientState.info);
    const [isSelected, setIsSelected] = useState((Number(id) === Number(planId)));

    const [modalityCurrent, setModalityCurrent] = useState(isSelected ? modality : 'online');

    useEffect(()=>{

        setIsSelected(Number(id) === Number(planId));

    }, [Number(id) === Number(planId)]);

    useEffect(()=>{
        if(isSelected && modalityCurrent){
            dispatch({
                type: 'INCLUDE_REGISTER_PATIENT',
                payload: { modality: modalityCurrent }
            });
            //if(window.location.pathname === '/') window.scrollTo(0, 0);
        }
    }, [isSelected, modalityCurrent]);

    useEffect(()=>{
        if(isSelected){
            setModalityCurrent(modality);
        }
    }, [modality]);

    return (
        <Card className={ 'plan' + (isSelected ? ' selected' : '') }>
            <Card.Header>
                <h2>{ name }</h2>
            </Card.Header>
            <Card.Body>
                <Card.Img src={ isSelected ? imageSelected : image } />
                <Card.Text>{ description }</Card.Text>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Select value={modalityCurrent} onChange={e=>/*dispatch({
                            type: 'INCLUDE_REGISTER_PATIENT',
                            payload: { modality: e.target.value }
                        })*/setModalityCurrent(e.target.value)}>
                            <option value="presencial">Presencial</option>
                            <option value="online">Online</option>
                        </Form.Select>
                    </Form.Group>
                </Form>
                <div>
                    <Card.Title><b>{ utils.formatPrice(modalityCurrent === 'online' ? priceOnline : pricePresential) }</b> <span>/ mês</span></Card.Title>
                    <Link to="/carrinho">
                        <Button onClick={()=>dispatch({
                            type: 'INCLUDE_REGISTER_PATIENT',
                            payload: {
                                planId: id
                            }
                        })}>{ isSelected ? 'Selecionado' : 'Selecionar' }</Button>
                    </Link>
                </div>
            </Card.Body>
        </Card>
    );
}