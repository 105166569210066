import { NotificationManager } from 'react-notifications';
import moment from 'moment';
import { validate as uuidValidate } from 'uuid';
import Cookies from 'universal-cookie';
import jwt_decode from "jwt-decode";
import store from './../store';
import http from './../services/http';

const cookie = new Cookies();

const defaultOptions = {
  path: '/'
};

export default {
  verifyAuth(){
    //const { refreshToken, token } = JSON.parse(localStorage.getItem('redux_localstorage_simple_accountState'));
    const token = store.getState().accountState.token;
    if(!token || !Object.keys(token).length) return false;
    let exp = (jwt_decode(token)).exp;
    let now = Math.ceil(moment.now() / 1000);
    let diff = exp - now;

    return diff > 0;
    /*if(diff <= 0){
      http.user.refresh(null, true)
        .then(result=>{
          store.dispatch({
              type: 'REFRESH_TOKEN',
              payload: {
                  user: result.data.user,
                  token: result.data.token,
                  refreshToken: result.data.refreshToken
              }
          });
        })
        .catch(()=>store.dispatch({type: 'LOGOUT'}));
    }*/
  },
  cookie: {
    get(name, options = {}){
      if(!name) return null;
      return cookie.get(name, {...defaultOptions, ...options});
    },
    set(name, value, options = {}){
      if(!name || value === undefined) return null;
      cookie.set(name, value, {...defaultOptions, ...options});
      return true;
    },
    remove(name, options = {}){
      if(!name) return null;
      cookie.remove(name, {...defaultOptions, ...options});
    }
  },
  isUUID(data){
    return uuidValidate(data);
  },
  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },
  isEmail(str){
    const exp = /\S+@\S+\.\S+/;
    return exp.test(str);
  },
  isDate(date){
    if(typeof date != 'string') return false;
    let datePart = date.substring(0, 10);
    let datePartArr = datePart.split('-');
    if(datePartArr.length != 3) return false;
    if(datePartArr.find(d=>isNaN(Number(d)))) return false;
    return true;
  },
  formatStatusPayment(status){
    return {
      AUTHORIZED: 'Pré-autorizado',
      PAID: 'Pago',
      IN_ANALYSIS: 'Em análise',
      DECLINED: 'Negado',
      CANCELED: 'Cancelado'
    }[status] || status;
  },
  formatBoolean(value){
    return value ? 'Sim' : 'Não';
  },
  formatPrice(price){
    if(!price) return price;
    let newPrice = `R$${Number(price).toFixed(2).toString().replace('.', ',')}`;
    return newPrice;
  },
  formatDate(date){
    return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
  },
  formatPhone(phone){
    let ddd = phone.substring(2, 4);
    let part1 = [11, 13].includes(phone.length) ? phone.substring(4, 9) : phone.substring(4, 8);
    let part2 = [11, 13].includes(phone.length) ? phone.substring(9) : phone.substring(8);

    return `(${ddd}) ${part1}-${part2}`;
  },
  getType(data){
    if(this.isUUID(data)) return 'uuid';
    if(this.isEmail(data)) return 'email';
    if(this.isDate(data)) return 'date';
    if(typeof data === 'boolean') return 'boolean';
    return 'string';
  },
  formatData(type, data){
    if(type === 'date') return this.formatDate(data);
    if(type === 'boolean') return data ? 'Sim' : 'Não';
    if(type === 'string') return this.capitalize(data);
    return data;
  },
  formatObj(obj){
    return Object
      .entries(obj)
      .reduce((acc, current)=>{
        let newObj = { ...acc };
        newObj[current[0]] = this.formatData(this.getType(current[1]), current[1]);
        return newObj;
      }, {});
  },
  formatNotification(text){
    return text.substring(0, 35);
  },
  translate(text){
    return ({
        sunday: 'domingo',
        monday: 'segunda-feira',
        tuesday: 'terça-feira',
        wednesday: 'quarta-feira',
        thursday: 'quinta-feira',
        friday: 'sexta-feira',
        saturday: 'sábado'
    }[text] || text);
  },
  dayWeek(text){
    return ({
        0: 'domingo',
        1: 'segunda-feira',
        2: 'terça-feira',
        3: 'quarta-feira',
        4: 'quinta-feira',
        5: 'sexta-feira',
        6: 'sábado'
    }[text] || text);
  },
  createNotification({type, title, message, timing, callback}){
      switch(type) {
          case 'info':
            NotificationManager.info(message, title);
            break;
          case 'success':
            NotificationManager.success(message, title, timing || 5000);
            break;
          case 'warning':
            NotificationManager.warning(message, title, timing || 5000);
            break;
          case 'error':
            NotificationManager.error(message, title, timing || 5000, callback);
            break;
      }
  },
  queryCreate({filters, activePage}){
    let query = Object.entries(filters).reduce((acc, current)=>{
      let value = current[0] === 'activePage' && activePage ? activePage : current[1];
      if(!isNaN(value)) value = `${value}`;
      let addStr = value.length > 0 && current[0] != 'totalPages' ? `&${current[0]}=${value}` : '';
      return `${acc}${addStr}`;
    }, '');
    return query;
  }
}