const INITIAL_STATE = {
    isLoading: false,
    configUtils: [],
}

const configUtilsReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'LOAD_CONFIG_UTILS':
            return {
                ...state, 
                configUtils: payload,
                isLoading: false
            };
        default:
            return state;
    }
}

export default configUtilsReducer;