import React, { useState, useEffect } from 'react';
import Table from '../../Components/Table';
import Modal from '../../Components/Modal';
import { Form, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { http } from '../../services';
import utils from './../../utils';
import store from '../../store';
import moment from 'moment';

const config = {
    columns: [
        {
            name: 'id',
            translate: 'Id'
        },
        {
            name: 'name',
            translate: 'Nome'
        },
        {
            name: 'rg',
            translate: 'RG'
        },
        {
            name: 'cpf',
            translate: 'CPF'
        },
        {
            name: 'birth',
            translate: 'Idade'
        },
        {
            name: 'dt_birth',
            translate: 'Data de Nascimento',
            type: 'date'
        }
    ],
    include: {
        cta: "Incluir",
        title: 'Incluir dependente',
        ctaFn: ()=>{
            http.dependent.create(store.getState().formState.form)
                .then(result=>{
                    store.dispatch({
                        type: 'INCLUDE_DEPENDENT',
                        payload: result.data
                    });
                    utils.createNotification({
                        type: 'success',
                        title: 'Dependente adicionado'
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: 'Erro ao adicionar dependente',
                    message: error.response.data
                }))
                .finally(()=>store.dispatch({
                    type: 'CLEAR_FORM'
                }));
        }
    },
    update: {
        cta: "Editar",
        title: 'Editar dependente',
        ctaFn: ({id})=>{
            http.dependent.update(id, store.getState().formState.form)
                .then(result=>{
                    store.dispatch({
                        type: 'UPDATE_DEPENDENT',
                        payload: result.data
                    });
                    utils.createNotification({
                        type: 'success',
                        title: `As informações do dependente ${id} foram alteradas!`
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: `Erro ao editar dependente ${id}`,
                    message: error.response.data
                }))
                .finally(()=>store.dispatch({
                    type: 'CLEAR_FORM'
                }));
        }
    },
    remove: {
        cta: "Remover",
        title: 'Remover dependente',
        ctaFn: ({ id })=>{
            http.dependent.destroy(id)
                .then(()=>{
                    store.dispatch({
                        type: 'DELETE_DEPENDENT',
                        payload: {
                            id
                        }
                    });
                    utils.createNotification({
                        type: 'success',
                        title: 'Dependente removido',
                        message: `O dependente ${id} foi removido!`
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: `Erro ao remover dependente ${id}`,
                    message: error.response.data
                }));
        },
        style: {
            backgroundColor: 'var(--color-danger)'
        }
    }
}

function IncludeContent(){

    const [name, setName] = useState('');
    const [rg, setRg] = useState('');
    const [cpf, setCpf] = useState('');
    const [dtBirth, setDtBirth] = useState('');

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch({
            type: 'SAVE_FORM',
            payload: {
                name, rg, cpf, dtBirth
            }
        });
    }, [name, rg, cpf, dtBirth]);

    return (
        <Form>
            <Form.Group className="mb-3" controlId="formFullname">
                <Form.Label>Nome completo</Form.Label>
                <Form.Control
                    value={ name }
                    onChange={ e=>setName(e.target.value) }
                    type="text"
                    placeholder="Digite o nome completo do dependente"
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formCpf">
                <Form.Label>CPF</Form.Label>
                <Form.Control
                    value={ cpf }
                    onChange={ e=>setCpf(e.target.value) }
                    type="text"
                    placeholder="Digite o cpf do dependente"
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formRg">
                <Form.Label>RG</Form.Label>
                <Form.Control
                    value={ rg }
                    onChange={ e=>setRg(e.target.value) }
                    type="text"
                    placeholder="Digite o rg do dependente"
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBirth">
                <Form.Label>Data de nascimento</Form.Label>
                <Form.Control
                    value={ dtBirth }
                    onChange={ e=>setDtBirth(e.target.value) }
                    type="date"
                />
            </Form.Group>
        </Form>
    );
}

function UpdateContent(props){

    const [name, setName] = useState(props.name);
    const [rg, setRg] = useState(props.rg);
    const [cpf, setCpf] = useState(props.cpf);
    const [dtBirth, setDtBirth] = useState(moment(props.dt_birth).format('YYYY-MM-DD'));

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch({
            type: 'SAVE_FORM',
            payload: {
                name, rg, cpf, dtBirth
            }
        });
    }, [name, rg, cpf, dtBirth]);

    return (
        <Form>
            <Form.Group className="mb-3" controlId="formFullname">
                <Form.Label>Nome completo</Form.Label>
                <Form.Control
                    value={ name }
                    onChange={e=>setName(e.target.value)}
                    type="text"
                    placeholder="Digite o nome completo do dependente"
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formCpf">
                <Form.Label>CPF</Form.Label>
                <Form.Control
                    value={ cpf }
                    onChange={e=>setCpf(e.target.value)}
                    type="text"
                    placeholder="Digite o cpf do dependente"
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formRg">
                <Form.Label>RG</Form.Label>
                <Form.Control
                    value={ rg }
                    onChange={e=>setRg(e.target.value)}
                    type="text"
                    placeholder="Digite o rg do dependente"
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBirth">
                <Form.Label>Data de nascimento</Form.Label>
                <Form.Control
                    value={ dtBirth }
                    onChange={e=>setDtBirth(e.target.value)}
                    type="date"
                />
            </Form.Group>
        </Form>
    );
}

function RemoveContent({ id }){
    return (
        <p>Deseja remover o dependente {id}?</p>
    );
}

function TableActions(props){

    return (
        <>
            <Modal
                config={ config.update }
                row={ props.row }
                content={ <UpdateContent
                    { ...props.row }
                /> }
            />
            <Modal
                config={ Object.assign({}, config.remove, { data: { id: props.row.id } }) }
                row={ props.row }
                content={ <RemoveContent
                    { ...props.row }
                /> }
            />
        </>
    );
}

export default function Dependent(){

    const { isLoading, dependents } = useSelector(state=>state.dependentState);
    const dispatch = useDispatch();

    useEffect(()=>{

        dispatch({
            type: 'IS_LOADING_DEPENDENT',
            payload: true
        });

        http.dependent.findAll()
            .then(result=>{
                dispatch({
                    type: 'LOAD_DEPENDENT',
                    payload: result.data
                });
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Erro ao carregar dependentes',
                message: error.response.data
            }))
            .finally(()=>dispatch({
                type: 'IS_LOADING_DEPENDENT',
                payload: false
            }));
    }, []);

    return (
        <div id='table'>
            <Modal
                config={ config.include }
                content={ <IncludeContent
                /> }
            />
            {
                isLoading ? <div><Spinner animation="border" /></div> :
                <Table
                    data={ dependents }
                    config={ config }
                    Actions={ TableActions }
                />
            }
        </div>
    );
}