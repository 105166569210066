import Trix from "trix";
import React, { useState, useEffect } from "react";
import { ReactTrixRTEInput } from "react-trix-rte";

export default function TrixEditor({ content, changePolicy }) {

  function handleChange(event, newValue) {
    changePolicy(newValue);
  }

  return (
    <>
      <ReactTrixRTEInput
        defaultValue={ content }
        onChange={ handleChange }
      />
    </>
  )
}