import { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import './style.css';

function CalendarC({ changeDate }) {
  const [date, setDate] = useState(new Date());

  useEffect(()=>{
    changeDate(date);
  }, [date]);

  return (
    <div className='app'>
      <div className='calendar-container'>
        <Calendar onChange={setDate} value={date} />
      </div>
    </div>
  );
}

export default CalendarC;