import React from 'react';
import { Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import './style.css';

export default function Social(){
    return (
        <Row id='social'>
            <h1>Siga-nos</h1>
            <a
                href='https://www.facebook.com/Cl%C3%ADnica-PsyUp-119538974441962'
                target="_blank"
            >
                <FontAwesomeIcon
                    style={{color: "#3b5998"}}
                    icon={ faFacebook }
                />
            </a>
            <a
                href='https://www.instagram.com/clinicapsyup'
                target="_blank"
            >
                <FontAwesomeIcon
                    style={{color: "#fff"}}
                    icon={ faInstagram }
                />
            </a>
            <a
                href='https://www.youtube.com/@ClinicaPsyUp'
                target="_blank"
            >
                <FontAwesomeIcon
                    style={{color: "#c4302b"}}
                    icon={ faYoutube }
                />
            </a>
            <a
                href='https://www.linkedin.com/company/clinica-psy-up/'
                target="_blank"
            >
                <FontAwesomeIcon
                    style={{color: "#0e76a8"}}
                    icon={ faLinkedin }
                />
            </a>
            <a
                href='mailto:adm@psyup.com.br'
                target="_blank"
            >
                <FontAwesomeIcon
                    style={{color: "#264653"}}
                    icon={ faEnvelope }
                />
            </a>
        </Row>
    );
}