import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import './style.css';
import Plan from '../Plan';
import { useSelector } from 'react-redux';

export default function Services(){

    const { isLoading, plans } = useSelector(state=>state.planState);

    return (
        <Container id='services'>
            <h1>Planos</h1>
            <Row>
                {
                    plans.map(plan=>(
                        <Col sm={12} md={6} lg={4}>
                            <Plan
                                id={ plan.id }
                                name={ plan.name }
                                image={ plan.image }
                                imageSelected={ plan.imageSelected }
                                description={ plan.description }
                                pricePresential={ plan.pricePresentialMonthly }
                                priceOnline={ plan.priceOnlineMonthly }
                            />
                        </Col>
                    ))
                }
            </Row>
        </Container>
    );
}