const INITIAL_STATE = {
    isLoading: false,
    matches: [],
    filters: {
        activePage: 1,
        totalPages: 1
    }
}

const matchReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'LOAD_MATCH':
            return {
                ...state, 
                matches: payload.rows.map(row=>{
                    let newRow = Object.assign({}, row);
                    newRow.psicologoName = newRow.psicologouser.name;
                    newRow.pacienteName = newRow.pacienteuser.name;
                    return newRow;
                }),
                filters: {
                    ...state.filters,
                    totalPages: payload.totalPages
                },
                isLoading: false
            };
        case 'IS_LOADING_MATCH':
            return {
                ...state,
                isLoading: payload
            };
        case 'INCLUDE_MATCH':
            return {
                ...state, 
                matches: [...state.matches, payload]
            };
        case 'UPDATE_MATCH':
            return {
                ...state, 
                matches: state.matches.map(match=>(match.id === payload.id) ? payload : match)
            };
        case 'FILTER_MATCH':
            return {
                ...state, 
                filters: Object.assign({}, state.filters, payload)
            };
        case 'DELETE_MATCH':
            return {
                ...state, 
                matches: state.matches.filter(match=>match.id != payload.id)
            };
        default:
            return state;
    }
}

export default matchReducer;