import React, { useEffect } from 'react';
import Table from '../../Components/Table';
import { Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { http } from '../../services';
import utils from './../../utils';
import store from '../../store';

const config = {
    columns: [
        {
            name: 'id',
            translate: 'Id'
        },
        {
            name: 'created_at',
            translate: 'Data',
            type: 'date'
        },
        {
            name: 'value',
            translate: 'Valor',
            type: 'currency'
        }
    ],
    include: {
        cta: "Incluir",
        title: 'Incluir dependente',
        ctaFn: ()=>{
            http.dependent.create(store.getState().formState.form)
                .then(result=>{
                    store.dispatch({
                        type: 'INCLUDE_DEPENDENT',
                        payload: result.data
                    });
                    utils.createNotification({
                        type: 'success',
                        title: 'Dependente adicionado'
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: 'Erro ao adicionar dependente',
                    message: error.response.data
                }))
                .finally(()=>store.dispatch({
                    type: 'CLEAR_FORM'
                }));
        }
    },
    update: {
        cta: "Editar",
        title: 'Editar dependente',
        ctaFn: ({id})=>{
            http.dependent.update(id, store.getState().formState.form)
                .then(result=>{
                    store.dispatch({
                        type: 'UPDATE_DEPENDENT',
                        payload: result.data
                    });
                    utils.createNotification({
                        type: 'success',
                        title: `As informações do dependente ${id} foram alteradas!`
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: `Erro ao editar dependente ${id}`,
                    message: error.response.data
                }))
                .finally(()=>store.dispatch({
                    type: 'CLEAR_FORM'
                }));
        }
    },
    remove: {
        cta: "Remover",
        title: 'Remover dependente',
        ctaFn: ({ id })=>{
            http.dependent.destroy(id)
                .then(()=>{
                    store.dispatch({
                        type: 'DELETE_DEPENDENT',
                        payload: {
                            id
                        }
                    });
                    utils.createNotification({
                        type: 'success',
                        title: 'Dependente removido',
                        message: `O dependente ${id} foi removido!`
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: `Erro ao remover dependente ${id}`,
                    message: error.response.data
                }));
        },
        style: {
            backgroundColor: 'var(--color-danger)'
        }
    }
}

export default function Payments(){

    const { isLoading, payments } = useSelector(state=>state.paymentState);
    const dispatch = useDispatch();

    useEffect(()=>{

        dispatch({
            type: 'IS_LOADING_PAYMENT',
            payload: true
        });

        http.user.findPayments()
            .then(result=>{
                dispatch({
                    type: 'LOAD_PAYMENT',
                    payload: result.data
                });
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Erro ao carregar pagamentos',
                message: error.response.data
            }))
            .finally(()=>dispatch({
                type: 'IS_LOADING_PAYMENT',
                payload: false
            }));
    }, []);

    return (
        <div id='table'>
            {
                isLoading ? <div><Spinner animation="border" /></div> :
                <Table
                    data={ payments }
                    config={ config }
                />
            }
        </div>
    );
}