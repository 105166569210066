import React from 'react'

import { FloatingWhatsApp } from 'react-floating-whatsapp-button'
import 'react-floating-whatsapp-button/dist/index.css'

export default function ButtonWhatsApp({ popupMessage, phone }){
  return (
    <FloatingWhatsApp
      popupMessage={ popupMessage }
      phone={ phone }
      zIndex="1"
    />
  );
}