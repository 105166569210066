const INITIAL_STATE = {
    open: false,
    title: '',
    description: ''
}

const modalInfoReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'OPEN_MODAL_INFO':
            return { ...state, open: true, title: payload.title, description: payload.description };
        case 'CLOSE_MODAL_INFO':
            return { ...state, open: false, title: '', description: '' };
        default:
            return state;
    }
}

export default modalInfoReducer;