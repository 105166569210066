import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './style.css';
import { Form, Button, Card } from 'react-bootstrap';
import { http } from '../../services';
import utils from './../../utils';
import { useSelector, useDispatch } from 'react-redux';
import HomeSelectDateLocationRegisterPatient from '../HomeSelectDateLocationRegisterPatient';
import useIsSuccessRegisterPatient from '../../hooks/useIsSuccessRegisterPatient';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowRight } from '@fortawesome/free-solid-svg-icons';

function FirstStage(props){

    const dispatch = useDispatch();
    const { modality, planId } = useSelector(state=>state.registerPatientState.info);
    const status = useIsSuccessRegisterPatient();

    const { isLoading, plans } = useSelector(state=>state.planState);

    const [plan, setPlan] = useState(null);

    useEffect(()=>{
        http.plan.findAll()
            .then(result=>{
                dispatch({
                    type: 'LOAD_PLAN',
                    payload: result.data
                });
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Erro ao carregar planos',
                message: error.response.data
            }))
            .finally(()=>dispatch({
                type: 'IS_LOADING_PLAN',
                payload: false
            }));
    }, []);

    useEffect(()=>{
        let plan = plans.find(p=>p.id===(Number(planId) || 1));
        setPlan(plan);
    }, [planId, plans, modality]);

    return (
        <>
            <Card className={ `stage-first${Number(planId) && modality ? ' active' : ''}` }>
                <Card.Body>
                    <div className='title'>
                        <p><b>01</b></p>
                    </div>
                    <div className='content'>
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Select value={ planId } onChange={e=>{
                                    props.setUpdate(true);
                                    dispatch({
                                    type: 'INCLUDE_REGISTER_PATIENT',
                                    payload: { planId: e.target.value }
                                    });
                                }}>
                                    <option value={ 0 }></option>
                                    {
                                        plans.map(p=><option value={ p.id }>{p.name}</option>)
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Check
                                    checked={ modality === 'presencial' }
                                    type="radio"
                                    label="Presencial"
                                    onClick={()=>{
                                        props.setUpdate(true);
                                        dispatch({
                                            type: 'INCLUDE_REGISTER_PATIENT',
                                            payload: { modality: 'presencial' }
                                        });
                                    }}
                                />
                                <Form.Check
                                    checked={ modality === 'online' }
                                    onClick={()=>{
                                        props.setUpdate(true);
                                        dispatch({
                                            type: 'INCLUDE_REGISTER_PATIENT',
                                            payload: { modality: 'online' }
                                        });
                                    }}
                                    type="radio"
                                    label="Online"
                                />
                            </Form.Group>
                        </Form>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
}

function SecondStage(props){
    return (
        <HomeSelectDateLocationRegisterPatient setUpdate={ props.setUpdate } />
    );
}

function ThirdStage(props){

    const reasons = useSelector(state=>state.reasonState.reasons);
    const dispatch = useDispatch();
    const { reasonId } = useSelector(state=>state.registerPatientState.info);

    return (
        <Card className={ `stage-third${Number(reasonId) ? ' active' : ''}` }>
            <Card.Body>
                <div className='title'>
                    <p><b>03</b></p>
                </div>
                <div className='content'>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Select value={ reasonId } onChange={e=>{
                            props.setUpdate(true);
                            dispatch({
                                type: 'INCLUDE_REGISTER_PATIENT',
                                payload: { reasonId: e.target.value }
                            });
                        }}>
                            <option value={0}></option>
                            {
                                reasons.map(reason=><option value={reason.id}> {reason.description} </option>)
                            }
                        </Form.Select>
                    </Form.Group>
                    <p>Escolha o motivo que o levou a buscar ajuda</p>
                </Form>
                </div>
            </Card.Body>
        </Card>
    );
}

export default function Home(props){

    const dispatch = useDispatch();
    const { reasonId, modality, planId, hours } = useSelector(state=>state.registerPatientState.info);
    const navigate = useNavigate();
    const [redirect, setRedirect]= useState(false);
    const [update, setUpdate] = useState(false);

    useEffect(()=>{
        if(redirect && update){
            navigate("/carrinho");
        }
    }, [redirect]);

    useEffect(()=>{
        http.reason.findAll()
            .then(result=>{
                dispatch({
                    type: 'LOAD_REASON',
                    payload: result.data
                });
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Erro ao carregar motivos',
                message: error.response.data
            }))
            .finally(()=>dispatch({
                type: 'IS_LOADING_REASON',
                payload: false
            }));
    }, []);

    useEffect(()=>{
        if(reasonId && modality && planId && Object.keys(hours).length){
            setRedirect(true);
        }
    }, [reasonId, modality, planId, hours, update]);

    return (
        <>
            <div style={ !props.disablePadding ? { padding: "0 3rem" } : {}}>
                <Row>
                    <Col xs={12}>
                        {
                            !props.hideVideo && <iframe id="demonstracao" src="https://www.youtube.com/embed/7mCgAgeZ80M" allow="fullscreen" />
                        }
                    </Col>
                </Row>
            </div>
            <div id='home' style={ !props.disablePadding ? { padding: "6rem 3rem" } : {}}>
                <Row className="stages-home">
                    <Col xs={ 12 } xl={ 3 }>
                        <FirstStage setUpdate={ setUpdate } />
                    </Col>
                    <Col className='arrow'>
                        <FontAwesomeIcon icon={ faArrowRight } />
                    </Col>
                    <Col xs={ 12 } xl={ 3 }>
                        <SecondStage setUpdate={ setUpdate } />
                    </Col>
                    <Col className='arrow'>
                        <FontAwesomeIcon icon={ faArrowRight } />
                    </Col>
                    <Col xs={ 12 } xl={ 3 }>
                        <ThirdStage setUpdate={ setUpdate } />
                    </Col>
                </Row>
                <div className="stages-home-responsive">
                    <FirstStage setUpdate={ setUpdate } />
                    <div className='arrow'>
                        <FontAwesomeIcon icon={ faArrowDown } />
                    </div>
                    <SecondStage setUpdate={ setUpdate } />
                    <div className='arrow'>
                        <FontAwesomeIcon icon={ faArrowDown } />
                    </div>
                    <ThirdStage setUpdate={ setUpdate } />
                </div>
            </div>
        </>
    );
}