import moment from "moment";

const INITIAL_STATE = {
    isLoading: false,
    dependents: [],
}

const dependentReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'LOAD_DEPENDENT':
            return {
                ...state, 
                dependents: payload.map(dependent=>({
                    ...dependent,
                    birth: moment().diff(dependent.dt_birth, 'years')
                })),
                isLoading: false
            };
        case 'INCLUDE_DEPENDENT':

            let newDependent = {
                ...payload,
                birth: moment().diff(payload.dt_birth, 'years')
            }

            return {
                ...state, 
                dependents: [...state.dependents, newDependent]
            };
        case 'IS_LOADING_DEPENDENT':
            return {
                ...state,
                isLoading: payload
            };
        case 'UPDATE_DEPENDENT':
            return {
                ...state, 
                dependents: state.dependents.map(dependent=>{
                    if(dependent.id === payload.id) {
                        let newDependent = {
                            ...payload,
                            birth: moment().diff(payload.dt_birth, 'years')
                        }
                        return newDependent;
                    }
                    
                    return dependent;
                })
            };
        case 'DELETE_DEPENDENT':
            return {
                ...state, 
                dependents: state.dependents.filter(dependent=>dependent.id != payload.id)
            };
        default:
            return state;
    }
}

export default dependentReducer;