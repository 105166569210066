import React, { useState, useEffect } from 'react';
import Modal from '../../Components/Modal';
import { Form, Button, Badge, Container, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { http } from '../../services';
import utils from './../../utils';
import ResetPassword from './../../Components/ResetPasswordDashboard';
import './style.css';

function AccountInfo({ account }){

    const [canceledPlan, setCanceledPlan] = useState(account.canceledPlan);

    return (
        <div id='account-info'>
            <h2>{ account.name }</h2>
            <p>{ account.email }</p>
            <Badge>{ account.type }</Badge>
            {
                !canceledPlan && account.planName ? <p>Plano { account.planName?.toLowerCase() }</p> : ''
            }
        </div>
    );
}

const config = {
    cancel: {
        cta: "Cancelar plano",
        title: 'Cancelar plano',
        ctaFn: ()=>{
            http.user.cancelPlan()
                .then(()=>{
                    utils.createNotification({
                        type: 'success',
                        title: 'Plano cancelado'
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: `Erro ao cancelar plano`,
                    message: error.response.data
                }));
        },
        style: {
            backgroundColor: 'var(--color-danger)'
        }
    }
}

function AccountForm(props){

    const [name, setName] = useState(props.account.name);
    const [accountTermOfUse, setAccountTermOfUse] = useState(props.account.termsOfUse);
    const [btnDisabled, setBtnDisabled] = useState(true);
    const dispatch = useDispatch();
    const [lastModify, setLastModify] = useState(null);

    const root = window.location.pathname.split('/')[1];

    useEffect(()=>{
        http.policy.term.lastModify()
            .then(result=>setLastModify(result.data))
            .catch(error=>{
                setBtnDisabled(false);
                utils.createNotification({
                    type: 'error',
                    title: 'Erro ao buscar data da última modificação dos termos de uso.',
                    message: error.response.data
                });
            });
    }, []);

    useEffect(()=>{
        setBtnDisabled(true);
        if(name !== props.account.name) setBtnDisabled(false);
    }, [name]);

    const discartUpdate = ()=>{
        setName(props.account.name);
    }

    const save = ()=>{
        setBtnDisabled(true);
        http.user.update({name})
            .then(result=>{
                dispatch({type: 'UPDATE_ACCOUNT', payload: result.data});
            })
            .catch(error=>{
                setBtnDisabled(false);
                utils.createNotification({
                    type: 'error',
                    title: 'Erro ao alterar dados da conta',
                    message: error.response.data
                });
            });
    }

    const acceptTermsOfUse = ()=>{
        http.policy.term.accept()
            .then(result=>(utils.createNotification({
                type: 'success',
                title: result.data
            })))
            .catch(error=>(utils.createNotification({
                type: 'error',
                title: 'Erro ao aceitar termos de uso',
                message: error.response.data
            })));
    }

    return (
        <Form id="account-form">
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Digite o seu nome"
                    value={ name }
                    onChange={ e=>setName(e.target.value) }
                />
            </Form.Group>
            <Button onClick={save} disabled={ btnDisabled }>Salvar</Button>
            <Button onClick={discartUpdate} disabled={ btnDisabled }>Descartar alterações</Button>
            {
                (lastModify && (accountTermOfUse < lastModify)) ? <Button onClick={acceptTermsOfUse}>Aceitar termos de uso</Button> : false
            }
            <ResetPassword />
            {
                !props.account.canceledPlan && root === 'paciente' ? (
                    <Modal
                        config={ config.cancel }
                        content={
                            <div className='account-form-modal-body'>Deseja cancelar o seu plano?</div>
                        }
                    />
                ) : false
            }
            
        </Form>
    );
}

export default function Account(){

    const account = useSelector(state=>state.accountState.user);

    return (
        <div id='table'>
            <Container>
                <Row>
                    <Col md={8}>
                        <AccountForm account={ account } />
                    </Col>
                    <Col md={4}>
                        <AccountInfo account={ account } />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}