import React, { useState, useEffect } from "react";
import { Container, Row, Col, Spinner, Card } from 'react-bootstrap';
import Calendar from "../../Components/Calendar";
import Table from '../../Components/Table';
import 'react-calendar/dist/Calendar.css';
import { useSelector, useDispatch } from 'react-redux';
import moment from "moment";
import http from "../../services/http";
import utils from './../../utils';
import './style.css';

export default function Schedule(){

    const { attendances, isLoading } = useSelector(state=>state.scheduleState);
    const [availables, setAvailables] = useState([]);
    const [isLoadingAvailables, setIsLoadingAvailables] = useState(false);

    const dispatch = useDispatch();
    const root = window.location.pathname.split('/')[1];

    useEffect(()=>{
        setIsLoadingAvailables(true);
        http.attendance.available()
            .then(result=>{
                setAvailables(result.data)
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Erro ao carregar próximos atendimentos',
                message: error.response.data
            }))
            .finally(()=>setIsLoadingAvailables(false));
    }, []);

    const config = {
        columns: [
            { name: 'id', translate: 'Id' },
            { name: 'time', translate: 'Hora' },
            root === 'paciente' ? { name: 'psychologistName', translate: 'Psicólogo' } : { name: 'patientName', translate: 'Paciente' },
            { name: 'modality', translate: 'Modalidade' },
            { name: 'price', translate: 'Valor', type: 'currency' },
            root === 'psicologo' && { name: 'rate', translate: 'Taxa(%)' }
        ]
    }

    const configAvailable = {
        columns: [
            { name: 'id', translate: 'Id' },
            { name: 'realized', type: 'date', translate: 'Data' },
            { name: 'time', translate: 'Hora' },
            root === 'paciente' ? { name: 'psychologistName', translate: 'Psicólogo' } : { name: 'patientName', translate: 'Paciente' },
            { name: 'modality', translate: 'Modalidade' },
            { name: 'price', translate: 'Valor', type: 'currency' },
            root === 'psicologo' && { name: 'rate', translate: 'Taxa(%)' }
        ]
    }

    const changeDate = date=>{
        dispatch({ type: 'IS_LOADING_SCHEDULE', payload: true });
        http.schedule.findAll({ realided: moment(date).format('YYYY-MM-DD') })
            .then(result=>{
                dispatch({
                    type: 'LOAD_SCHEDULE',
                    payload: result.data
                });
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Erro ao carregar agenda',
                message: error.response.data
            }))
            .finally(()=>dispatch({ type: 'IS_LOADING_SCHEDULE', payload: false }));
    }

    return (
        <Container className="schedule" fluid>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>Próximos atendimentos</Card.Title>
                            <Card.Text>
                                <Row>
                                    <Col>
                                        {
                                            isLoadingAvailables ? <Spinner style={{color: 'var(--color-font)'}} animation="border" /> : (
                                                availables.length ? <Table
                                                    data={ availables }
                                                    config={ configAvailable }
                                                /> : <p className="empty">Não há atendimentos agendados.</p>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>Atendimentos por dia</Card.Title>
                            <Card.Text>
                                <Row>
                                    <Col sm={ 5 } md={ 4 }>
                                        <Calendar changeDate={ changeDate } />
                                    </Col>
                                    <Col sm={ 7 } md={ 8 }>
                                    {
                                        isLoading ? <div><Spinner animation="border" /></div> :
                                        ( attendances.length ? <Table
                                            data={ attendances }
                                            config={ config }
                                            //Actions={ TableActions }
                                        /> : <p className="empty">Não há atendimentos no dia selecionado.</p>)
                                    }
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}