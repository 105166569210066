const INITIAL_STATE = {
    current: '/',
    open: true,
    loading: false
}

const pageReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'LOADING_PAGE':
            return { ...state, loading: payload };
        case 'SET_PAGE':
            return { ...state, current: payload };
        case 'TOGGLE':
            return { ...state, open: !state.open };
        default:
            return state;
    }
}

export default pageReducer;