const INITIAL_STATE = {
    isLoading: false,
    reasons: [],
}

const reasonReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'LOAD_REASON':
            return {
                ...state, 
                reasons: payload,
                isLoading: false
            };
        case 'INCLUDE_REASON':
            return {
                ...state, 
                reasons: [...state.reasons, payload]
            };
        case 'IS_LOADING_REASON':
            return {
                ...state,
                isLoading: payload
            };
        case 'UPDATE_REASON':
            return {
                ...state, 
                reasons: state.reasons.map(reason=>(reason.id === payload.id) ? payload : reason)
            };
        case 'DELETE_REASON':
            return {
                ...state, 
                reasons: state.reasons.filter(reason=>reason.id != payload.id)
            };
        default:
            return state;
    }
}

export default reasonReducer;