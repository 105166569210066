import React from 'react';
import { Navbar, Nav, Button, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, Redirect } from 'react-router-dom';
import './style.css';
import Logo from './../../Images/logo.png';
import { LabelLogin } from '../HomePageHeader';

export default function Header(){
    const root = window.location.pathname.split('/')[1];

    return (
        <Navbar id="home-page-header-responsive" expand="lg">
            <Navbar.Brand style={{margin: '0 50px'}}>
                <Link to="/">
                    <Image src={ Logo } className="logo" fluid />
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
                <Nav
                className="mr-auto my-2 my-lg-0"
                navbarScroll
                >
                    {
                        root !== 'carrinho' ? (
                            <>
                                <Navbar>
                                    <a href='#home'>Home</a>
                                </Navbar>
                                <Navbar>
                                    <a href='#about'>Quem Somos</a>
                                </Navbar>
                                <Navbar>
                                    <a href='#contact'>Contato</a>
                                </Navbar>
                                <Navbar>
                                    <a target='_blank' href='/login'>Login</a>
                                </Navbar>
                                <Navbar>
                                    <Link to="/carrinho">Carrinho</Link>
                                </Navbar>
                            </>
                        ) : <LabelLogin />
                    }
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}