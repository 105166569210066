import React, { useState, useEffect } from "react";
import './style.css';
import Logo from './../../Images/logo.png';
import LogoResponsive from './../../Images/logo-icone.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faCircleUser,
    faRightFromBracket,
    faBars,
    faCartShopping
} from '@fortawesome/free-solid-svg-icons';
import { Navbar, Image, Nav, Button } from 'react-bootstrap';
import config from './../../config';
import NotificationBell from '../NotificationBell';
import store from './../../store';
import moment from "moment";
import jwt_decode from "jwt-decode";
import utils from '../../utils';
import { io } from "socket.io-client";
import { websocket, http } from './../../services';

import {
    Link,
    Outlet,
    Navigate
} from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';

function HeaderResponsive(){
    const root = window.location.pathname.split('/')[1];
    const user = useSelector(state=>state.accountState.user);
    const dispatch = useDispatch();
    
    return (
        <Navbar id="header-responsive" expand="xxl">
            <Navbar.Brand style={{margin: '0 50px'}}>
                <Link to="/">
                    <Image src={ Logo } style={{width: '60px'}} fluid />
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
                <Nav
                className="mr-auto my-2 my-lg-0"
                navbarScroll
                >
                    {
                        config.pages[root].map(page=>(
                            <Navbar>
                                <Link to={ page.url }>{ page.title }</Link>
                            </Navbar>
                        ))
                    }
                    <Navbar>
                        <Link to={ `/${user.type === 'psicólogo' ? 'psicologo' : user.type}/minha-conta` }>Minha conta</Link>
                    </Navbar>
                    <Navbar>
                        <Button onClick={()=>dispatch({type: 'LOGOUT'})} variant='link'>Sair</Button>
                    </Navbar>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default function Layout(){

    const [active, setActive] = useState(false);
    const user = useSelector(state=>state.accountState.user);

    const [pageCurrent, setPageCurrent] = useState(window.location.pathname);

    const root = window.location.pathname.split('/')[1];

    const dispatch = useDispatch();
    const { isLogged, token, refreshToken, socket } = useSelector(state=>state.accountState);

    const isOpen = useSelector(state=>state.notificationState.open);
    
    useEffect(()=>{
        dispatch({type: "SET_PAGE", payload: window.location.pathname});
        
        if(isLogged){
            const interval = setInterval(() => {
                let exp = (jwt_decode(store.getState().accountState.token)).exp;
                let now = Math.ceil(moment.now() / 1000);
                let diff = exp - now;
                
                if(diff <= 60){
                    if(diff <= 0) dispatch({type: 'ACCESS_TOKEN_EXPIRED', payload: true});
                    http.user.refresh();
                }

            }, 1000);
            return () => clearInterval(interval);
        }

    }, [isLogged, refreshToken]);

    useEffect(()=>{
        if(isLogged){
            new Promise(resolve=>{
                const data = io(`${config.host}/`, {
                    reconnectionDelayMax: 10000,
                    auth: {
                      token: token
                    }
                });
    
                dispatch({type: 'SET_SOCKET', payload: data});
    
                resolve(data);
            })
                .then(socket=>{
                    socket.on("new-notification", data=>{
                        dispatch({type: 'INCLUDE_NOTIFICATION', payload: data});
                    });
                    socket.on("payment-pix-success", ()=>{
                        utils.createNotification({
                            type: 'success',
                            title: 'Pagamento realizado!'
                        });
                    });
                    websocket.notification.findAll()
                        .then(result=>dispatch({type: 'LOAD_NOTIFICATION', payload: result}));
                });
        }
    }, [isLogged]);

    return (
        <div onClick={event=>{
            if(isOpen && !['svg', 'BUTTON'].includes(event.target.nodeName)){
                dispatch({type: 'TOGGLE_BELL_NOTIFICATION'});
            }
        }} className={ "container-layout" + (active ? " active" : "") }>
            {
                !isLogged ? <Navigate replace to={ window.location.href.includes('admin') ? "/admin/login" : "/login" } /> : false
            }
            <HeaderResponsive active={ active } />
            <div className="navigation">
                <ul>
                    <li className="logo">
                        <Link to="/">
                        <img src={ !active ? Logo : LogoResponsive } />
                        </Link>
                    </li>
                    {
                        config.pages[root].map(page=>(
                            <Link to={ page.url }>
                                <li
                                    className={ "" + (pageCurrent === page.url ? "active" : "") }
                                    onClick={ ()=>setPageCurrent(page.url) }
                                >
                                    <div className="icon">
                                        <FontAwesomeIcon icon={ page.icon } />
                                    </div>
                                    <p>{ page.title }</p>
                                </li>
                            </Link>
                        ))
                    }
                    {
                        user.type === 'paciente' && <Link to="/carrinho">
                            <li>
                                <div className="icon">
                                    <FontAwesomeIcon icon={ faCartShopping } />
                                </div>
                                <p>Carrinho</p>
                            </li>
                        </Link>
                    }
                    <li className="hr">
                        <hr/>
                    </li>
                    <Link to={ `/${user.type === 'psicólogo' ? 'psicologo' : user.type}/minha-conta` }>
                        <li
                            className={ "" + (pageCurrent.includes('minha-conta') ? "active" : "") }
                            onClick={ ()=>setPageCurrent(`/${user.type === 'psicólogo' ? 'psicologo' : user.type}/minha-conta`) }
                        >
                            <div className="icon">
                                <FontAwesomeIcon icon={ faCircleUser } />
                            </div>
                            <p>Minha conta</p>
                        </li>
                    </Link>
                    <li onClick={()=>dispatch({type: 'LOGOUT'})}>
                        <div className="icon">
                            <FontAwesomeIcon icon={ faRightFromBracket } />
                        </div>
                        <p>Sair</p>
                    </li>
                </ul>
            </div>
            <div className="main">
                <header>
                    <FontAwesomeIcon
                        className="toggle"
                        onClick={()=>setActive(!active)}
                        icon={ faBars }
                    />
                    <NotificationBell />
                </header>
                <Outlet />
            </div>
        </div>
    );
}