import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './style.css';
import Location from '../Location';
import Social from '../Social';
import Phone from './../Phone';

export default function Home(){
    return (
        <Row id='contact'>
            <Col>
                <Location />
                <Social />
                <Phone />
            </Col>
        </Row>
    );
}