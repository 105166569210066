import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useEffect } from 'react';
import './style.css';

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size={ props.config.size ? props.config.size : "md" }
      aria-labelledby="contained-modal-title-vcenter"
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            { props.config.title }
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            props.content
          }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>props.setModalShow(false)}>Voltar</Button>
          {
            props.config.ctaFn ? <Button style={ props.config.style } className='cta' onClick={()=>{
              props.setModalShow(false);
              props.config.ctaFn(props.row);
            }}>{ props.config.cta }</Button> : false
          }
        </Modal.Footer>
    </Modal>
  );
}

export default function ModalC({content, config, row}) {

  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div id='modal'>
        <Button style={ config.style } className='cta' variant="primary" onClick={() => setModalShow(true)}>
            { config.cta }
        </Button>

        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          content={ content }
          config={ config }
          row={ row }
          setModalShow={ setModalShow }
        />
    </div>
  );
}

