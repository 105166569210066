import React from 'react';
import { Card } from 'react-bootstrap';
import './style.css';

export default function SectionWrap({ title, children }){
    return (
        <Card className='section-wrap' style={{ width: '100%' }}>
            <Card.Header>{ title }</Card.Header>
            <Card.Body>
                {
                    children
                }
            </Card.Body>
        </Card>
    );
}