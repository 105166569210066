const INITIAL_STATE = {
    isLoading: false,
    units: []
}

const unitReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'LOAD_UNIT':
            return {
                ...state, 
                units: payload,
                isLoading: false
            };
        case 'INCLUDE_UNIT':
            return {
                ...state, 
                units: [...state.units, payload]
            };
        case 'IS_LOADING_UNIT':
            return {
                ...state,
                isLoading: payload
            };
        case 'UPDATE_UNIT':
            return {
                ...state,
                units: state.units.map(unit=>(unit.id === payload.id) ? payload : unit)
            };
        case 'DELETE_UNIT':
            return {
                ...state, 
                units: state.units.filter(unit=>unit.id != payload.id)
            };
        default:
            return state;
    }
}

export default unitReducer;