import React from 'react';
import './style.css';
import { Link } from 'react-router-dom';

export default function HomePageHeaderMenuAuth(){
    return (
        <div id='home-page-header-auth' className='space-between-row'>
            <a target='_blank' href='/login'>Login</a>
            <Link to="/carrinho">Carrinho</Link>
        </div>
    );
}