import React, { useState, useEffect } from 'react';
import Table from '../../Components/Table';
import Modal from '../../Components/Modal';
import { Form, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { http } from '../../services';
import utils from './../../utils';
import store from '../../store';

const config = {
    columns: [
        {
            name: 'id',
            translate: 'Id'
        },
        {
            name: 'description',
            translate: 'Descrição'
        }
    ],
    include: {
        cta: "Incluir",
        title: 'Incluir motivos',
        ctaFn: ()=>{
            http.reason.create(store.getState().formState.form)
                .then(result=>{
                    store.dispatch({
                        type: 'INCLUDE_REASON',
                        payload: result.data
                    });
                    utils.createNotification({
                        type: 'success',
                        title: 'Motivo criado com sucesso'
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: 'Erro ao criar motivo',
                    message: error.response.data
                }))
                .finally(()=>store.dispatch({
                    type: 'CLEAR_FORM'
                }));
        }
    },
    update: {
        cta: "Editar",
        title: 'Editar motivo',
        ctaFn: ({id})=>{
            http.reason.update(id, store.getState().formState.form)
                .then(result=>{
                    store.dispatch({
                        type: 'UPDATE_REASON',
                        payload: result.data
                    });
                    utils.createNotification({
                        type: 'success',
                        title: `As informações do motivo ${id} foram alteradas!`
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: `Erro ao editar motivo ${id}`,
                    message: error.response.data
                }))
                .finally(()=>store.dispatch({
                    type: 'CLEAR_FORM'
                }));
        }
    },
    remove: {
        cta: "Deletar",
        title: 'Deletar motivo',
        ctaFn: ({ id })=>{
            http.reason.destroy(id)
                .then(()=>{
                    store.dispatch({
                        type: 'DELETE_REASON',
                        payload: {
                            id
                        }
                    });
                    utils.createNotification({
                        type: 'success',
                        title: 'Motivo deletado',
                        message: `O motivo ${id} foi deletado!`
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: `Erro ao deletar motivo ${id}`,
                    message: error.response.data
                }));
        },
        style: {
            backgroundColor: 'var(--color-danger)'
        }
    }
}

function IncludeContent(){

    const [description, setDescription] = useState('');

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch({
            type: 'SAVE_FORM',
            payload: {
                description
            }
        });
    }, [description]);

    return (
        <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                    value={ description }
                    onChange={ e=>setDescription(e.target.value) }
                    type="text"
                    placeholder="Digite a descrição do motivo"
                />
            </Form.Group>
        </Form>
    );
}

function UpdateContent(props){

    const [description, setDescription] = useState(props.description);
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch({
            type: 'SAVE_FORM',
            payload: {
                description
            }
        });
    }, [description]);

    return (
        <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                    value={ description }
                    onChange={ e=>setDescription(e.target.value) }
                    type="text"
                    placeholder="Digite a descrição do motivo"
                />
            </Form.Group>
        </Form>
    );
}

function RemoveContent({ id }){
    return (
        <p>Deseja deletar o motivo {id}?</p>
    );
}

function TableActions(props){

    return (
        <>
            <Modal
                config={ config.update }
                row={ props.row }
                content={ <UpdateContent
                    { ...props.row }
                /> }
            />
            <Modal
                config={ Object.assign({}, config.remove, { data: { id: props.row.id } }) }
                row={ props.row }
                content={ <RemoveContent
                    { ...props.row }
                /> }
            />
        </>
    );
}

export default function Reasons(){

    const { isLoading } = useSelector(state=>state.reasonState);
    const dispatch = useDispatch();

    const reasons = useSelector(state=>state.reasonState.reasons);

    useEffect(()=>{

        dispatch({
            type: 'IS_LOADING_REASON',
            payload: true
        });

        http.reason.findAll()
            .then(result=>{
                dispatch({
                    type: 'LOAD_REASON',
                    payload: result.data
                });
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Erro ao carregar motivos',
                message: error.response.data
            }))
            .finally(()=>dispatch({
                type: 'IS_LOADING_REASON',
                payload: false
            }));
    }, []);

    return (
        <div id='table'>
            <Modal
                config={ config.include }
                content={ <IncludeContent
                /> }
            />
            {
                isLoading ? <div><Spinner animation="border" /></div> :
                <Table
                    data={ reasons }
                    config={ config }
                    Actions={ TableActions }
                />
            }
        </div>
    );
}