import { useState, useEffect } from 'react';
import store from './../store';

function useIsSuccessRegisterPatient(){
    const [states, setStates] = useState([]); //first, second, third, finish

    useEffect(()=>{
        if(
            store.getState().registerPatientState.info.reasonId
            && !states.includes('third')
        ) setStates([...states, 'third']);

        if(
            (store.getState().registerPatientState.info.unitId || store.getState().registerPatientState.info.modality === 'online')
            && !states.includes('second')
            && (store.getState().registerPatientState.info.hours && Object.values(store.getState().registerPatientState.info.hours).reduce((acc,current)=>{
                return acc + current.length;
            }, 0) === 3)
        ) setStates([...states, 'second']);

        if(
            (!store.getState().registerPatientState.info.unitId && store.getState().registerPatientState.info.modality === 'presencial')
            || (store.getState().registerPatientState.info.hours && Object.values(store.getState().registerPatientState.info.hours).reduce((acc,current)=>{
                return acc + current.length;
            }, 0) < 3)
            && states.includes('second')
        ) setStates([...states.filter(state=>state!=='second')]);

        if(
            store.getState().registerPatientState.info.planId
            && store.getState().registerPatientState.info.modality
            && !states.includes('first')
        ) setStates([...states, 'first']);

        if(
            !(store.getState().registerPatientState.info.planId
            && store.getState().registerPatientState.info.modality)
            && states.includes('first')
        ) setStates([...states.filter(state=>state!=='first')]);

    }, [store.getState().registerPatientState.info]);

    return states;

}

export default useIsSuccessRegisterPatient;