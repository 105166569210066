import axios from "axios";
import config from './../../config';
import store from './../../store';
import utils from "../../utils";

export default {
    configUtils: {
        findAll(){
            return axios.get(`${config.host}/config-utils`);
        }
    },
    dashboard: {
        admin(){
            return axios.get(`${config.host}/dashboard/admin`, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        }
    },
    user: {
        auth(data){
            return axios.post(`${config.host}/user/auth`, data);
        },
        resetPasswordForDashboard(data){
            return axios.put(`${config.host}/user/reset-password`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        resetPassword(data){
            return axios.post(`${config.host}/user/reset-password`, data, {
                headers: {
                    refresh_token: store.getState().accountState.refreshToken
                }
            });
        },
        refresh(data = null, quiet = false){
            let promise = axios.post(`${config.host}/user/refresh`, data, {
                headers: {
                    refresh_token: store.getState().accountState.refreshToken
                }
            });
                
            if(quiet){
                return promise;
            } else {
                promise
                    .then(result=>{
                        store.dispatch({
                            type: 'REFRESH_TOKEN',
                            payload: {
                                user: result.data.user,
                                token: result.data.token,
                                refreshToken: result.data.refreshToken
                            }
                        });
                    })
                    .catch(()=>utils.createNotification({
                        type: 'error',
                        title: `Erro ao obter token de acesso!`
                    }));
            }

        },
        onlyPayments(data){
            let newData = Object.assign({}, data);

            return new Promise(async (resolve, reject)=>{
                const configUtils = store.getState().configUtilsState.configUtils;
                const expiryArr = data.paymentInfo.expiry?.split("/");
                if(["CREDIT_CARD", "DEBIT_CARD"].includes(data.payment)){
                    const cardEncrypt = window.PagSeguro.encryptCard({
                        publicKey: configUtils.pagseguroPublicKey,
                        holder: data.paymentInfo.name,
                        number: data.paymentInfo.number,
                        expMonth: expiryArr && expiryArr[0],
                        expYear: expiryArr && `20${expiryArr[1]}`,
                        securityCode: data.paymentInfo.cvc
                    });

                    let errors = {
                        account: {}
                    };
                    let errorsPayment = {};

                    if(typeof data?.paymentInfo?.cvc !== "string" || ![3, 4].includes(data.paymentInfo.cvc.length)){
                        errorsPayment.cvc = "Informe o código de segurança do cartão.";
                    }
                    if(!data.paymentInfo.document) errorsPayment.document = "Informe um documento válido.";
                    if(typeof data.checkedTerm !== "boolean" || !data.checkedTerm) errors.checkedTerm = "Política de privacidade e termos de uso devem ser aceitos para prosseguir.";
                    if(!['online', 'presencial'].includes(data.modality) || data.planId) errors.planId = "Selecione um plano.";
                    if(!data?.account?.complement) errors["account"]["complement"] = "Informe o complemento do seu endereço.";
                    if(!data?.account?.address) errors["account"]["address"] = "Informe o nome da rua.";
                    if(!data?.account?.city) errors["account"]["city"] = "Informe a cidade.";
                    if(!data?.account?.number) errors["account"]["number"] = "Informe o número do seu endereço.";
                    if(!data?.account?.cep) errors["account"]["cep"] = "Informe um cep válido.";
                    if(cardEncrypt.errors.length){
                        let newErrors = cardEncrypt.errors.reduce((acc, current)=>{
                            let newError = ({
                                "INVALID_NUMBER": {
                                    number: "Informe o número do cartão."
                                },
                                "INVALID_HOLDER": {
                                    name: "Informe o nome do cartão."
                                },
                                "INVALID_EXPIRATION_MONTH": {
                                    expiry: "Informe a data de expiração."
                                },
                                "INVALID_EXPIRATION_YEAR": {
                                    expiry: "Informe a data de expiração."
                                }
                            }[current.code]);
        
                            return Object.assign({}, acc, newError);
                        }, {});
    
                        Object.assign(errorsPayment, newErrors);
                    }

                    if(Object.keys(errorsPayment).length){
                        reject({
                            response: {
                                data: {
                                    ...errors,
                                    paymentInfo: errorsPayment
                                }
                            }
                        });
    
                        return;
                    }

                    newData.encryptedCard = cardEncrypt.encryptedCard;

                    // delete newData.paymentInfo.number;
                    // delete newData.paymentInfo.expiry;
                }

                if("DEBIT_CARD" === data.payment){
                    const result = await axios.post(`${config.host}/user/session-pagseguro`, {}, {
                        headers: {
                            auth: store.getState().accountState.token
                        }
                    });
                    await window.PagSeguro.setUp({
                        session: result.data.session,
                        env: result.data.env
                    });
                    
                    const contact = store.getState().accountState.user.cell || store.getState().accountState.user.telephone;
                    let phoneRegex = /^(55)?\s?\(?(0?\d{2})\)?\s?(9?\d{4})-?(\d{4})$/g.exec(contact);
                    const plan = store.getState().planState.plans.find(p=>p.id === data.planId);
                    const modalityFormated = data.modality === "presencial" ? "pricePresentialMonthly" : "priceOnlineMonthly";
                    
                    const request = {
                        data: {
                            customer: {
                                name: newData.paymentInfo.name,
                                email: store.getState().accountState.user.email,
                                phones: [
                                    {
                                        country: '55',
                                        area: phoneRegex[2],
                                        number: phoneRegex[3] + phoneRegex[4],
                                        type: 'MOBILE'
                                    }
                                ]
                            },
                            paymentMethod: {
                                type: 'DEBIT_CARD',
                                installments: 1,
                                card: {
                                    number: data.paymentInfo.number,
                                    expMonth: expiryArr && expiryArr[0],
                                    expYear: expiryArr && `20${expiryArr[1]}`,
                                    holder: {
                                        name: data.paymentInfo.name
                                    }
                                }
                            },
                            amount: {
                                value: plan[modalityFormated] * 100,
                                currency: 'BRL'
                            },
                            billingAddress: {
                                street: store.getState().registerPatientState.info.account.address,
                                number: store.getState().registerPatientState.info.account.number,
                                complement: store.getState().registerPatientState.info.account.complement,
                                regionCode: store.getState().registerPatientState.info.account.uf,
                                country: 'BRA',
                                city: store.getState().registerPatientState.info.account.city,
                                postalCode: store.getState().registerPatientState.info.account?.cep.replace("-", "")
                            },
                            dataOnly: false
                        }
                    }
                    const result2 = await window.PagSeguro.authenticate3DS(request)
                        .then(r=>{
                            if(r.status === "AUTH_NOT_SUPPORTED"){
                                reject({
                                    response: {
                                        data: {
                                            AUTH_NOT_SUPPORTED: "AUTH_NOT_SUPPORTED"
                                        }
                                    }
                                });
                                return {isError: true};
                            }
                            return r;
                        });
                    if(result2?.isError) return;
                    newData.id = result2.id;
                }

                axios.post(`${config.host}/only-payments`, newData, {
                    headers: {
                        auth: store.getState().accountState.token
                    }
                })
                    .then(result=>resolve(result))
                    .catch(err=>reject(err));
            });
            
        },
        registerAndPayments(data){
            return axios.post(`${config.host}/user/register-and-payments`, data, {
                headers: !data.account ? {
                    auth: store.getState().accountState.token
                } : {}
            });
        },
        registerPsychologist(data){
            return axios.post(`${config.host}/user/register-psychologist`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        findAll(params = {}){
            return axios.get(`${config.host}/user`, {
                params,
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        update(data){
            return axios.put(`${config.host}/user`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        updateAdmin(id, data){
            return axios.put(`${config.host}/user/${id}`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        createAdmin(data){
            return axios.post(`${config.host}/user/admin`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        destroy(id){
            return axios.delete(`${config.host}/user/${id}`, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        findPayments(params = {}){
            return axios.get(`${config.host}/user/payments`, {
                params,
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        cancelPlan(){
            return axios.post(`${config.host}/user/patient/cancel-plan`, {}, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        }
    },
    schedule: {
        findAll(params = {}){
            return axios.get(`${config.host}/schedule`, {
                params,
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        }
    },
    attendance: {
        create(data){
            return axios.post(`${config.host}/attendance`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        update(id, data){
            return axios.put(`${config.host}/attendance/${id}`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        findAll(params = {}){
            return axios.get(`${config.host}/attendance`, {
                params,
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        available(){
            return axios.get(`${config.host}/attendance/available`, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        destroy(id){
            return axios.delete(`${config.host}/attendance/${id}`, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        }
    },
    reason: {
        create(data){
            return axios.post(`${config.host}/reason`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        findAll(params = {}){
            return axios.get(`${config.host}/reason`, {
                params
            });
        },
        update(id, data){
            return axios.put(`${config.host}/reason/${id}`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        destroy(id){
            return axios.delete(`${config.host}/reason/${id}`, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        }
    },
    dependent: {
        create(data){
            return axios.post(`${config.host}/dependent`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        findAll(params = {}){
            return axios.get(`${config.host}/dependent`, {
                params,
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        update(id, data){
            return axios.put(`${config.host}/dependent/${id}`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        destroy(id){
            return axios.delete(`${config.host}/dependent/${id}`, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        }
    },
    plan: {
        findAll(params = {}){
            return axios.get(`${config.host}/plan`, {
                params
            });
        },
        update(id, data){
            return axios.put(`${config.host}/plan/${id}`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        }
    },
    match: {
        create(data){
            return axios.post(`${config.host}/match`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        update(id, data){
            return axios.put(`${config.host}/match/${id}`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        findAll(params = {}){
            return axios.get(`${config.host}/match`, {
                params,
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        destroy(id){
            return axios.delete(`${config.host}/match/${id}`, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        }
    },
    unit: {
        create(data){
            return axios.post(`${config.host}/unit`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        findAll(params = {}){
            return axios.get(`${config.host}/unit`, {
                params
            });
        },
        destroy(id){
            return axios.delete(`${config.host}/unit/${id}`, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        },
        update(id, data){
            return axios.put(`${config.host}/unit/${id}`, data, {
                headers: {
                    auth: store.getState().accountState.token
                }
            });
        }
    },
    policy: {
        privacy: {
            findAll(params = {}){
                return axios.get(`${config.host}/policy/privacy`, {
                    params
                });
            },
            update(data){
                return axios.put(`${config.host}/policy/privacy`, data, {
                    headers: {
                        auth: store.getState().accountState.token
                    }
                });
            }
        },
        term: {
            findAll(params = {}){
                return axios.get(`${config.host}/policy/term`, {
                    params
                });
            },
            update(data){
                return axios.put(`${config.host}/policy/term`, data, {
                    headers: {
                        auth: store.getState().accountState.token
                    }
                });
            },
            lastModify(){
                return axios.get(`${config.host}/policy/term/last-modify`);
            },
            accept(){
                return axios.put(`${config.host}/policy/term/accept`, {}, {
                    headers: {
                        auth: store.getState().accountState.token
                    }
                });
            }
        }
    },
    ibge: {
        allStates(){
            return axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome`);
        }
    },
    correios: {
        getCepInfo(cep){
            return axios.get(`https://viacep.com.br/ws/${cep}/json/`);
        }
    }
}