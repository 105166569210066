import React, { useState, useEffect } from 'react';
import Table from '../../Components/Table';
import Modal from '../../Components/Modal';
import { Form, Button, Spinner, Container, Row, Col, Badge } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { http } from '../../services';
import utils from './../../utils';
import store from '../../store';
import Filter from '../../Components/Filter';
import {
    useSearchParams
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../../Components/Pagination';
import './style.css';

const config = {
    columns: [
        { name: 'id', translate: 'Id' },
        { name: 'email', translate: 'E-mail' },
        { name: 'name', translate: 'Nome' },
        { name: 'type', translate: 'Tipo' },
        { name: 'active', translate: 'Ativo', type: 'boolean' },
        { name: 'email_verified', translate: 'Verificado', type: 'boolean' },
        { name: 'createdAt', translate: 'Criação', type: 'date' },
        { name: 'updatedAt', translate: 'Alteração', type: 'date' }
    ],
    include: {
        cta: "Incluir",
        title: 'Novo usuário administrador',
        ctaFn: ()=>{
            http.user.createAdmin(store.getState().formState.form)
                .then(result=>{
                    store.dispatch({
                        type: 'INCLUDE_USER',
                        payload: result.data
                    });
                    utils.createNotification({
                        type: 'success',
                        title: 'Usuário administrador criado'
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: 'Erro ao criar usuário administrador',
                    message: error.response.data
                }))
                .finally(()=>store.dispatch({
                    type: 'CLEAR_FORM'
                }));
        }
    },
    update: {
        cta: "Editar",
        title: 'Editar usuário',
        ctaFn: ({id})=>{
            http.user.updateAdmin(id, store.getState().formState.form)
                .then(result=>{
                    store.dispatch({
                        type: 'UPDATE_USER',
                        payload: result.data
                    });
                    utils.createNotification({
                        type: 'success',
                        title: `As informações do usuário ${id} foram alteradas!`
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: `Erro ao editar usuário ${id}`,
                    message: error.response.data
                }))
                .finally(()=>store.dispatch({
                    type: 'CLEAR_FORM'
                }));
        }
    },
    remove: {
        cta: "Deletar",
        title: 'Deletar usuário',
        ctaFn: ({ id })=>{
            http.user.destroy(id)
                .then(()=>{
                    store.dispatch({
                        type: 'DELETE_USER',
                        payload: {
                            id
                        }
                    });
                    utils.createNotification({
                        type: 'success',
                        title: 'Usuário deletado',
                        message: `O usuário ${id} foi deletado!`
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: `Erro ao deletar usuário ${id}`,
                    message: error.response.data
                }));
        },
        style: {
            backgroundColor: 'var(--color-danger)'
        }
    },
    info: {
        cta: "Informações",
        title: "Informações do usuário",
        size: "lg"
    },
    dependents: {
        cta: "Dependentes",
        title: "Dependentes do paciente",
        size: "lg"
    }
}

function FilterContent(){
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const [id, setId] = useState(searchParams.get('id') || '');
    const [email, setEmail] = useState(searchParams.get('email') || '');
    const [name, setName] = useState(searchParams.get('name') || '');
    const [type, setType] = useState(searchParams.get('type') || '');
    const [active, setActive] = useState(searchParams.get('active') || '');

    const toFilter = (obj={})=>{
        dispatch({ type: 'FILTER_USER', payload: obj });
        setSearchParams(Object.entries(obj).reduce((acc, current)=>{
            if(current[1]) return acc + (acc ? `&${current[0]}=${current[1]}` : `${current[0]}=${current[1]}`);
            return acc;
        }, ''));
        dispatch({
            type: 'IS_LOADING_USER',
            payload: true
        });
        http.user.findAll(obj)
            .then(result=>{
                dispatch({
                    type: 'LOAD_USER',
                    payload: result.data
                });
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Erro ao carregar usuários',
                message: error.response.data
            }))
            .finally(()=>dispatch({
                type: 'IS_LOADING_USER',
                payload: false
            }));
    }

    return (
        <Row xs={1} md={4} lg={6}>
            <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Id</Form.Label>
                    <Form.Control value={ id } onChange={ e=>setId(e.target.value) } type="text" placeholder="Digite o id do usuário" />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control value={ email } onChange={ e=>setEmail(e.target.value) } type="email" placeholder="Digite o email do usuário" />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="formBasic">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control value={name} onChange={e=>setName(e.target.value)} type="text" placeholder="Digite o nome do usuário" />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="formBasic">
                    <Form.Label>Tipo</Form.Label>
                    <Form.Select value={type} onChange={e=>setType(e.target.value)} aria-label="Default select example">
                        <option value=''>Selecione o tipo de conta</option>
                        <option value="admin">Admin</option>
                        <option value="psicólogo">Psicólogo</option>
                        <option value="paciente">Paciente</option>
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="formBasic">
                    <Form.Label>Ativo</Form.Label>
                    <Form.Select value={active} onChange={e=>setActive(e.target.value)} aria-label="Default select example">
                        <option value=''>O usuário está ativo?</option>
                        <option value={ 1 }>Sim</option>
                        <option value={ 0 }>Não</option>
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col>
                {<Form.Group id="filter-btn" className="mb-3" controlId="formBasicEmail">
                    <Form.Label></Form.Label>
                        <Button onClick={()=>toFilter({
                            activePage: 1,
                            id,
                            email,
                            name,
                            type,
                            active
                        })} variant="success">
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </Button>
                </Form.Group>}
            </Col>
        </Row>
    );
}

function IncludeContent(){

    const dispatch = useDispatch();

    return (
        <Form>
            <Form.Group className="mb-3" controlId="formBasic">
                <Form.Control
                    type="email"
                    placeholder="E-mail"
                    onChange={e=>dispatch({
                        type: 'SAVE_FORM',
                        payload: {
                            email: e.target.value
                        }
                    })}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasic">
                <Form.Control
                    type="text"
                    placeholder="Nome"
                    onChange={e=>dispatch({
                        type: 'SAVE_FORM',
                        payload: {
                            name: e.target.value
                        }
                    })}
                />
            </Form.Group>
        </Form>
    );
}

function UpdateContent(props){

    const dispatch = useDispatch();

    const [name, setName] = useState(props.name);
    const [active, setActive] = useState(props.active);

    useEffect(()=>{
        dispatch({
            type: 'SAVE_FORM',
            payload: {
                name, active
            }
        });
    }, [name, active]);

    return (
        <Form>
            <Form.Group className="mb-3" controlId="formBasic">
                <Form.Control
                    value={ name }
                    type="text"
                    placeholder="Nome"
                    onChange={e=>setName(e.target.value)}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                    type="checkbox"
                    label="Ativo"
                    checked={ active }
                    onChange={e=>setActive(e.target.checked)}
                />
            </Form.Group>
        </Form>
    );
}

function RemoveContent({ id }){
    return (
        <p>Deseja deletar o usuário {id}?</p>
    );
}

function InfoContent(props){
    return (
        <div className='info-content'>
            <Container fluid>
                <Row>
                    {
                        props.type === 'paciente' && (
                            <>
                                <Col md={3}>
                                    <h2>Motivo</h2>
                                    <p>{ props.reasonDescription }</p>
                                    <h2>Unidade</h2>
                                    <p>{ props.unitDescription }</p>
                                    <h2>Plano</h2>
                                    <p>{ props.planName }</p>
                                </Col>
                                <Col md={3}>
                                    <h2>Horários</h2>
                                    {
                                        Object.entries(props.hours)
                                            .filter(h=>h[1].length)
                                            .map(h=>(
                                                <>
                                                    <p>{utils.capitalize(utils.dayWeek(Number(h[0])))}</p>
                                                    {
                                                        h[1].map(t=>(
                                                            <Badge style={{
                                                                backgroundColor: 'var(--color-active)'
                                                            }}>{t}</Badge>
                                                        ))
                                                    }
                                                </>
                                            ))
                                    }
                                </Col>
                            </>
                        )
                    }
                    <Col md={3}>
                        {
                            props.crp && (
                                <>
                                    <h2>CRP</h2>
                                    <p>{ props.crp }</p>
                                </>
                            )
                        }
                        <h2>CPF</h2>
                        <p>{ props.cpf }</p>
                        <h2>RG</h2>
                        <p>{ props.rg }</p>
                        <h2>Gênero</h2>
                        <p>{ props.gender }</p>
                        <h2>Data de nascimento</h2>
                        <p>{ utils.formatDate(props.birth) }</p>
                        <h2>Celular/Telefone</h2>
                        <p>{ props.cell || props.telephone }</p>
                    </Col>
                    <Col md={3}>
                        <h2>Cep</h2>
                        <p>{ props.cep }</p>
                        <h2>Rua</h2>
                        <p>{ props.address }</p>
                        <h2>Número</h2>
                        <p>{ props.number }</p>
                        <h2>Bairro</h2>
                        <p>{ props.district }</p>
                        <h2>Complemento</h2>
                        <p>{ props.complement }</p>
                        <h2>Cidade</h2>
                        <p>{ props.city }</p>
                        <h2>Estado</h2>
                        <p>{ props.state }</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

function DependentsContent(props){
    return (
        <div className='info-content'>
            <Table
                data={ props.dependents }
                config={ {
                    columns: [
                        {
                            name: 'id',
                            translate: 'Id'
                        },
                        {
                            name: 'name',
                            translate: 'Nome'
                        },
                        {
                            name: 'rg',
                            translate: 'RG'
                        },
                        {
                            name: 'cpf',
                            translate: 'CPF'
                        },
                        {
                            name: 'birth',
                            translate: 'Idade'
                        },
                        {
                            name: 'dt_birth',
                            translate: 'Data de Nascimento',
                            type: 'date'
                        }
                    ]
                } }
            />
        </div>
    );
}

function TableActions(props){

    return (
        <>
            <Modal
                config={ config.update }
                row={ props.row }
                content={ <UpdateContent
                    { ...props.row }
                /> }
            />
            {
                ['paciente', 'psicólogo'].includes(props.row.type) && (
                    <Modal
                        config={ config.info }
                        row={ props.row }
                        content={ <InfoContent
                            { ...props.row }
                        /> }
                    />
                )
            }
            {
                props.row.type === 'paciente' && (
                    <Modal
                        config={ config.dependents }
                        row={ props.row }
                        content={ <DependentsContent
                            { ...props.row }
                        /> }
                    />
                )
            }
            <Modal
                config={ Object.assign({}, config.remove, { data: { id: props.row.id } }) }
                row={ props.row }
                content={ <RemoveContent
                    { ...props.row }
                /> }
            />
        </>
    );
}

export default function User(){

    const { isLoading, users, filters } = useSelector(state=>state.userState);
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    const toFilter = (obj={})=>{
        dispatch({ type: 'FILTER_USER', payload: obj });
        setSearchParams(Object.entries(obj).reduce((acc, current)=>{
            if(current[1] && current[0] != "totalPages") return acc ? acc + `&${current[0]}=${current[1]}` : `${current[0]}=${current[1]}`;
            return acc;
        }, ''));
        dispatch({
            type: 'IS_LOADING_USER',
            payload: true
        });
        http.user.findAll(obj)
            .then(result=>{
                dispatch({
                    type: 'LOAD_USER',
                    payload: result.data
                });
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Erro ao carregar usuários',
                message: error.response.data
            }))
            .finally(()=>dispatch({
                type: 'IS_LOADING_USER',
                payload: false
            }));
    }

    useEffect(()=>{
        toFilter({
            activePage: Number(searchParams.get('activePage')) || 1,
            email: searchParams.get('email'),
            name: searchParams.get('name'),
            type: searchParams.get('type'),
            active: searchParams.get('active')
        });
    }, []);

    return (
        <div id='table'>
            <Filter 
                content={ <FilterContent /> }
            />
            <Modal
                config={ config.include }
                content={ <IncludeContent
                /> }
            />
            {
                isLoading ? <div><Spinner animation="border" /></div> :
                <Table
                    data={ users }
                    config={ config }
                    Actions={ TableActions }
                />
            }
            {
                filters.totalPages > 1 ? <Row>
                    <Col>
                        <Pagination
                            filters={ filters }
                            toFilter={ toFilter }
                        />
                    </Col>
                </Row> : false
            }
        </div>
    );
}