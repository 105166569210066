import React, { useState, useEffect } from 'react';
import Table from '../../Components/Table';
import Modal from '../../Components/Modal';
import { Form, Spinner, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { http } from '../../services';
import utils from './../../utils';
import store from '../../store';

const config = {
    columns: [
        { name: 'id', translate: 'Id' },
        { name: 'name', translate: 'Nome'},
        { name: 'description', translate: 'Descrição' },
        { name: 'pricePresentialMonthly', translate: 'Valor Presencial - Mensal', type: 'currency' },
        { name: 'priceOnlineMonthly', translate: 'Valor Online - Mensal', type: 'currency' },
        { name: 'pricePresentialSession', translate: 'Valor Presencial - Sessão', type: 'currency' },
        { name: 'priceOnlineSession', translate: 'Valor Online - Sessão', type: 'currency' }
    ],
    update: {
        cta: "Editar",
        title: 'Editar plano',
        ctaFn: ({id})=>{
            http.plan.update(id, store.getState().formState.form)
                .then(result=>{
                    store.dispatch({
                        type: 'UPDATE_PLAN',
                        payload: result.data
                    });
                    utils.createNotification({
                        type: 'success',
                        title: `As informações do plano ${id} foram alteradas!`
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: `Erro ao editar plano ${id}`,
                    message: error.response.data
                }))
                .finally(()=>store.dispatch({
                    type: 'CLEAR_FORM'
                }));
        }
    }
}

function UpdateContent(props){

    const dispatch = useDispatch();

    const [name, setName] = useState(props.name);
    const [description, setDescription] = useState(props.description);
    const [pricePresentialMonthly, setPricePresentialMonthly] = useState(props.pricePresentialMonthly);
    const [pricePresentialSession, setPricePresentialSession] = useState(props.pricePresentialSession);
    const [priceOnlineMonthly, setPriceOnlineMonthly] = useState(props.priceOnlineMonthly);
    const [priceOnlineSession, setPriceOnlineSession] = useState(props.priceOnlineSession);

    useEffect(()=>{
        dispatch({
            type: 'SAVE_FORM',
            payload: {
                name, description, pricePresentialMonthly, pricePresentialSession, priceOnlineMonthly, priceOnlineSession
            }
        });
    }, [name, description, pricePresentialMonthly, pricePresentialSession, priceOnlineMonthly, priceOnlineSession]);

    return (
        <Form>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                            value={ name }
                            type="text"
                            placeholder="Nome"
                            onChange={e=>setName(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="formBasicDescription">
                        <Form.Label>Descrição</Form.Label>
                        <Form.Control
                            value={ description }
                            type="text"
                            placeholder="Descrição"
                            onChange={e=>setDescription(e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={6}>
                    <Form.Group className="mb-3" controlId="formBasicPricePresentialMonthly">
                        <Form.Label>Valor Presencial - Mensal</Form.Label>
                        <Form.Control
                            value={ pricePresentialMonthly }
                            type="number"
                            placeholder="Valor presencial"
                            onChange={e=>setPricePresentialMonthly(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col sm={12} md={6}>
                    <Form.Group className="mb-3" controlId="formBasicPriceOnlineMonthly">
                        <Form.Label>Valor Online - Mensal</Form.Label>
                        <Form.Control
                            value={ priceOnlineMonthly }
                            type="number"
                            placeholder="Valor online"
                            onChange={e=>setPriceOnlineMonthly(e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={6}>
                    <Form.Group className="mb-3" controlId="formBasicPricePresentialSession">
                        <Form.Label>Valor Presencial - Sessão</Form.Label>
                        <Form.Control
                            value={ pricePresentialSession }
                            type="number"
                            placeholder="Valor presencial"
                            onChange={e=>setPricePresentialSession(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col sm={12} md={6}>
                    <Form.Group className="mb-3" controlId="formBasicPriceOnlineSession">
                        <Form.Label>Valor Online - Sessão</Form.Label>
                        <Form.Control
                            value={ priceOnlineSession }
                            type="number"
                            placeholder="Valor online"
                            onChange={e=>setPriceOnlineSession(e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    );
}

function TableActions(props){

    return (
        <>
            <Modal
                config={ config.update }
                row={ props.row }
                content={ <UpdateContent
                    { ...props.row }
                /> }
            />
        </>
    );
}

export default function Plan(){

    const { isLoading, plans } = useSelector(state=>state.planState);
    const dispatch = useDispatch();

    useEffect(()=>{

        dispatch({
            type: 'IS_LOADING_PLAN',
            payload: true
        });

        http.plan.findAll()
            .then(result=>{
                dispatch({
                    type: 'LOAD_PLAN',
                    payload: result.data
                });
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Erro ao carregar planos',
                message: error.response.data
            }))
            .finally(()=>dispatch({
                type: 'IS_LOADING_PLAN',
                payload: false
            }));
    }, []);

    return (
        <div id='table'>
            {
                isLoading ? <div><Spinner animation="border" /></div> :
                <Table
                    data={ plans }
                    config={ config }
                    Actions={ TableActions }
                />
            }
        </div>
    );
}