import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Header from './../../Components/HomePageHeader';
import Footer from "../../Components/Footer";
import './style.css';

export default function PixQRCode(){
    return (
        <div className="pix-qr-code">
            <Container fluid>
                <Row id='section-header' style={{padding: 0}}>
                    <Col>
                        <Container>
                            <Row>
                                <Col>
                                    <Header />
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="pix-container">
                    <Col className="mb-4" md={8}>
                        <p><b>Sucesso!</b> Escaneie o QR-Code para fazer o pagamento via pix.</p>
                    </Col>
                    <Col md={4} className="pix-img">
                        <img draggable={ false } src={ sessionStorage.getItem('qr-code') } />
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    );
}