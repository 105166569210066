import React from "react";
import './style.css';

function HomePageText({ text, fullheight, style, type }){
    return (
        <>
            <div id="home-page-text" style={ style } className={ "space-between-row" + (fullheight ? " fullheight" : "")}>
                {
                    type === 'title' ? <h1>{ text }</h1> : <div
                        dangerouslySetInnerHTML={{__html: text}}
                    />
                }
            </div>
        </>
    );
}

export default HomePageText;