const INITIAL_STATE = {
    open: false,
    notifications: []
}

const notificationReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'LOAD_NOTIFICATION':
            return { ...state, notifications: payload };
        case 'TOGGLE_BELL_NOTIFICATION':
            return { ...state, open: !state.open };
        case 'CLOSE_NOTIFICATION':
            return { ...state, open: false };
        case 'VIEW_NOTIFICATION':
            return { ...state,
                open: false,
                notifications: state.notifications.filter(({id})=>payload.id !== id) };
        case 'INCLUDE_NOTIFICATION':
            return { ...state,
                open: false,
                notifications: [
                    ...state.notifications,
                    payload
                ]
            }
        default:
            return state;
    }
}

export default notificationReducer;