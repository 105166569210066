import React, { useState } from "react";
import './style.css';
import Menu from './../HomePageHeaderMenu';
import logo from './../../Images/logo.png';
import Auth from './../HomePageHeaderAuth';
import HomePageHeaderResponsive from './../HomePageHeaderResponsive';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ListGroup } from 'react-bootstrap';

export function LabelLogin(){

    const { isLogged, user } = useSelector(state=>state.accountState);
    const [active, setActive] = useState(false);
    const dispatch = useDispatch();

    return (
        <div className="label-login">
            {
                isLogged && user.type === 'paciente' ? (
                    <div className={'label'} onClick={()=>setActive(!active)}>
                        <p>{user.name.substring(0,1)}</p>
                        <nav className={`${active ? 'display-show' : ''}`}>
                            <ListGroup>
                                <ListGroup.Item><Link to="/paciente/agenda">Agenda</Link></ListGroup.Item>
                                <ListGroup.Item><Link to="/paciente/minha-conta">Configurações</Link></ListGroup.Item>
                                <ListGroup.Item onClick={()=>dispatch({type: 'LOGOUT'})}>Sair</ListGroup.Item>
                            </ListGroup>
                        </nav>
                    </div>
                ) : <p>Já possui uma conta? <Link to="/login?redirect=/carrinho">Login</Link></p>
            }
        </div>
    );
}

function HomePageHeader(){
    const root = window.location.pathname.split('/')[1];
    return (
        <>
            <div id="home-page-header" className="space-between-row">
                <Link to="/">
                    <img className="logo" src={ logo } />
                </Link>
                {
                    !['carrinho', 'pagamento-pix'].includes(root) ? (
                        <>
                            <Menu />
                            <Auth />
                        </>
                    ) : <LabelLogin />
                }
            </div>
            <HomePageHeaderResponsive />
        </>
    );
}

export default HomePageHeader;