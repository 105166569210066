import React, { useState } from 'react';
import logo from './../../Images/logo.png';
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    Image
} from 'react-bootstrap';
import './style.css';
import { Link, redirect } from 'react-router-dom';
import ResetPassword from '../../Components/ResetPassword';
import utils from '../../utils';
import { http } from '../../services/index';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useQuery } from '../../hooks';
import store from './../../store';
import { useEffect } from 'react';

function FormLogin(){

    const query = useQuery();
    const dispatch = useDispatch();
    const { isLogged, user } = useSelector(state=>state.accountState);

    const [btnDisabled, setBtnDisabled] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    //utils.verifyAuth();

    const authentication = ()=>{
        setBtnDisabled(true);
        http.user.auth({email, password})
            .then(result=>{
                dispatch({
                    type: 'LOGIN',
                    payload: {
                        user: result.data.user,
                        token: result.data.token,
                        refreshToken: result.data.refreshToken
                    }
                });
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Erro ao fazer login',
                message: error.response.data
            }))
            .finally(()=>setBtnDisabled(false));
    }

    useEffect(()=>{
        if(!utils.verifyAuth()){
            dispatch({
                type: 'LOADING_PAGE',
                payload: true
            });
            setBtnDisabled(true);
            http.user.refresh(null, true)
                .then(result=>{
                    store.dispatch({
                        type: 'REFRESH_TOKEN',
                        payload: {
                            user: result.data.user,
                            token: result.data.token,
                            refreshToken: result.data.refreshToken
                        }
                    });
                    user.type === 'admin' ?
                    redirect("/admin/dashboard") :
                    (
                        user.type === 'paciente' ?
                        redirect("/paciente/agenda") :
                        redirect("/psicologo/dashboard")
                    )
                })
                .catch(()=>{
                    store.dispatch({type: 'LOGOUT'})
                })
                .finally(()=>setBtnDisabled(false));
        }
    }, []);

    return (
        <Form>
            {
                utils.verifyAuth() ? (
                    query.get("redirect") && user.type === 'paciente' ? (
                        <Navigate replace to={ query.get("redirect") } />
                    ) : (
                        user.type === 'admin' ?
                        <Navigate replace to="/admin/dashboard" /> :
                        (
                            user.type === 'paciente' ?
                            <Navigate replace to="/paciente/agenda" /> :
                            <Navigate replace to="/psicologo/dashboard" />
                        )
                    )
                )
                : false
            }
            <Link to="/">
                <Image draggable={false} src={logo} />
            </Link>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                    type="email"
                    placeholder="E-mail"
                    onChange={e=>setEmail(e.target.value)}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control
                    className="mb-2"
                    type="password"
                    placeholder="Senha"
                    onChange={e=>setPassword(e.target.value)}
                />
                <ResetPassword />
            </Form.Group>
            <Button
                className='mb-2'
                variant="primary"
                disabled={btnDisabled}
                onClick={()=>authentication()}>
                Entrar
            </Button>
            <div className="register">
                <Link to="/carrinho">Cadastre-se</Link>
            </div>
        </Form>
    );
}

export default function Login(){
    return (
        <div id='login'>
            <Container>
                <Row>
                    <Col>
                        <FormLogin />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}