import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

export default  function PixPayment({submit}){

    const dispatch = useDispatch();
    const { paymentInfo } = useSelector(state=>state.paymentRegisterPatientState);
    const { isLoading, info } = useSelector(state=>state.registerPatientState);
    const check = useRef(null);

    useEffect(()=>{
        if(info.errors?.checkedTerm) check.current.classList.add('is-invalid');
        else check.current.classList.remove('is-invalid');
    }, [info.errors?.checkedTerm]);

    return (
        <Container fluid>
            <Row>
                <Col>
                <Form className='card-payment-inputs'>
                    <Form.Group className="mb-3" controlId="exampleForm.name">
                        <Form.Control className={ info?.errors?.paymentInfo?.name ? 'is-invalid' : '' } value={ paymentInfo?.name || '' } name="name" onFocus={ e=>dispatch({
                            type: 'INFO_PAYMENT_REGISTER_PATIENT',
                            payload: {
                            focus: e.target.name
                            }
                        }) } onChange={ e=>dispatch({
                            type: 'INFO_PAYMENT_REGISTER_PATIENT',
                            payload: {
                                name: e.target.value
                            }
                            }) } type="text" placeholder="Nome" />
                        <div class="invalid-feedback">
                            {
                            info?.errors?.paymentInfo?.name
                            }
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.email">
                        <Form.Control className={ info?.errors?.paymentInfo?.email ? 'is-invalid' : '' } value={ paymentInfo?.email || '' } name="email" onFocus={ e=>dispatch({
                                type: 'INFO_PAYMENT_REGISTER_PATIENT',
                                payload: {
                                    focus: e.target.name
                                }
                        }) } onChange={ e=>dispatch({
                            type: 'INFO_PAYMENT_REGISTER_PATIENT',
                            payload: {
                                email: e.target.value
                            }
                        }) } type="text" placeholder="E-mail" />
                        <div class="invalid-feedback">
                            {
                            info?.errors?.paymentInfo?.email
                            }
                        </div>
                    </Form.Group>
                    <Row>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Control type="text" className={ info?.errors?.paymentInfo?.document ? 'is-invalid' : '' } value={ paymentInfo?.document || '' } name="document" placeholder="CPF ou CNPJ" onFocus={ e=>dispatch({
                                    type: 'INFO_PAYMENT_REGISTER_PATIENT',
                                    payload: {
                                    focus: e.target.name
                                    }
                                }) } onChange={ e=>dispatch({
                                    type: 'INFO_PAYMENT_REGISTER_PATIENT',
                                    payload: {
                                        document: e.target.value
                                    }
                                }) } />
                                <div class="invalid-feedback">
                                    {
                                    info?.errors?.paymentInfo?.document
                                    }
                                </div>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Control type="text" className={ info?.errors?.paymentInfo?.phone ? 'is-invalid' : '' } value={ paymentInfo?.phone || '' } name="phone" placeholder="Telefone ou celular com código do país e DDD" onFocus={ e=>dispatch({
                                    type: 'INFO_PAYMENT_REGISTER_PATIENT',
                                    payload: {
                                    focus: e.target.name
                                    }
                                }) } onChange={ e=>dispatch({
                                    type: 'INFO_PAYMENT_REGISTER_PATIENT',
                                    payload: {
                                        phone: e.target.value
                                    }
                                }) } />
                                <div class="invalid-feedback">
                                    {
                                        info?.errors?.paymentInfo?.phone
                                    }
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className='accept-policy'>
                            <Form.Check ref={check} id="policy-check" checked={ paymentInfo?.checkedTerm } className={ 'mr-5' + (paymentInfo?.errors?.checkedTerm ? ' is-invalid' : '') } onChange={e=>dispatch({type: 'INCLUDE_POLICY_REGISTER_PATIENT', payload: { checkedTerm: e.target.checked }})} />
                            <label for="policy-check">Eu li e concordo com a <a id='policy-privacy' href='/politica-de-privacidade' target='_blank'>Política de Privacidade</a> e os <a id='policy-term' href='/termos-de-uso' target="_blank">Termos de Uso</a> da Psy Up.</label>
                            <div class="invalid-feedback">
                                {
                                info.errors?.checkedTerm
                                }
                            </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {
                            isLoading ? <Spinner animation="border" role="status" /> : <Button onClick={submit} className='finish-buy'>Finalizar compra</Button>
                            }
                        </Col>
                    </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}