import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';
import './style.css';

export default function DashboardPanel({icon, color, title, value}){
    
    return (
        <div className='dashboard-panel' style={{backgroundColor: color}}>
            <Row>
                <Col xs={9}>
                    <Row>
                        <Col>
                            <span>{ title }</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>{ value }</Col>
                    </Row>
                </Col>
                <Col xs={3}>
                    <Row>
                        <Col>
                            <FontAwesomeIcon
                                size='2x'
                                icon={ icon }
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}