import React from 'react';
import { Row, Col } from 'react-bootstrap';
import about from './../../Images/about.svg';
import './style.css';

export default function Home(){
    return (
        <Row id='about'>
            <Col xs={{span: 12, order: 2}} md={{span: 6, order: 1}}>
                <img
                    style={{width: '100%'}}
                    src={ about }
                    draggable={ false }
                />
            </Col>
            <Col xs={{span: 12, order: 1}} md={{span: 6, order: 2}}>
                <h1>Quem Somos</h1>
                <p>Cansado de procurar um profissional e ficar perdido?</p>
                <p>“Qual a diferença entre cada psicólogo?”</p>
                <p>“Por que cada psicólogo cobra um valor?”</p>
                <p>“Como escolher o melhor profissional para o meu caso?”</p>
                <p>Para responder perguntas como essas, nasceu a PsyUp, facilitando a conexão entre pacientes e psicólogos.</p>
                <p>Nos conte o que está acontecendo contigo e fazemos todo o resto!</p>
            </Col>
        </Row>
    );
}