import React, { useState, useEffect } from 'react';
import Table from '../../Components/Table';
import Modal from '../../Components/Modal';
import { Form, Spinner, Container, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { http } from '../../services';
import utils from './../../utils';
import store from '../../store';

const config = {
    columns: [
        { name: 'id', translate: 'Id' },
        { name: 'description', translate: 'Descrição'},
        { name: 'cep', translate: 'Cep' },
        { name: 'address', translate: 'Endereço' },
        { name: 'number', translate: 'N°' },
        { name: 'district', translate: 'Distrito' },
        { name: 'complement', translate: 'Complemento' },
        { name: 'city', translate: 'Cidade' },
        { name: 'state', translate: 'Estado' }
    ],
    include: {
        cta: "Incluir",
        title: 'Incluir unidade',
        ctaFn: ()=>{
            http.unit.create(store.getState().formState.form)
                .then(result=>{
                    store.dispatch({
                        type: 'INCLUDE_UNIT',
                        payload: result.data
                    });
                    utils.createNotification({
                        type: 'success',
                        title: 'Unidade criada com sucesso'
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: 'Erro ao criar unidade',
                    message: error.response.data
                }))
                .finally(()=>store.dispatch({
                    type: 'CLEAR_FORM'
                }));
        }
    },
    update: {
        cta: "Editar",
        title: 'Editar unidade',
        ctaFn: ({id})=>{
            http.unit.update(id, store.getState().formState.form)
                .then(result=>{
                    store.dispatch({
                        type: 'UPDATE_UNIT',
                        payload: result.data
                    });
                    utils.createNotification({
                        type: 'success',
                        title: `As informações da unidade ${id} foram alteradas!`
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: `Erro ao editar unidade ${id}`,
                    message: error.response.data
                }))
                .finally(()=>store.dispatch({
                    type: 'CLEAR_FORM'
                }));
        }
    },
    remove: {
        cta: "Deletar",
        title: 'Deletar unidade',
        ctaFn: ({ id })=>{
            http.unit.destroy(id)
                .then(()=>{
                    store.dispatch({
                        type: 'DELETE_UNIT',
                        payload: {
                            id
                        }
                    });
                    utils.createNotification({
                        type: 'success',
                        title: 'Unidade deletada',
                        message: `A unidade ${id} foi deletada!`
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: `Erro ao deletar unidade ${id}`,
                    message: error.response.data
                }));
        },
        style: {
            backgroundColor: 'var(--color-danger)'
        }
    }
}

function IncludeContent(){

    const dispatch = useDispatch();

    return (
        <Form>
            <Container>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="formBasicDescription">
                            <Form.Label>Descrição</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Descrição"
                                onChange={e=>dispatch({
                                    type: 'SAVE_FORM',
                                    payload: {
                                        description: e.target.value
                                    }
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="formBasicCep">
                            <Form.Label>Cep</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Cep"
                                onChange={e=>dispatch({
                                    type: 'SAVE_FORM',
                                    payload: {
                                        cep: e.target.value
                                    }
                                })}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="formBasicAddress">
                            <Form.Label>Rua</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Endereço"
                                onChange={e=>dispatch({
                                    type: 'SAVE_FORM',
                                    payload: {
                                        address: e.target.value
                                    }
                                })}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="formBasicNumber">
                            <Form.Label>Número</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Número"
                                onChange={e=>dispatch({
                                    type: 'SAVE_FORM',
                                    payload: {
                                        number: e.target.value
                                    }
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="formBasicDistrict">
                            <Form.Label>Bairro</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Bairro"
                                onChange={e=>dispatch({
                                    type: 'SAVE_FORM',
                                    payload: {
                                        district: e.target.value
                                    }
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicComplement">
                            <Form.Label>Complemento</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Complemento"
                                onChange={e=>dispatch({
                                    type: 'SAVE_FORM',
                                    payload: {
                                        complement: e.target.value
                                    }
                                })}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="formBasicCity">
                            <Form.Label>Cidade</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Cidade"
                                onChange={e=>dispatch({
                                    type: 'SAVE_FORM',
                                    payload: {
                                        city: e.target.value
                                    }
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="formBasicState">
                            <Form.Label>Estado</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Estado"
                                onChange={e=>dispatch({
                                    type: 'SAVE_FORM',
                                    payload: {
                                        state: e.target.value
                                    }
                                })}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
        </Form>
    );
}

function UpdateContent(props){

    const dispatch = useDispatch();

    const [description, setDescrition] = useState(props.description);
    const [cep, setCep] = useState(props.cep);
    const [address, setAddress] = useState(props.address);
    const [number, setNumber] = useState(props.number);
    const [district, setDistrict] = useState(props.district);
    const [complement, setComplement] = useState(props.complement);
    const [city, setCity] = useState(props.city);
    const [state, setState] = useState(props.state);

    useEffect(()=>{
        dispatch({
            type: 'SAVE_FORM',
            payload: {
                description, cep, address, number, district, complement, city, state
            }
        });
    }, [description, cep, address, number, district, complement, city, state]);

    return (
        <Form>
            <Container>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="formBasicDescription">
                            <Form.Label>Descrição</Form.Label>
                            <Form.Control
                                value={ description }
                                type="text"
                                placeholder="Descrição"
                                onChange={e=>setDescrition(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="formBasicCep">
                            <Form.Label>Cep</Form.Label>
                            <Form.Control
                                value={ cep }
                                type="text"
                                placeholder="Cep"
                                onChange={e=>setCep(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="formBasicAddress">
                            <Form.Label>Rua</Form.Label>
                            <Form.Control
                                value={ address }
                                type="text"
                                placeholder="Endereço"
                                onChange={e=>setAddress(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="formBasicNumber">
                            <Form.Label>Número</Form.Label>
                            <Form.Control
                                value={ number }
                                type="text"
                                placeholder="Número"
                                onChange={e=>setNumber(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="formBasicDistrict">
                            <Form.Label>Bairro</Form.Label>
                            <Form.Control
                                value={ district }
                                type="text"
                                placeholder="Bairro"
                                onChange={e=>setDistrict(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicComplement">
                            <Form.Label>Complemento</Form.Label>
                            <Form.Control
                                value={ complement }
                                type="text"
                                placeholder="Complemento"
                                onChange={e=>setComplement(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="formBasicCity">
                            <Form.Label>Cidade</Form.Label>
                            <Form.Control
                                value={ city }
                                type="text"
                                placeholder="Cidade"
                                onChange={e=>setCity(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="formBasicState">
                            <Form.Label>Estado</Form.Label>
                            <Form.Control
                                value={ state }
                                type="text"
                                placeholder="Estado"
                                onChange={e=>setState(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
        </Form>
    );
}

function RemoveContent({ id }){
    return (
        <p>Deseja deletar a unidade {id}?</p>
    );
}

function TableActions(props){

    return (
        <>
            <Modal
                config={ config.update }
                row={ props.row }
                content={ <UpdateContent
                    { ...props.row }
                /> }
            />
            <Modal
                config={ Object.assign({}, config.remove, { data: { id: props.row.id } }) }
                row={ props.row }
                content={ <RemoveContent
                    { ...props.row }
                /> }
            />
        </>
    );
}

export default function Units(){

    const { isLoading } = useSelector(state=>state.unitState);
    const dispatch = useDispatch();

    const units = useSelector(state=>state.unitState.units);

    useEffect(()=>{

        dispatch({
            type: 'IS_LOADING_UNIT',
            payload: true
        });

        http.unit.findAll()
            .then(result=>{
                dispatch({
                    type: 'LOAD_UNIT',
                    payload: result.data
                });
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Erro ao carregar unidades',
                message: error.response.data
            }))
            .finally(()=>dispatch({
                type: 'IS_LOADING_UNIT',
                payload: false
            }));
    }, []);

    return (
        <div id='table'>
            <Modal
                config={ config.include }
                content={ <IncludeContent
                /> }
            />
            {
                isLoading ? <div><Spinner animation="border" /></div> :
                <Table
                    data={ units }
                    config={ config }
                    Actions={ TableActions }
                />
            }
        </div>
    );
}