import utils from '../../../utils';

const INITIAL_STATE = {
    isLoading: false,
    isCreating: false,
    attendances: [],
    filters: {
        id: '',
        createdAt: '',
        hour: '',
        name: '',
        type: '',
        active: '',
        activePage: 1,
        totalPages: 1
    }
}

INITIAL_STATE.attendances.__proto__.formatted = function(){
    return this.map(attendance=>utils.formatObj(attendance));
};

const attendanceReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'CREATING_ATTENDANCE':
            return {
                ...state, 
                isCreating: payload
            };
        case 'LOAD_ATTENDANCE':
            return {
                ...state, 
                attendances: payload.rows.map(row=>{
                    let newRow = Object.assign({}, row);
                    newRow.psychologistName = newRow.psychologistattendance.name;
                    newRow.patientName = newRow.patientattendance.name;
                    newRow.planName = newRow.planattendance.name;
                    return newRow;
                }),
                filters: {
                    ...state.filters,
                    totalPages: payload.totalPages
                },
                isLoading: false
            };
        case 'FILTER_ATTENDANCE':
            return {
                ...state, 
                filters: Object.assign({}, state.filters, payload)
            };
        case 'IS_LOADING_ATTENDANCE':
            return {
                ...state,
                isLoading: payload
            };
        case 'INCLUDE_ATTENDANCE':
            return {
                ...state, 
                attendances: [...state.attendances, payload]
            };
        case 'UPDATE_ATTENDANCE':
            return {
                ...state, 
                attendances: state.attendances.map(attendance=>(attendance.id === payload.id) ? Object.assign({}, attendance, payload) : attendance)
            };
        case 'DELETE_ATTENDANCE':
            return {
                ...state, 
                attendances: state.attendances.filter(attendance=>attendance.id != payload.id)
            };
        default:
            return state;
    }
}

export default attendanceReducer;