const INITIAL_STATE = {
    isLoading: false,
    users: [],
    filters: {
        id: '',
        email: '',
        name: '',
        type: '',
        active: '',
        activePage: 1,
        totalPages: 1
    }
}

const userReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'LOAD_USER':
            return {
                ...state, 
                users: payload.rows,
                filters: {
                    ...state.filters,
                    totalPages: payload.totalPages
                },
                isLoading: false
            };
        case 'FILTER_USER':
            return {
                ...state, 
                filters: Object.assign({}, state.filters, payload)
            };
        case 'IS_LOADING_USER':
            return {
                ...state,
                isLoading: payload
            };
        case 'INCLUDE_USER':
            return {
                ...state, 
                users: [...state.users, payload]
            };
        case 'UPDATE_USER':
            return {
                ...state, 
                users: state.users.map(user=>(user.id === payload.id) ? payload : user)
            };
        case 'DELETE_USER':
            return {
                ...state, 
                users: state.users.filter(user=>user.id != payload.id)
            };
        default:
            return state;
    }
}

export default userReducer;