import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";

import Layout from '../Components/Layout';
import Dashboard from './Dashboard';
import Login from './Login';
import Account from './Account';
import Policy from './Policy';
import Reasons from './Reasons';
import Units from './Units';
import Match from './Match';
import Plan from './Plan';
import Attendances from './Attendances';
import User from './User';
import Dependent from './Dependent';
import HomePage from './HomePage';
import HomePagePolicyPrivacy from './HomePagePolicyPrivacy';
import HomePagePageTermsOfUse from './HomePageTermsOfUse';
import Cart from './Cart';
import Schedule from './Schedule';
import Payments from './Payments';
import PixQRCode from './PixQRCode';
import Loading from './Loading';
import { useSelector, useDispatch } from 'react-redux';
import { http } from '../services';

export default function Pages(){

    const { loading } = useSelector(state=>state.pageState);
    const dispatch = useDispatch();

    http.configUtils.findAll()
        .then(result=>{
            dispatch({
            type: "LOAD_CONFIG_UTILS",
            payload: result.data
        })});

    return  (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/carrinho" element={<Cart />} />
                <Route path="/carrinho/psicologo" element={<Cart />} />
                <Route path="/pagamento-pix" element={<PixQRCode />} />
                <Route path="/politica-de-privacidade" element={<HomePagePolicyPrivacy />} />
                <Route path="/termos-de-uso" element={<HomePagePageTermsOfUse />} />
                <Route path="/login" element={<Login />} />
                <Route path="/admin/login" element={<Login />} />
                <Route path="admin" element={<Layout />}>
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="politicas" element={<Policy />} />
                    <Route path="motivos" element={<Reasons />} />
                    <Route path="unidades" element={<Units />} />
                    <Route path="match" element={<Match />} />
                    <Route path="planos" element={<Plan />} />
                    <Route path="atendimentos" element={<Attendances />} />
                    <Route path="usuarios" element={<User />} />
                    <Route path="minha-conta" element={<Account />} />
                </Route>
                <Route path="paciente" element={<Layout />}>
                    <Route path="dependentes" element={<Dependent />} />
                    <Route path="agenda" element={<Schedule />} />
                    <Route path="historico-de-pagamentos" element={<Payments />} />
                    <Route path="minha-conta" element={<Account />} />
                </Route>
                <Route path="psicologo" element={<Layout />}>
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="agenda" element={<Schedule />} />
                    <Route path="minha-conta" element={<Account />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}