import { combineReducers } from 'redux';
import pageReducer from './pageReducer';
import stepperReducer from './stepperReducer';
import infoRegisterReducer from './infoRegisterReducer';
import notificationReducer from './notificationReducer';
import modalInfoReducer from './modalInfoReducer';
import accountReducer from './accountReducer';
import attendanceReducer from './attendanceReducer';
import formReducer from './formReducer';
import userReducer from './userReducer';
import policyReducer from './policyReducer';
import planReducer from './planReducer';
import matchReducer from './matchReducer';
import unitReducer from './unitReducer';
import reasonReducer from './reasonReducer';
import dependentReducer from './dependentReducer';
import registerPatientReducer from './registerPatientReducer';
import scheduleReducer from './scheduleReducer';
import paymentReducer from './paymentReducer';
import paymentRegisterPatientReducer from './paymentRegisterPatientReducer';
import configUtilsReducer from './configUtilsReducer';

export default combineReducers({
    configUtilsState: configUtilsReducer,
    pageState: pageReducer,
    stepperState: stepperReducer,
    infoRegisterState: infoRegisterReducer,
    notificationState: notificationReducer,
    modalInfoState: modalInfoReducer,
    accountState: accountReducer,
    attendanceState: attendanceReducer,
    formState: formReducer,
    userState: userReducer,
    policyState: policyReducer,
    planState: planReducer,
    matchState: matchReducer,
    unitState: unitReducer,
    reasonState: reasonReducer,
    dependentState: dependentReducer,
    registerPatientState: registerPatientReducer,
    scheduleState: scheduleReducer,
    paymentState: paymentReducer,
    paymentRegisterPatientState: paymentRegisterPatientReducer
});