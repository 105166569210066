import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {
    faClipboardUser,
    faHandHoldingDollar,
    faSackDollar
} from '@fortawesome/free-solid-svg-icons';
import DashboardPanel from '../../Components/DashboardPanel';
import './style.css';
import GraphBar from './../../Components/GraphBar';
import { http } from './../../services';
import utils from '../../utils';
import { useSelector } from 'react-redux';

export default function Dashboard(){

    const [info, setInfo] = useState({});
    const user = useSelector(state=>state.accountState.user);

    useEffect(()=>{
        http.dashboard.admin()
            .then(result=>setInfo(result.data))
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Erro ao carregar informações do dashboard',
                message: error.response.data
            }));
    }, []);

    return (
        <Container id='dashboard' fluid>
            <Row>
                {
                    user.type === 'admin' && (
                        <>
                            <Col className={ `col-sm-12 col-md-4` }>
                                <DashboardPanel 
                                    color="var(--color-primary)"
                                    icon={ faSackDollar }
                                    title="Receita dos Atendimentos"
                                    value={ utils.formatPrice(info.attendanceAmount || 0) }
                                />
                            </Col>
                            <Col className={ `col-sm-12 col-md-4` }>
                                <DashboardPanel 
                                    color="var(--color-main)"
                                    icon={ faHandHoldingDollar }
                                    title="Receita dos Pagamentos"
                                    value={ utils.formatPrice(info.paymentAmount) || 0 }
                                />
                            </Col>
                        </>
                    )
                }
                <Col className={ `col-sm-12 col-md-4` }>
                    <DashboardPanel 
                        color="var(--color-active)"
                        icon={ faClipboardUser }
                        title="Total de Atendimentos"
                        value={info.attendanceQuantity || 0}
                    />
                </Col>
            </Row>
            <Row className='mt-5'>
                <GraphBar { ...info.attendanceWeekDay } />
            </Row>
        </Container>
    );
}