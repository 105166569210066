const INITIAL_STATE = {
    isLoading: false,
    info: {
        checkedTerm: false,
        planId: null,
        typeAttendance: null,
        modality: null,
        account: {},
        hours: {},
        unitId: null,
        dependent: {},
        errors: {}
    },
    isSuccess: function(){
        return Boolean(this.info.planId);
    },
    isFirstStateSuccess: function(){
        return Boolean(this.info.planId);
    },
    isSecondStateSuccess: function(){
        return Boolean(this.info.unitId);
    }
}

const registerPatientReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'INCLUDE_REGISTER_PATIENT':
            return {
                ...state, 
                info: Object.assign({}, state.info, payload),
                errors: Object.assign({}, Object.entries(state.info.errors)
                .filter(entry=>{
                    return entry[0] !== Object.keys(payload)[0];
                })
                .reduce((acc, current)=>{
                    let newObj = {};
                    newObj[current[0]] = current[1];
                    return Object.assign({}, acc, newObj);
                }, {}))
            };
        case 'INCLUDE_POLICY_REGISTER_PATIENT':
            let newErrorFilterPolicy = Object.assign({}, state.info.errors);
            delete newErrorFilterPolicy.checkedTerm;

            return {
                ...state, 
                info: Object.assign({}, state.info, {
                    ...payload,
                    errors: newErrorFilterPolicy
                })
            };
        case 'INFO_PAYMENT_REGISTER_PATIENT':
            return {
                ...state,
                info: Object.assign({}, state.info, {
                    paymentInfo: typeof payload === 'object' ? 
                        Object.assign({}, state.info.paymentInfo, payload) : {}
                })
            };
        case 'INCLUDE_ACCOUNT_REGISTER_PATIENT':
            let newErrorFilterAccount = Object.assign({}, state.info.errors);
            delete newErrorFilterAccount.account;
            let newErrorAccount = state.info.errors.account && Object.entries(state.info.errors.account)
                .filter(entry=>entry[0] != Object.keys(payload)[0])
                .reduce((acc, current)=>{
                    let newObj = {};
                    newObj[current[0]] = current[1];
                    console.log(current[0]);
                    return Object.assign({}, acc, newObj);
                }, {});
            newErrorFilterAccount.account = newErrorAccount;

            return {
                ...state, 
                info: Object.assign({}, state.info, {
                    account: Object.assign({}, state.info.account, payload),
                    errors: newErrorFilterAccount
                })
            };
        case 'MARK_HOUR_REGISTER_PATIENT':
            let obj = Object.assign({}, state.info.hours);
            if(!obj[payload[0]]) obj[payload[0]] = [];
            let newArr = [...obj[payload[0]], payload[1]];
            obj[payload[0]] = newArr;
            
            return {
                ...state, 
                info: Object.assign({}, state.info, {
                    hours: obj
                })
            };
        case 'SELECT_UNIT_PATIENT':
            console.log(payload);
            return {
                ...state, 
                info: Object.assign({}, state.info, {
                    unitId: payload
                })
            };
        case 'MARKOFF_HOUR_REGISTER_PATIENT':
            return {
                ...state, 
                info: Object.assign({}, state.info, {
                    hours: Object.defineProperty(Object.assign({}, state.info.hours), payload[0], {
                        value: state.info.hours[payload[0]].filter(h=>h != payload[1])
                    })
                })
            };
        case 'INCLUDE_DEPENDENT_REGISTER_PATIENT':
            return {
                ...state, 
                info: Object.assign({}, state.info, {
                    dependent: Object.assign({}, state.info.dependent, payload),
                })
            };
        case 'CLEAR_REGISTER_PATIENT':
            return {
                ...state, 
                info: {
                    planId: 0,
                    reasonId: 0,
                    typeAttendance: null,
                    modality: null,
                    account: {},
                    hours: {},
                    dependent: {},
                    errors: {}
                }
            };
        case 'IS_LOADING_REGISTER_PATIENT':
            return {
                ...state, 
                isLoading: payload
            };
        case 'SET_ERRORS_REGISTER_PATIENT':
            return {
                ...state, 
                info: Object.assign({}, state.info, {
                    errors: Object.assign({}, state.info.errors, payload)
                })
            };
        case 'CLEAR_ERRORS_REGISTER_PATIENT':
            return {
                ...state, 
                info: Object.assign({}, state.info, {
                    errors: {}
                })
            };
        default:
            return state;
    }
}

export default registerPatientReducer;