import React, { useState, useEffect } from 'react';
import Table from '../../Components/Table';
import Modal from '../../Components/Modal';
import { Form, Button, Spinner, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { http } from '../../services';
import utils from './../../utils';
import store from '../../store';
import Filter from '../../Components/Filter';
import {
    useSearchParams
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../../Components/Pagination';

const config = {
    columns: [
        { name: 'id', translate: 'Id' },
        { name: 'psicologoId', translate: 'Psicólogo - Id'},
        { name: 'psicologoName', translate: 'Psicólogo - Nome' },
        { name: 'pacienteId', translate: 'Paciente - Id' },
        { name: 'pacienteName', translate: 'Paciente - Nome' }
    ],
    include: {
        cta: "Incluir",
        title: 'Incluir match',
        ctaFn: ()=>{
            http.match.create(store.getState().formState.form)
                .then(result=>{
                    store.dispatch({
                        type: 'INCLUDE_MATCH',
                        payload: result.data
                    });
                    utils.createNotification({
                        type: 'success',
                        title: 'Match criado com sucesso'
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: 'Erro ao criar match',
                    message: error.response.data
                }))
                .finally(()=>store.dispatch({
                    type: 'CLEAR_FORM'
                }));
        }
    },
    update: {
        cta: "Editar",
        title: 'Editar match',
        ctaFn: ({id})=>{
            http.match.update(id, store.getState().formState.form)
                .then(result=>{
                    store.dispatch({
                        type: 'UPDATE_MATCH',
                        payload: result.data
                    });
                    utils.createNotification({
                        type: 'success',
                        title: `As informações do match ${id} foram alteradas!`
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: `Erro ao editar match ${id}`,
                    message: error.response.data
                }))
                .finally(()=>store.dispatch({
                    type: 'CLEAR_FORM'
                }));
        }
    },
    remove: {
        cta: "Deletar",
        title: 'Deletar match',
        ctaFn: ({ id })=>{
            http.match.destroy(id)
                .then(()=>{
                    store.dispatch({
                        type: 'DELETE_MATCH',
                        payload: {
                            id
                        }
                    });
                    utils.createNotification({
                        type: 'success',
                        title: 'Match deletado',
                        message: `O match ${id} foi deletado!`
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: `Erro ao deletar match ${id}`,
                    message: error.response.data
                }));
        },
        style: {
            backgroundColor: 'var(--color-danger)'
        }
    }
}

function FilterContent(){
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const [id, setId] = useState(searchParams.get('id') || '');
    const [psicologoId, setPsicologoId] = useState(searchParams.get('psicologoId') || '');
    const [pacienteId, setPacienteId] = useState(searchParams.get('pacienteId') || '');
    const [psicologoName, setPsicologoName] = useState(searchParams.get('psicologoName') || '');
    const [pacienteName, setPacienteName] = useState(searchParams.get('pacienteName') || '');

    const toFilter = (obj={})=>{
        dispatch({ type: 'FILTER_MATCH', payload: obj });
        setSearchParams(Object.entries(obj).reduce((acc, current)=>{
            if(current[1]) return acc + (acc ? `&${current[0]}=${current[1]}` : `${current[0]}=${current[1]}`);
            return acc;
        }, ''));
        dispatch({
            type: 'IS_LOADING_MATCH',
            payload: true
        });
        http.match.findAll(obj)
            .then(result=>{
                dispatch({
                    type: 'LOAD_MATCH',
                    payload: result.data
                });
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Erro ao carregar matches',
                message: error.response.data
            }))
            .finally(()=>dispatch({
                type: 'IS_LOADING_MATCH',
                payload: false
            }));
    }

    return (
        <Row xs={1} md={4} lg={6}>
            <Col>
                <Form.Group className="mb-3" controlId="formBasicId">
                    <Form.Label>Id</Form.Label>
                    <Form.Control value={ id } onChange={ e=>setId(e.target.value) } type="text" placeholder="Digite o id do match" />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="formBasicPsicologoId">
                    <Form.Label>Psicólogo - Id</Form.Label>
                    <Form.Control value={ psicologoId } onChange={ e=>setPsicologoId(e.target.value) } type="text" placeholder="Digite o id do psicólogo" />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="formBasicPsicologoName">
                    <Form.Label>Psicólogo - Nome</Form.Label>
                    <Form.Control value={ psicologoName } onChange={ e=>setPsicologoName(e.target.value) } type="text" placeholder="Digite o nome do psicólogo" />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="formBasicPacienteId">
                    <Form.Label>Paciente - Id</Form.Label>
                    <Form.Control value={ pacienteId } onChange={ e=>setPacienteId(e.target.value) } type="text" placeholder="Digite o id do paciente" />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="formBasicPacienteName">
                    <Form.Label>Paciente - Nome</Form.Label>
                    <Form.Control value={ pacienteName } onChange={ e=>setPacienteName(e.target.value) } type="text" placeholder="Digite o nome do paciente" />
                </Form.Group>
            </Col>
            <Col>
                {<Form.Group id="filter-btn" className="mb-3" controlId="formBasicEmail">
                    <Form.Label></Form.Label>
                        <Button onClick={()=>toFilter({
                            activePage: 1,
                            id,
                            psicologoId,
                            pacienteId,
                            psicologoName,
                            pacienteName
                        })} variant="success">
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </Button>
                </Form.Group>}
            </Col>
        </Row>
    );
}

function IncludeContent(){

    const dispatch = useDispatch();

    return (
        <Form>
            <Form.Group className="mb-3" controlId="formBasicDate" style={{width: 'auto'}}>
                <Form.Control
                    type="text"
                    placeholder="Psicólogo"
                    onChange={e=>dispatch({
                        type: 'SAVE_FORM',
                        payload: {
                            psicologoId: e.target.value
                        }
                    })}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicDate" style={{width: 'auto'}}>
                <Form.Control
                    type="text"
                    placeholder="Paciente"
                    onChange={e=>dispatch({
                        type: 'SAVE_FORM',
                        payload: {
                            pacienteId: e.target.value
                        }
                    })}
                />
            </Form.Group>
        </Form>
    );
}

function UpdateContent(props){

    const dispatch = useDispatch();

    const [psicologoId, setPsicologoId] = useState(props.psicologoId);
    const [pacienteId, setPacienteId] = useState(props.pacienteId);

    useEffect(()=>{
        dispatch({
            type: 'SAVE_FORM',
            payload: {
                psicologoId, pacienteId
            }
        });
    }, [psicologoId, pacienteId]);

    return (
        <Form>
            <Form.Group className="mb-3" controlId="formBasicDate" style={{width: 'auto'}}>
                <Form.Control
                    type="text"
                    placeholder="Psicólogo"
                    value={ psicologoId }
                    onChange={e=>setPsicologoId(e.target.value)}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicDate" style={{width: 'auto'}}>
                <Form.Control
                    type="text"
                    placeholder="Paciente"
                    value={ pacienteId }
                    onChange={e=>setPacienteId(e.target.value)}
                />
            </Form.Group>
        </Form>
    );
}

function RemoveContent({ id }){
    return (
        <p>Deseja deletar o match {id}?</p>
    );
}

function TableActions(props){

    return (
        <>
            <Modal
                config={ config.update }
                row={ props.row }
                content={ <UpdateContent
                    { ...props.row }
                /> }
            />
            <Modal
                config={ Object.assign({}, config.remove, { data: { id: props.row.id } }) }
                row={ props.row }
                content={ <RemoveContent
                    { ...props.row }
                /> }
            />
        </>
    );
}

export default function Match(){

    const dispatch = useDispatch();

    const { matches, filters, isLoading } = useSelector(state=>state.matchState);
    const [searchParams, setSearchParams] = useSearchParams();

    const toFilter = (obj={})=>{
        dispatch({ type: 'FILTER_MATCH', payload: obj });
        setSearchParams(Object.entries(obj).reduce((acc, current)=>{
            if(current[1] && current[0] != "totalPages") return acc ? acc + `&${current[0]}=${current[1]}` : `${current[0]}=${current[1]}`;
            return acc;
        }, ''));
        dispatch({
            type: 'IS_LOADING_MATCH',
            payload: true
        });
        http.match.findAll(obj)
            .then(result=>{
                dispatch({
                    type: 'LOAD_MATCH',
                    payload: result.data
                });
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Erro ao carregar matches',
                message: error.response.data
            }))
            .finally(()=>dispatch({
                type: 'IS_LOADING_MATCH',
                payload: false
            }));
    }

    useEffect(()=>{
        toFilter({
            activePage: Number(searchParams.get('activePage')) || 1,
            id: searchParams.get('id'),
            psicologoId: searchParams.get('psicologoId'),
            psicologoName: searchParams.get('psicologoName'),
            pacienteId: searchParams.get('pacienteId'),
            pacienteName: searchParams.get('pacienteName')
        });
    }, []);

    return (
        <div id='table'>
            <Filter 
                content={ <FilterContent /> }
            />
            <Modal
                config={ config.include }
                content={ <IncludeContent
                /> }
            />
            {
                isLoading ? <div><Spinner animation="border" /></div> :
                <Table
                    data={ matches }
                    config={ config }
                    Actions={ TableActions }
                />
            }
            {
                filters.totalPages > 1 ? <Row>
                    <Col>
                        <Pagination
                            filters={ filters }
                            toFilter={ toFilter }
                        />
                    </Col>
                </Row> : false
            }
        </div>
    );
}