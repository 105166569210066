const INITIAL_STATE = {
    isLoadingPrivacy: false,
    isLoadingTerm: false,
    privacy: null,
    term: null
}

const policyReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'LOAD_PRIVACY':
            return {
                ...state, 
                privacy: payload.privacy,
                isLoadingPrivacy: false
            };
        case 'IS_LOADING_PRIVACY':
            return {
                ...state,
                isLoadingPrivacy: payload
            };
        case 'LOAD_TERM':
            return {
                ...state, 
                term: payload.term,
                isLoadingTerm: false
            };
        case 'IS_LOADING_TERM':
            return {
                ...state,
                isLoadingTerm: payload
            };
        default:
            return state;
    }
}

export default policyReducer;