import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Pagination from '@mui/material/Pagination';
import './style.css';

export default function PaginationC({filters, toFilter}) {

    const changePage = (e, currentPage)=>{
        toFilter(Object.assign({}, filters, {activePage: currentPage}));
    }

    return (
        <Row>
            <Col>
                <Pagination size="small" page={ Number(filters.activePage) } onChange={(e, currentPage)=>changePage(e, currentPage)} count={ filters.totalPages } />
            </Col>
        </Row>
    );
}