const INITIAL_STATE = {
    info: {
        modality: '',
        availability: {
            sunday: [],
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: []
        }
    }
}

INITIAL_STATE.info.availability.__proto__.qtd = function(){

    return Object.entries(this).reduce((acc, current)=>{
        return acc + current[1].length;
    }, 0);

};

const infoRegisterReducer = (state = INITIAL_STATE, { type, payload }) => {

    let newAvailability = state.info.availability;

    switch(type){
        case 'ADD_INFO_REGISTER':
            return { ...state,
                info: Object.assign({}, state.info, payload)
            };
        case 'ADD_AVAILABILITY':

            let hasHour = state.info.availability[payload[0]].includes(payload[1]);

            newAvailability[payload[0]] = !hasHour && state.info.availability.qtd() <= 2 ? [...state.info.availability[payload[0]], payload[1]] : state.info.availability[payload[0]];
            
            return { ...state,
                info: Object.assign({}, state.info, {
                    availability: newAvailability
                })
            };

        case 'REMOVE_AVAILABILITY':

            newAvailability[payload[0]] = state.info.availability[payload[0]].filter(hour=>hour!==payload[1]);

            return { ...state,
                info: Object.assign({}, state.info, {
                    availability: newAvailability
                })
            };

        default:
            return state;
    }
}

export default infoRegisterReducer;