import React, { useEffect, useState } from 'react';
import { Button, Modal, Container, Table, Badge, Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import './style.css';
import utils from './../../utils';
import { http } from '../../services';
import TableC from '../Table';
import useIsSuccessRegisterPatient from '../../hooks/useIsSuccessRegisterPatient';

const config = {
  columns: [
      { name: 'description', translate: 'Unidade' },
      { name: 'cep', translate: 'CEP' },
      { name: 'address', translate: 'Rua' },
      { name: 'number', translate: 'Nº' },
      { name: 'district', translate: 'Distrito' },
      { name: 'complement', translate: 'Complemento' },
      { name: 'city', translate: 'Cidade' },
      { name: 'state', translate: 'Estado' }
  ]
}

function SelectDate(){

  const dispatch = useDispatch();
  const hoursSelected = Object.entries(useSelector(state=>state.registerPatientState.info.hours));
  const [qtdElArr, setQtdElArr] = useState(0);

  useEffect(()=>{
    setQtdElArr((hoursSelected.reduce((acc, current)=>[...acc, ...current[1]], [])).length);
  }, [(hoursSelected.reduce((acc, current)=>[...acc, ...current[1]], [])).length]);

  const hours = {
    1: ['manhã', 'tarde', 'noite'],
    2: ['manhã', 'tarde', 'noite'],
    3: ['manhã', 'tarde', 'noite'],
    4: ['manhã', 'tarde', 'noite'],
    5: ['manhã', 'tarde', 'noite'],
    6: ['manhã', 'tarde', 'noite']
  };

  const days = ['segunda-feira', 'terça-feira', 'quarta-feira',
    'quinta-feira', 'sexta-feira', 'sábado'
  ];

  return (
    <div className='select-date'>
      <Container>
        {
          hoursSelected.map(hs=>{
            return hs[1].map(h=>(
              <Badge>
                { utils.capitalize(days[hs[0]]) } - { utils.capitalize(h) } <span onClick={()=>dispatch({
                  type: 'MARKOFF_HOUR_REGISTER_PATIENT',
                  payload: [ hs[0], h ]
                })}>x</span>
              </Badge>
            ));
          })
        }
        <Table responsive>
          <thead>
            <tr>
              <th>Dia</th>
              <th>Manhã</th>
              <th>Tarde</th>
              <th>Noite</th>
            </tr>
          </thead>
          <tbody>
            {
              Object.entries(hours).map((hour, index)=>(
                <tr>
                  <td>{utils.capitalize(days[index])}</td>
                  {
                    ['manhã', 'tarde', 'noite'].map(shift=>{
                      const active = hoursSelected.find(hs=>Number(hs[0]) === Number(index) && hs[1].includes(shift));
                      return (
                        <td>
                          <Button disabled={ qtdElArr >=3 } onClick={()=>{
                            !active && dispatch({
                              type: 'MARK_HOUR_REGISTER_PATIENT',
                              payload: [ index, shift ]
                            });
                          }} className={ active ? 'active' : '' }>{
                            active ? 'Selecionado' : 'Selecionar'
                          }</Button>
                        </td>
                      );
                    })
                  }
                </tr>
              ))
            }
          </tbody>
        </Table>
      </Container>
    </div>
  );
}

function TableActions(props){

  const dispatch = useDispatch();
  const unitId = useSelector(state=>state.registerPatientState.info.unitId);

  return (
      <>
        <Button className={ unitId === props.row.id ? 'active' : '' } onClick={()=>dispatch({ type: 'SELECT_UNIT_PATIENT', payload: props.row.id })}>Selecionar</Button>
      </>
  );
}

function SelectUnit(){

  const dispatch = useDispatch();
  const units = useSelector(state=>state.unitState.units);
  const unitId = useSelector(state=>state.registerPatientState.info.unitId);

  useEffect(()=>{

    dispatch({
        type: 'IS_LOADING_UNIT',
        payload: true
    });

    http.unit.findAll()
        .then(result=>{
            dispatch({
                type: 'LOAD_UNIT',
                payload: result.data
            });
        })
        .catch(error=>utils.createNotification({
            type: 'error',
            title: 'Erro ao carregar unidades',
            message: error.response.data
        }))
        .finally(()=>dispatch({
            type: 'IS_LOADING_UNIT',
            payload: false
        }));
  }, []);

  return (
    <div className='select-unit'>
      {
        unitId && <Badge>{ units.find(unit=>unit.id === unitId)?.description }<span onClick={()=>dispatch({type: "SELECT_UNIT_PATIENT", payload: null})}>x</span></Badge>
      }
      <TableC
          data={ units }
          config={ config }
          Actions={ TableActions }
      />
    </div>
  );
}

function MyVerticallyCenteredModal(props) {

  const hoursSelected = Object.entries(useSelector(state=>state.registerPatientState.info.hours));
  const modality = useSelector(state=>state.registerPatientState.info.modality);
  const unitId = useSelector(state=>state.registerPatientState.info.unitId);
  const [qtdElArr, setQtdElArr] = useState(0);
  const [stateCurrent, setStateCurrent] = useState(0);

  useEffect(()=>{
    setQtdElArr((hoursSelected.reduce((acc, current)=>[...acc, ...current[1]], [])).length);
  }, [(hoursSelected.reduce((acc, current)=>[...acc, ...current[1]], [])).length]);

  useEffect(()=>{
    setStateCurrent(0);
  }, [props.show]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="home-select-date-location-register-patient"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {
            stateCurrent ? 'Informe a unidade mais próxima para você' : 'Informe a sua disponibilidade'
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          !stateCurrent ? <SelectDate /> : <SelectUnit />
        }
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Fechar</Button>
        <Button disabled={!stateCurrent} onClick={()=>setStateCurrent(stateCurrent - 1)}>Voltar</Button>
        <Button disabled={
          (stateCurrent && !unitId) ||
          qtdElArr < 1} onClick={()=>{
            if(stateCurrent || modality !== 'presencial'){
              props.onHide();
            } else {
              setStateCurrent(stateCurrent + 1);
            }
          }}>Prosseguir</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function HomeSelectDateLocationRegisterPatient() {
  const [modalShow, setModalShow] = React.useState(false);
  const hoursSelected = Object.entries(useSelector(state=>state.registerPatientState.info.hours)).filter(h=>Boolean(h[1]?.length));
  const { unitId, modality } = useSelector(state=>state.registerPatientState.info);
  const status = useIsSuccessRegisterPatient();

  return (
    <>
      {/*<Button className={ 'btnmodal-home-select-date-location-register-patient' +
       ( status.includes('second') ? ' active' : '') } variant="primary" onClick={() => setModalShow(true)}>
        Escolha data e local da consulta         
      </Button>*/}
      <Card onClick={() => setModalShow(true)} className={ `stage-second${
        hoursSelected.length &&
        ((unitId && modality === 'presencial') || modality === 'online')
          ? ' active' : ''
      }` } style={{ width: '100%', minHeight: '18rem' }}>
        <Card.Body>
            <div className='title'>
                <p><b>02</b></p>
            </div>
            <div className='content'>
                <h2>Disponibilidade e local</h2>
                <p>Selecione sua disponibilidade de horário e preferência de localidade</p>
            </div>
        </Card.Body>
      </Card>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}