import React from 'react';
import { Table } from 'react-bootstrap';
import './style.css';
import utils from './../../utils';

const formatValues = (type, value)=>({
    currency: utils.formatPrice(value),
    date: utils.formatDate(value),
    boolean: utils.formatBoolean(value),
    status_payment: utils.formatStatusPayment(value)
})[type] || value;

export default function TableC({ data, Actions, config, Footer }){
    return (
        <Table id='table' responsive>
            <thead>
                <tr>
                    {
                        config.columns.map(column=><th>{ column.translate }</th>)
                    }
                    {
                        Actions ? <th>Ações</th> : false
                    }
                </tr>
            </thead>
            <tbody>
                {
                    data.map(row=>(<tr>
                            {
                                config.columns.map(column=>{
                                    return <td>{ formatValues(column.type, row[column.name]) }</td>;
                                })
                            }
                            {Actions ? <td>
                                <Actions
                                    row={ row }
                                />
                            </td> : false}
                        </tr>))
                }
            </tbody>
            {
                Footer ? <tfoot>
                    {
                        Footer
                    }
                </tfoot> : false
            }
        </Table>
    );
}