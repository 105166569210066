import React, { useEffect } from 'react';
import Header from './../../Components/HomePageHeader';
import { Container, Row, Col } from 'react-bootstrap';
import Home from './../../Components/Home';
import About from './../../Components/About';
import Contact from './../../Components/Contact';
import Services from './../../Components/Services';
import './style.css';
import BtnWhatsApp from './../../Components/ButtonWhatsApp';
import { useDispatch } from 'react-redux';
import { http } from '../../services';
import PlanIndividual from './../../Images/plan-individual.svg';
import PlanIndividualSelected from './../../Images/plan-individual-selected.svg';
import PlanCouple from './../../Images/plan-couple.svg';
import PlanCoupleSelected from './../../Images/plan-couple-selected.svg';
import PlanChild from './../../Images/plan-child.svg';
import PlanChildSelected from './../../Images/plan-child-selected.svg';
import utils from '../../utils';
import Footer from '../../Components/Footer';
import config from '../../config';

export default function HomePage(){

    const dispatch = useDispatch();

    useEffect(()=>{
        http.plan.findAll()
            .then(result=>{
                dispatch({
                    type: 'LOAD_PLAN',
                    payload: result.data.map((plan, id)=>{
                        let newObj = Object.create(plan);
                        if(id === 0){
                            newObj.image = PlanIndividual;
                            newObj.imageSelected = PlanIndividualSelected;
                        } else if(id === 1){
                            newObj.image = PlanCouple;
                            newObj.imageSelected = PlanCoupleSelected;
                        } else {
                            newObj.image = PlanChild;
                            newObj.imageSelected = PlanChildSelected;
                        }
                        return newObj;
                    })
                });
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Erro ao carregar planos',
                message: error.response.data
            }))
            .finally(()=>dispatch({
                type: 'IS_LOADING_PLAN',
                payload: false
            }));
    }, []);

    return (
        <Container id='home-page' fluid>
            <BtnWhatsApp
                popupMessage="Olá, como podemos te ajudar?"
                phone={ config.phone }
            />
            <Row id='section-header'>
                <Col>
                    <Header />
                </Col>
            </Row>
            <Row id='section-home'>
                <Col>
                    <Home />
                </Col>
            </Row>
            <Row id='section-about'>
                <Col>
                    <About />
                </Col>
            </Row>
            <Row id='section-services'>
                <Col>
                    <Services />
                </Col>
            </Row>
            <Row id='section-contact'>
                <Col>
                    <Contact />
                </Col>
            </Row>
            <Footer />
        </Container>
    );
}