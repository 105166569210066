import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import './style.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: true,
      text: 'Pico de Atendimentos na Semana',
    },
  },
};

const labels = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];

export default function GraphBar({ sunday, monday, tuesday, wednesday, thursday, friday, saturday }) {
  const data = {
    labels,
    datasets: [
      {
        label: 'Quantidade',
        data: [sunday, monday, tuesday, wednesday, thursday, friday, saturday],
        backgroundColor: '#c980dd',
      }
    ],
  };

  return <Bar options={options} data={data} />;
}
