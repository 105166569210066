const INITIAL_STATE = {
    activeStep: 0
}

const stepperReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'NEXT_STEP':
            return { ...state,
                activeStep: state.activeStep<2 ? state.activeStep+1 : state.activeStep
            };
        case 'PREVIOUS_STEP':
            return { ...state,
                activeStep: state.activeStep>0 ? state.activeStep-1 : state.activeStep
            };
        default:
            return state;
    }
}

export default stepperReducer;