import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './style.css';
import { http } from '../../services';
import utils from './../../utils';

export default function ResetPasswordDashboard() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [btnDisabled, setBtnDisabled] = useState(true);

    useEffect(()=>{
        if(password && (password === repeatPassword)) return setBtnDisabled(false);
        setBtnDisabled(true);
    }, [password, repeatPassword]);

    useEffect(()=>{
        setPassword('');
        setRepeatPassword('');
        setBtnDisabled(true);
    }, [show]);

    const save = ()=>{
        http.user.resetPasswordForDashboard({password})
            .then(()=>{
                utils.createNotification({
                    type: 'success',
                    title: 'Senha alterada!'
                })
            })
            .catch(error=>{
                utils.createNotification({
                    type: 'error',
                    title: 'Erro ao alterar senha da conta',
                    message: error.response.data
                })
            })
            .finally(()=>setShow(false));
    }

    return (
        <div id='reset-password-dashboard'>
            <Button onClick={handleShow}>Redefinir senha</Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Redefinição de senha</Modal.Title>
                </Modal.Header>
                <Modal.Body className='reset-password-dashboard-body'>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                            password={ password }
                            onChange={ e=>setPassword(e.target.value) }
                            type="password"
                            placeholder="Digite a sua nova senha"
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                            repeatPassword={ repeatPassword }
                            onChange={ e=>setRepeatPassword(e.target.value) }
                            type="password"
                            placeholder="Repita a senha digitada"
                        />
                    </Form.Group>
                </Form>
                </Modal.Body>
                <Modal.Footer className='reset-password-dashboard-footer'>
                <Button className='return' variant="secondary" onClick={handleClose}>
                    Voltar
                </Button>
                <Button className='save' variant="primary" onClick={save} disabled={ btnDisabled }>
                    Alterar
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}