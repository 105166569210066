import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

function MyVerticallyCenteredModal(props) {

  const { title, description } = useSelector(state=>state.modalInfoState);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          { title }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {
            description
          }
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Voltar</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function ModalInfo() {
    const [modalShow, setModalShow] = React.useState(true);
    const open = useSelector(state=>state.modalInfoState.open);
    const dispatch = useDispatch();

    return (
        <>
        <MyVerticallyCenteredModal
            show={ open }
            onHide={() => dispatch({type: 'CLOSE_MODAL_INFO'})}
        />
        </>
    );
}