import React, { useState, useEffect, useRef } from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { Form, Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import store from '../../store';
import { http } from '../../services';
import './style.css';

export function CardPaymentInputs({ submit }){

  const check = useRef(null);
  const dispatch = useDispatch();
  const { payment } = useSelector(state=>state.paymentRegisterPatientState);
  const { isLoading, info } = useSelector(state=>state.registerPatientState);
  const { number, name, expiry, cvc, document } = useSelector(state=>state.paymentRegisterPatientState.paymentInfo);
  const [fillAddressLoading, setFillAddressLoading] = useState(false);
  const [cep, setCep] = useState(info?.cep);
  const [numberAddress, setNumberAddress] = useState(info?.number);
  const [address, setAddress] = useState(info?.address);
  const [city, setCity] = useState(info?.city);
  const [complement, setComplement] = useState(info?.complement);
  const [uf, setUf] = useState(info?.uf);
  
  const fillAddress = () => {
    setFillAddressLoading(true);
    http.correios.getCepInfo(cep)
      .then(result => {
        if(!uf) setUf(result.data.uf);
        if(!address) setAddress(result.data.logradouro);
        if(!complement) setComplement(result.data.complement);
        if(!city) setCity(result.data.localidade);
      })
      .catch(()=>{
        let newObj = info.errors;
        newObj["account"]["cep"] = "Informe um CEP válido.";
        dispatch({ type: 'SET_ERRORS_REGISTER_PATIENT', payload: Object.assign({}, {
          cep: "Informe um CEP válido."
        }) });
      })
      .finally(()=>setFillAddressLoading(false));
  }

  useEffect(()=>{
    if(info.errors?.checkedTerm) check.current.classList.add('is-invalid');
    else check.current.classList.remove('is-invalid');
  }, [info.errors?.checkedTerm]);

  useEffect(()=>{
    dispatch({
      type: "INCLUDE_ACCOUNT_REGISTER_PATIENT",
      payload: {
        address,
        complement,
        number: numberAddress,
        uf,
        city,
        cep
      }
    });
  }, [complement, address, uf, city, numberAddress, cep]);

  return (
    <Form className='card-payment-inputs'>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Control className={ info.errors?.paymentInfo?.number ? 'is-invalid' : '' } value={ number || '' } name="number" onFocus={ e=>dispatch({
            type: 'INFO_PAYMENT_REGISTER_PATIENT',
            payload: {
              focus: e.target.name
            }
          }) } onChange={ e=>{
            if(e.target.value.match(/\D/) || e.target.value.length > 16) return false;
            dispatch({
              type: 'INFO_PAYMENT_REGISTER_PATIENT',
              payload: {
                number: e.target.value
              }
            });
          } } type="text" placeholder="Número do cartão" />
          <div class="invalid-feedback">
            {
              info.errors?.paymentInfo?.number
            }
          </div>
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Control className={ info.errors?.paymentInfo?.name ? 'is-invalid' : '' } value={ name || '' } name="name" onFocus={ e=>dispatch({
                  type: 'INFO_PAYMENT_REGISTER_PATIENT',
                  payload: {
                    focus: e.target.name
                  }
          }) } onChange={ e=>dispatch({
            type: 'INFO_PAYMENT_REGISTER_PATIENT',
            payload: {
              name: e.target.value
            }
          }) } type="text" placeholder="Nome do cartão" />
          <div class="invalid-feedback">
            {
              info.errors?.paymentInfo?.name
            }
          </div>
      </Form.Group>
      <Row>
          <Col md={8}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control className={ info.errors?.paymentInfo?.expiry ? 'is-invalid' : '' } value={ expiry || '' } name="expiry" onFocus={ e=>dispatch({
                  type: 'INFO_PAYMENT_REGISTER_PATIENT',
                  payload: {
                    focus: e.target.name
                  }
                }) } onChange={ e=>dispatch({
                  type: 'INFO_PAYMENT_REGISTER_PATIENT',
                  payload: {
                    expiry: (()=>{
                      if(e.target.value.replace("/", "").length > 4) return expiry;
                      if(e.target.value[e.target.value.length - 1] === '/') return e.target.value.substring(0, 2);
                      if(e.target.value.length && e.target.value[e.target.value.length - 1].replace("/", "").match(/\D/)) return expiry;
                      if(e.target.value.replace("/", "").length === 3 && expiry.length === 2) return e.target.value.substring(0, 2) + "/" + e.target.value[2];
                      return e.target.value;
                    })()
                  }
                }) } type="text" placeholder="Validade" />
                <div class="invalid-feedback">
                  {
                    info.errors?.paymentInfo?.expiry
                  }
                </div>
              </Form.Group>
          </Col>
          <Col md={4}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Control type="text" className={ info.errors?.paymentInfo?.cvc ? 'is-invalid' : '' } value={ cvc || '' } name="cvc" placeholder="CVC" onFocus={ e=>dispatch({
                    type: 'INFO_PAYMENT_REGISTER_PATIENT',
                    payload: {
                      focus: e.target.name
                    }
                  }) } onChange={ e=>dispatch({
                    type: 'INFO_PAYMENT_REGISTER_PATIENT',
                    payload: {
                      cvc: e.target.value
                    }
                  }) } />
                  <div class="invalid-feedback">
                    {
                      info.errors?.paymentInfo?.cvc
                    }
                  </div>
              </Form.Group>
          </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Control type="text" className={ info.errors?.paymentInfo?.document ? 'is-invalid' : '' }
            value={ document || '' }
            name="document"
            placeholder="Número de documento (Exemplo: CPF, RG)"
            onChange={ e=>dispatch({
              type: 'INFO_PAYMENT_REGISTER_PATIENT',
              payload: {
                document: e.target.value
              }
            }) } />
            <div class="invalid-feedback">
              {
                info.errors?.paymentInfo?.document
              }
            </div>
          </Form.Group>
        </Col>
      </Row>
      {
        payment === "DEBIT_CARD" && (
          <>
            <Row>
              <Col xs={ 12 } md={ 6 }>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Control className={ info.errors?.account?.cep ? 'is-invalid' : '' } type="text" value={ cep } onBlur={fillAddress} onChange={e=>setCep(e.target.value)} placeholder="CEP do seu endereço" />
                    <div class="invalid-feedback">
                      {
                        info.errors?.account?.cep
                      }
                    </div>
                </Form.Group>
              </Col>
              <Col xs={ 12 } md={ 6 }>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Control className={ info.errors?.account?.number ? 'is-invalid' : '' } type="text" value={ numberAddress } onChange={e=>setNumberAddress(e.target.value)} placeholder="Número do seu endereço" />
                    <div class="invalid-feedback">
                      {
                        info.errors?.account?.number
                      }
                    </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Control className={ info.errors?.account?.address ? 'is-invalid' : '' } type="text" value={ address } onChange={e=>setAddress(e.target.value)} placeholder="Rua" />
                    <div class="invalid-feedback">
                      {
                        info.errors?.account?.address
                      }
                    </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={ 12 } md={ 6 }>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Control className={ info.errors?.account?.city ? 'is-invalid' : '' } type="text" value={ city } onChange={e=>setCity(e.target.value)} placeholder="Cidade" />
                    <div class="invalid-feedback">
                      {
                        info.errors?.account?.city
                      }
                    </div>
                </Form.Group>
              </Col>
              <Col xs={ 12 } md={ 6 }>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Control className={ info.errors?.account?.complement ? 'is-invalid' : '' } type="text" value={ complement } onChange={e=>setComplement(e.target.value)} placeholder="Complemento" />
                    <div class="invalid-feedback">
                      {
                        info.errors?.account?.complement
                      }
                    </div>
                </Form.Group>
              </Col>
            </Row>
          </>
        )
      }
      <Row>
        <Col>
          <Form.Group className='accept-policy'>
            <Form.Check ref={check} id="policy-check" checked={ info.checkedTerm } className={ 'mr-5' + (info.errors?.checkedTerm ? ' is-invalid' : '') } onChange={e=>dispatch({type: 'INCLUDE_POLICY_REGISTER_PATIENT', payload: { checkedTerm: e.target.checked }})} />
            <label for="policy-check">Eu li e concordo com a <a id='policy-privacy' href='/politica-de-privacidade' target='_blank'>Política de Privacidade</a> e os <a id='policy-term' href='/termos-de-uso' target="_blank">Termos de Uso</a> da Psy Up.</label>
            <div class="invalid-feedback">
              {
                info.errors?.checkedTerm
              }
            </div>
          </Form.Group>
        </Col>
      </Row>
      <Row>
          <Col>
            {
              isLoading ? <Spinner animation="border" role="status" /> : <Button disabled={fillAddressLoading} onClick={()=>{
                submit()
                  .then(result=>{
                    if(result){
                      setAddress("");
                      setCep("");
                      setCity("");
                      setComplement("");
                      setNumberAddress("");
                    }
                  });
              }} className='finish-buy'>Finalizar compra</Button>
            }
          </Col>
      </Row>
    </Form>
  );
}

export default function CardPayment({submit}){

    const { paymentInfo } = useSelector(state=>state.paymentRegisterPatientState);
    const handleInputFocus = (e) => {
        this.setState({ focus: e.target.name });
      }
      
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
        this.setState({ [name]: value });
    }

    const { isLoading } = useSelector(state=>state.registerPatientState);

    return (
        <div id="PaymentForm">
            <Container fluid>
                <Row>
                    <Col md={12} lg={5}>
                        <Cards
                          cvc={paymentInfo.cvc || ''}
                          expiry={paymentInfo.expiry || ''}
                          focused={paymentInfo.focus || false}
                          name={paymentInfo.name || ''}
                          number={paymentInfo.number || ''}
                          acceptedCards={ ['visa', 'mastercard'] }
                          issuer={ true }
                          placeholders={{
                            name: 'NOME DO CARTÃO'
                          }}
                          locale={{
                            valid: 'válido até'
                          }}
                        />
                    </Col>
                    <Col md={12} lg={7}>
                      <CardPaymentInputs submit={ submit } />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}