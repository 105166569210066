import React from "react";
import './style.css';

export default function HomePageHeaderMenu(){
    return (
        <div id="home-page-header-menu" className="space-between-row">
            <a href="/#home">Home</a>
            <a href="/#about">Quem Somos</a>
            <a href="/#services">Planos</a>
            <a href="/#contact">Contato</a>
        </div>
    );
}