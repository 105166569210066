import React, { useState, useEffect } from 'react';
import Table from '../../Components/Table';
import Modal from '../../Components/Modal';
import { Form, Button, Spinner, Container, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { http } from '../../services';
import utils from './../../utils';
import store from '../../store';
import moment from 'moment';
import Filter from '../../Components/Filter';
import {
    useSearchParams
} from "react-router-dom";
import Pagination from '../../Components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

const config = {
    columns: [
        { name: 'id', translate: 'Id' },
        { name: 'createdAt', translate: 'Agendado', type: 'date' },
        { name: 'realized', translate: 'Realizado', type: 'date' },
        { name: 'time', translate: 'Hora' },
        { name: 'patientName', translate: 'Paciente' },
        { name: 'psychologistName', translate: 'Psicólogo' },
        { name: 'modality', translate: 'Modalidade' },
        { name: 'planName', translate: 'Plano' },
        { name: 'price', translate: 'Valor', type: 'currency' },
        { name: 'rate', translate: 'Taxa(%)' }
    ],
    include: {
        cta: "Agendar",
        title: 'Agendar atendimento',
        ctaFn: ()=>{
            store.dispatch({
                type: 'CREATING_ATTENDANCE',
                payload: true
            });
            http.attendance.create(store.getState().formState.form)
                .then(result=>{
                    store.dispatch({
                        type: 'INCLUDE_ATTENDANCE',
                        payload: result.data
                    });
                    utils.createNotification({
                        type: 'success',
                        title: 'Atendimento agendado'
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: 'Erro ao agendar atendimento',
                    message: error.response.data
                }))
                .finally(()=>{
                    store.dispatch({
                        type: 'CLEAR_FORM'
                    });
                    store.dispatch({
                        type: 'CREATING_ATTENDANCE',
                        payload: false
                    });
                });
        }
    },
    update: {
        cta: "Editar",
        title: 'Editar atendimento',
        ctaFn: ({id})=>{
            http.attendance.update(id, store.getState().formState.form)
                .then(result=>{
                    store.dispatch({
                        type: 'UPDATE_ATTENDANCE',
                        payload: result.data
                    });
                    utils.createNotification({
                        type: 'success',
                        title: `As informações do atendimento ${id} foram alteradas!`
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: `Erro ao editar atendimento ${id}`,
                    message: error.response.data
                }))
                .finally(()=>store.dispatch({
                    type: 'CLEAR_FORM'
                }));
        }
    },
    remove: {
        cta: "Deletar",
        title: 'Deletar atendimento',
        ctaFn: ({ id })=>{
            http.attendance.destroy(id)
                .then(()=>{
                    store.dispatch({
                        type: 'DELETE_ATTENDANCE',
                        payload: {
                            id
                        }
                    });
                    utils.createNotification({
                        type: 'success',
                        title: 'Atendimento deletado',
                        message: `O atendimento ${id} foi deletado!`
                    });
                })
                .catch(error=>utils.createNotification({
                    type: 'error',
                    title: `Erro ao deletar atendimento ${id}`,
                    message: error.response.data
                }));
        },
        style: {
            backgroundColor: 'var(--color-danger)'
        }
    }
}

function FilterContent(){
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const [id, setId] = useState(searchParams.get('id') || '');
    const [createdAt, setCreatedAt] = useState(searchParams.get('createdAt') || '');
    const [realided, setRealided] = useState(searchParams.get('realided') || '');
    const [hour, setHour] = useState(searchParams.get('hour') || '');
    const [modality, setModality] = useState(searchParams.get('modality') || '');
    const [patientName, setPatientName] = useState(searchParams.get('patientName') || '');
    const [psychologistName, setPsychologistName] = useState(searchParams.get('psychologistName') || '');

    const toFilter = (obj={})=>{
        dispatch({ type: 'FILTER_ATTENDANCE', payload: obj });
        let params = Object.entries(obj).reduce((acc, current)=>{
            if(current[1]) return acc + (acc ? `&${current[0]}=${current[1]}` : `${current[0]}=${current[1]}`);
            return acc;
        }, '');
        console.log(params);
        setSearchParams(params);
        dispatch({
            type: 'IS_LOADING_ATTENDANCE',
            payload: true
        });
        http.attendance.findAll(obj)
            .then(result=>{
                dispatch({
                    type: 'LOAD_ATTENDANCE',
                    payload: result.data
                });
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Erro ao carregar atendimentos',
                message: error.response.data
            }))
            .finally(()=>dispatch({
                type: 'IS_LOADING_ATTENDANCE',
                payload: false
            }));
    }

    return (
        <Row xs={1} md={4} lg={6}>
            <Col>
                <Form.Group className="mb-3" controlId="formBasicId">
                    <Form.Label>Id</Form.Label>
                    <Form.Control value={ id } onChange={ e=>setId(e.target.value) } type="text" placeholder="Digite o id do agendamento" />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="formBasicCreatedAt">
                    <Form.Label>Agendado</Form.Label>
                    <Form.Control value={ createdAt } onChange={ e=>setCreatedAt(e.target.value) } type="date" />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="formBasicRealized">
                    <Form.Label>Realizado</Form.Label>
                    <Form.Control value={realided} onChange={e=>setRealided(e.target.value)} type="date" />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="formBasicHour">
                    <Form.Label>Hora</Form.Label>
                    <Form.Control value={hour} onChange={e=>setHour(e.target.value)} type="time" />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="formBasicPatientName">
                    <Form.Label>Paciente</Form.Label>
                    <Form.Control value={patientName} onChange={e=>setPatientName(e.target.value)} type="text" placeholder="Digite o nome do paciente" />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="formBasicPsychologistName">
                    <Form.Label>Psicólogo</Form.Label>
                    <Form.Control value={psychologistName} onChange={e=>setPsychologistName(e.target.value)} type="text" placeholder="Digite o nome do psicólogo" />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="formBasicModality">
                    <Form.Label>Modalidade</Form.Label>
                    <Form.Select value={modality} onChange={e=>setModality(e.target.value)} aria-label="Default select example">
                        <option value=''>Selecione a modalidade</option>
                        <option value='Presencial'>Presencial</option>
                        <option value='Online'>Online</option>
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col>
                {<Form.Group id="filter-btn" className="mb-3" controlId="formBasicEmail">
                    <Form.Label></Form.Label>
                        <Button onClick={()=>toFilter({
                            activePage: 1,
                            id,
                            createdAt,
                            realided,
                            hour,
                            modality,
                            patientName,
                            psychologistName
                        })} variant="success">
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </Button>
                </Form.Group>}
            </Col>
        </Row>
    );
}

function IncludeContent(){

    const dispatch = useDispatch();
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [time, setTime] = useState(moment().format('HH:mm'));

    useEffect(()=>{
        dispatch({
            type: 'SAVE_FORM',
            payload: {
                realized: date,
                time
            }
        });
    }, date, time);

    const changeTime = newTime=>{
        setTime(newTime);
        dispatch({
            type: 'SAVE_FORM',
            payload: {
                realized: date,
                time: newTime
            }
        });
    }

    return (
        <Form>
            <Form.Group className="mb-3" controlId="formBasicDate">
                <Container fluid>
                    <Row>
                        <Col className='p-0 mr-2' sm={4}>
                            <Form.Control
                                style={{width: 'auto'}}
                                type="date"
                                value={ date }
                                min={moment().format('YYYY-MM-DD')}
                                onChange={e=>setDate(e.target.value)}
                            />
                        </Col>
                        <Col className='p-0' sm={4}>
                            <Form.Control
                                style={{width: 'auto'}}
                                type="time"
                                value={ time }
                                onChange={e=>changeTime(e.target.value)}
                            />
                        </Col>
                    </Row>
                </Container>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicDate" style={{width: 'auto'}}>
                <Form.Control
                    type="text"
                    placeholder="Psicólogo"
                    onChange={e=>dispatch({
                        type: 'SAVE_FORM',
                        payload: {
                            psychologistId: e.target.value
                        }
                    })}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicDate" style={{width: 'auto'}}>
                <Form.Control
                    type="text"
                    placeholder="Paciente"
                    onChange={e=>dispatch({
                        type: 'SAVE_FORM',
                        payload: {
                            patientId: e.target.value
                        }
                    })}
                />
            </Form.Group>
        </Form>
    );
}

function UpdateContent(props){

    const [price, setPrice] = useState(props.price);

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch({
            type: 'SAVE_FORM',
            payload: {
                price
            }
        });
    }, [price]);

    return (
        <Form>
            <Form.Group className="mb-3" controlId="formPrice">
                <Form.Label>Preço</Form.Label>
                <Form.Control
                    value={ price }
                    onChange={e=>setPrice(e.target.value)}
                    type="text"
                    placeholder="Digite o valor do atendimento"
                />
            </Form.Group>
        </Form>
    );
}

function RemoveContent({ id }){
    return (
        <p>Deseja deletar o atendimento {id}?</p>
    );
}

function TableActions(props){

    return (
        <>
            <Modal
                config={ config.update }
                row={ props.row }
                content={ <UpdateContent
                    { ...props.row }
                /> }
            />
            <Modal
                config={ Object.assign({}, config.remove, { data: { id: props.row.id } }) }
                row={ props.row }
                content={ <RemoveContent
                    { ...props.row }
                /> }
            />
        </>
    );
}

export default function Attendances(){

    const { isLoading, isCreating, attendances, filters } = useSelector(state=>state.attendanceState);
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const toFilter = (obj={})=>{
        dispatch({ type: 'FILTER_ATTENDANCE', payload: obj });
        setSearchParams(Object.entries(obj).reduce((acc, current)=>{
            if(current[1] && current[0] != 'totalPages') return acc ? acc + `&${current[0]}=${current[1]}` : `${current[0]}=${current[1]}`;
            return acc;
        }, ''));
        dispatch({
            type: 'IS_LOADING_ATTENDANCE',
            payload: true
        });
        http.attendance.findAll(obj)
            .then(result=>{
                dispatch({
                    type: 'LOAD_ATTENDANCE',
                    payload: result.data
                });
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Erro ao carregar atendimentos',
                message: error.response.data
            }))
            .finally(()=>dispatch({
                type: 'IS_LOADING_ATTENDANCE',
                payload: false
            }));
    }

    useEffect(()=>{

        toFilter({
            activePage: Number(searchParams.get('activePage')) || 1,
            id: searchParams.get('id'),
            modality: searchParams.get('modality'),
            hour: searchParams.get('hour'),
            patientName: searchParams.get('patientName'),
            psychologistName: searchParams.get('psychologistName'),
            realided: searchParams.get('realided'),
            createdAt: searchParams.get('createdAt')
        });

    }, []);

    return (
        <div id='table'>
            <Filter 
                content={ <FilterContent /> }
            />
            {
                isCreating ? <div><Spinner animation="border" /></div> : (
                    <Modal
                        config={ config.include }
                        content={ <IncludeContent
                        /> }
                    />
                )
            }
            {
                isLoading ? <div><Spinner animation="border" /></div> :
                <Table
                    data={ attendances }
                    config={ config }
                    Actions={ TableActions }
                />
            }
            {
                filters.totalPages > 1 ? <Row>
                    <Col>
                        <Pagination
                            filters={ filters }
                            toFilter={ toFilter }
                        />
                    </Col>
                </Row> : false
            }
        </div>
    );
}