const INITIAL_STATE = {
    payment: 'CREDIT_CARD',
    paymentInfo: {}
}

const paymentRegisterPatientReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'TYPE_PAYMENT_REGISTER_PATIENT':
            return {
                ...state,
                payment: payload.payment
            };
        case 'INFO_PAYMENT_REGISTER_PATIENT':
            return {
                ...state,
                paymentInfo: Object.assign({}, state.paymentInfo, payload)
            };
        case 'CLEAR_PAYMENT_REGISTER_PATIENT':
            return {
                ...state, 
                payment: 'CREDIT_CARD',
                paymentInfo: {}
            };
        default:
            return state;
    }
}

export default paymentRegisterPatientReducer;