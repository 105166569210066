import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './style.css';
import http from '../../services/http';
import utils from '../../utils';

export default function ResetPassword() {

  const [show, setShow] = useState(false);
  const [email, setEmail] = useState('');

  const handleClose = () => {
    setShow(false);
    setEmail('');
  }
  const handleShow = () => setShow(true);

  const reset = ()=>{
    handleClose();
    http.user.resetPassword({ email })
    .then(()=>{
        utils.createNotification({
            type: 'success',
            title: `Link de redefinição enviado para o seu e-mail`
        });
    })
    .catch(error=>utils.createNotification({
        type: 'error',
        title: `Erro ao enviar e-mail de redefinição de senha`,
        message: error.response.data
    }));
  }

  return (
    <div id='reset-password'>
        <Form.Text onClick={handleShow} className="text-muted">
            Esqueceu a senha?
        </Form.Text>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Redefinição de senha</Modal.Title>
            </Modal.Header>
            <Modal.Body className='reset-password-body'>
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <p className='reset-password-description'>Preencha os dados abaixo. Enviaremos um link de redefinição de senha para o seu e-mail.</p>
                    <Form.Control value={ email } onChange={ e=>setEmail(e.target.value) } type="email" placeholder="E-mail cadastrado" />
                </Form.Group>
            </Form>
            </Modal.Body>
            <Modal.Footer className='reset-password-footer'>
            <Button variant="secondary" onClick={handleClose}>
                Voltar
            </Button>
            <Button variant="primary" onClick={reset}>
                Enviar link
            </Button>
            </Modal.Footer>
        </Modal>
    </div>
  );
}