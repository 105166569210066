import './App.css';
import Pages from './Pages';
import store from './store';
import { Provider } from 'react-redux';
import '@duik/core/dist/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import SSRProvider from 'react-bootstrap/SSRProvider';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import ModalInfo from './Components/ModalInfo';

function App(){
  return (
    <Provider store={ store }>
      <SSRProvider>
        <div className="App">
          <NotificationContainer />
          <ModalInfo />
          <Pages />
        </div>
      </SSRProvider>
    </Provider>
  );
}

export default App;