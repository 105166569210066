import store from './../../store';

export default {
    notification: {
        findAll(){
            return new Promise((resolve, reject)=>{
                store.getState().accountState.socket.emit('notification-findall', data=>{
                    resolve(data);
                });
            });
        },
        view(id){
            return new Promise((resolve, reject)=>{
                store.getState().accountState.socket.emit('notification-view', id, response=>{
                    if(response.status === 500) return reject(response);
                    resolve(resolve);
                });
            });
        }
    }
}