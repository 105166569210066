import React from 'react';
import './style.css';
import moment from 'moment';
import { Link } from 'react-router-dom';

export default function Footer(){
    return (
        <div className='footer'>
            <div className='copyright'>
                <p>Psy Up © {moment().format('YYYY')}. Todos os direitos reservados.</p>
            </div>
            <nav>
                <Link to="/politica-de-privacidade">Política de privacidade</Link>
                <Link to="/termos-de-uso">Termos de uso</Link>
            </nav>
        </div>
    );
}