const INITIAL_STATE = {
    isLoading: false,
    payments: [],
}

const paymentReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch(type){
        case 'LOAD_PAYMENT':
            return {
                ...state, 
                payments: payload,
                isLoading: false
            };
        case 'IS_LOADING_PAYMENT':
            return {
                ...state,
                isLoading: payload
            };
        default:
            return state;
    }
}

export default paymentReducer;