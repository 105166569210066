import React, { useEffect, useState, useRef } from 'react';
import Header from './../../Components/HomePageHeader';
import { Container, Row, Col, Card, Button, Form, Spinner } from 'react-bootstrap';
import './style.css';
import PlanIndividual from './../../Images/plan-individual.svg';
import PlanIndividualSelected from './../../Images/plan-individual-selected.svg';
import PlanCouple from './../../Images/plan-couple.svg';
import PlanCoupleSelected from './../../Images/plan-couple-selected.svg';
import PlanChild from './../../Images/plan-child.svg';
import PlanChildSelected from './../../Images/plan-child-selected.svg';
import { useSelector, useDispatch } from 'react-redux';
import { http } from '../../services';
import utils from '../../utils';
import CardPayment, { CardPaymentInputs } from '../../Components/CardPayment';
import PixPayment from '../../Components/PixPayment';
import Plan from '../../Components/Plan';
import SectionWrap from '../../Components/SectionWrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import Footer from './../../Components/Footer';
import { Link, Navigate, useLocation } from 'react-router-dom';
import store from '../../store';
import Home from '../../Components/Home';

function SelectPayment(){
    const dispatch = useDispatch();
    const { planId, modality, account, dependent, checkedTerm, unitId, reasonId, hours } = useSelector(state=>state.registerPatientState.info);
    const { payment, paymentInfo } = useSelector(state=>state.paymentRegisterPatientState);
    const [isRedirect, setIsRedirect] = useState(false);
    
    const finishBuy = address => {
        dispatch({type: 'IS_LOADING_REGISTER_PATIENT', payload: true});

        const isUserLoggedAndPatient = (store.getState().accountState.isLogged && store.getState().accountState.user.type === 'paciente');

        const api = isUserLoggedAndPatient ?
           http.user.onlyPayments : http.user.registerAndPayments;

        return api({
            payment,
            paymentInfo,
            planId,
            modality,
            account,
            dependent,
            checkedTerm,
            unitId,
            reasonId,
            hours
        })
            .then(result=>{
                const qrCode = result?.data?.links?.length && result?.data?.links[0]?.href;
                if(qrCode) sessionStorage.setItem('qr-code', qrCode);
                if(payment !== "PIX"){
                    utils.createNotification({
                        type: 'success',
                        title: isUserLoggedAndPatient ? 'Pagamento realizado!' : 'Cadastro e pagamentos realizados!'
                    });
                }
                dispatch({type: 'CLEAR_PAYMENT_REGISTER_PATIENT'});
                dispatch({type: 'CLEAR_REGISTER_PATIENT'});
                if(qrCode) setIsRedirect(true);
            })
            .catch(error=>{
                if(error.response.data.AUTH_NOT_SUPPORTED){
                    utils.createNotification({
                        type: 'error',
                        title: 'Erro ao realizar pagamento',
                        message: 'Utilize um cartão de débito habilitado para autenticação 3DS ou escolha uma outra forma de pagamento.'
                    });
                    return;
                }
                dispatch({ type: 'SET_ERRORS_REGISTER_PATIENT', payload: error.response.data });
                utils.createNotification({
                    type: 'error',
                    title: 'Erro ao processar requisição',
                    message: error.response.data.message || (
                        typeof error.response.data === 'object' && 'Há campos do formulário que precisam ser preenchidos!'
                    ) || error?.response?.data
                });
            })
            .finally(()=>dispatch({type: 'IS_LOADING_REGISTER_PATIENT', payload: false}));
    }

    return (
        <Container fluid>
            {
                isRedirect && <Navigate replace to={ "/pagamento-pix" } />
            }
            <Row>
                <Col md={6} lg={ 3 }>
                    <div className='select-payment'>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    <Form.Check checked={ 'CREDIT_CARD' === payment } onChange={()=>dispatch({type: 'TYPE_PAYMENT_REGISTER_PATIENT', payload: { payment: 'CREDIT_CARD' }})} label="Cartão de Crédito" type="radio" aria-label="radio 1" />
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    <Form.Check checked={ 'DEBIT_CARD' === payment } onChange={()=>dispatch({type: 'TYPE_PAYMENT_REGISTER_PATIENT', payload: { payment: 'DEBIT_CARD' }})} label="Cartão de Débito" type="radio" aria-label="radio 2" />
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    <Form.Check checked={ 'PIX' === payment } onChange={()=>dispatch({type: 'TYPE_PAYMENT_REGISTER_PATIENT', payload: { payment: 'PIX' }})} label="Pix" type="radio" aria-label="radio 3" />
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
                {
                    payment === 'CREDIT_CARD' ? (
                        <Col md={6} lg={ 9 }>
                            <CardPayment submit={ finishBuy } />
                        </Col>
                    ) : (
                        payment === 'DEBIT_CARD' ? (
                            <Col md={6} lg={ 9 }>
                                <CardPayment submit={ finishBuy } />
                            </Col>
                        ) : (
                            <Col md={6} lg={ 9 }>
                                <PixPayment submit={ finishBuy } />
                            </Col>
                        )
                    )
                }
            </Row>
            <Row className={`row-card-payment-inputs${ payment === 'PIX' ? ' hide' : '' }`}>
                <Col>
                    <CardPaymentInputs submit={ finishBuy } />
                </Col>
            </Row>
        </Container>
        
    );
}

function CreateAccount(){

    const [states, setStates] = useState([]);
    const dispatch = useDispatch();

    const formLoginEl = useRef(null);
    const formContactEl = useRef(null);

    const { account, errors } = useSelector(state=>state.registerPatientState.info);

    const getCep = ()=>{
        http.correios.getCepInfo(account?.cep)
            .then(result=>{
                if(result.data.erro) return utils.createNotification({
                    type: 'error',
                    title: `Erro ao carregar endereço`
                });
                
                let stateName = (states.find(state=>state.sigla === result.data.uf)).nome;

                dispatch({
                    type: 'INCLUDE_ACCOUNT_REGISTER_PATIENT',
                    payload: {
                        state: stateName,
                        address: result.data.logradouro,
                        complement: result.data.complemento,
                        district: result.data.bairro,
                        city: result.data.localidade
                    }
                });
                dispatch({
                    type: 'SET_ERRORS_REGISTER_PATIENT',
                    payload: {
                        account: {
                            ...errors.account,
                            district: '',
                            city: '',
                            complement: '',
                            address: '',
                            state: ''
                        }
                    }
                });
            })
            .catch(()=>utils.createNotification({
                type: 'error',
                title: `Erro ao carregar endereço`
            }));
    }

    useEffect(()=>{
        if(!Object.values(account).find(value=>Boolean(value))){
            formLoginEl.current.reset();
            formContactEl.current.reset();
        }
    }, [account]);

    useEffect(()=>{
        http.ibge.allStates()
            .then(result=>setStates(result.data))
            .catch(()=>utils.createNotification({
                type: 'error',
                title: `Erro ao carregar estados`
            }));
    }, []);

    const root = window.location.pathname.substring(
        window.location.pathname.indexOf('/'),
        window.location.pathname.length
    );

    return (
        <div id="create-account">
            <SectionWrap title="Dados de Login">
                <Form ref={ formLoginEl }>
                    <Container fluid>
                        <Row>
                            <Col xs={ 12 } md={ 4 }>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>E-mail</Form.Label>
                                    <Form.Control value={ account?.email } className={ errors?.account?.email ? 'is-invalid' : '' } type="email" onChange={e=>dispatch({
                                    type: 'INCLUDE_ACCOUNT_REGISTER_PATIENT',
                                    payload: {
                                        email: e.target.value
                                    }
                                })} placeholder="Digite o seu melhor e-mail" />
                                        <div class="invalid-feedback">
                                        {
                                            errors?.account?.email
                                        }
                                        </div>
                                </Form.Group>
                            </Col>
                            <Col xs={ 12 } md={ 4 }>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Senha</Form.Label>
                                    <Form.Control value={ account?.password } className={ errors?.account?.password ? 'is-invalid' : '' } type="password" onChange={e=>dispatch({
                                    type: 'INCLUDE_ACCOUNT_REGISTER_PATIENT',
                                    payload: {
                                        password: e.target.value
                                    }
                                })} placeholder="Digite uma senha" />
                                    <div class="invalid-feedback">
                                        {
                                            errors?.account?.password
                                        }
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xs={ 12 } md={ 4 }>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Confirmação de senha</Form.Label>
                                    <Form.Control value={ account?.repeatPassword } className={ errors?.account?.repeatPassword ? 'is-invalid' : '' } type="password" onChange={e=>dispatch({
                                    type: 'INCLUDE_ACCOUNT_REGISTER_PATIENT',
                                    payload: {
                                        repeatPassword: e.target.value
                                    }
                                })} placeholder="Repita a senha digitada" />
                                    <div class="invalid-feedback">
                                        {
                                            errors?.account?.repeatPassword
                                        }
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </SectionWrap>
            <SectionWrap title="Dados de Contato">
                <Form ref={ formContactEl }>
                    <Container fluid>
                        <Row>
                            <Col xs={ 12 } md={ 8 }>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control className={ errors?.account?.name ? 'is-invalid' : '' } type="text" value={ account?.name } onChange={e=>dispatch({
                                    type: 'INCLUDE_ACCOUNT_REGISTER_PATIENT',
                                    payload: {
                                        name: e.target.value
                                    }
                                })} placeholder="Digite o seu nome" />
                                    <div class="invalid-feedback">
                                        {
                                            errors?.account?.name
                                        }
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xs={ 12 } md={ 4 }>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Data de nascimento</Form.Label>
                                    <Form.Control className={ errors?.account?.birth ? 'is-invalid' : '' } type="date" value={ account?.birth } onChange={e=>dispatch({
                                    type: 'INCLUDE_ACCOUNT_REGISTER_PATIENT',
                                    payload: {
                                        birth: e.target.value
                                    }
                                })} />
                                    <div class="invalid-feedback">
                                        {
                                            errors?.account?.birth
                                        }
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            {
                                root === '/carrinho/psicologo' && (
                                    <Col xs={ 12 } md={ root === '/carrinho/psicologo' ? 3 : 4 }>
                                        <Form.Group className="mb-3" controlId="formBasicCRP">
                                            <Form.Label>CRP</Form.Label>
                                            <Form.Control className={ errors?.account?.crp ? 'is-invalid' : '' } type="text" value={ account?.crp } onChange={e=>dispatch({
                                            type: 'INCLUDE_ACCOUNT_REGISTER_PATIENT',
                                            payload: {
                                                crp: e.target.value
                                            }
                                        })} placeholder="Digite o seu CRP" />
                                            <div class="invalid-feedback">
                                                {
                                                    errors?.account?.crp
                                                }
                                            </div>
                                        </Form.Group>
                                    </Col>
                                )
                            }
                            <Col xs={ 12 } md={ root === '/carrinho/psicologo' ? 3 : 4 }>
                                <Form.Group className="mb-3" controlId="formBasicCPF">
                                    <Form.Label>CPF</Form.Label>
                                    <Form.Control className={ errors?.account?.cpf ? 'is-invalid' : '' } type="text" value={ account?.cpf } onChange={e=>dispatch({
                                    type: 'INCLUDE_ACCOUNT_REGISTER_PATIENT',
                                    payload: {
                                        cpf: e.target.value
                                    }
                                })} placeholder="Digite o seu CPF" />
                                    <div class="invalid-feedback">
                                        {
                                            errors?.account?.cpf
                                        }
                                    </div>
                                </Form.Group>
                            </Col>
                            {
                                (root === '/carrinho/psicologo') && (
                                    <Col xs={ 12 } md={ root === '/carrinho/psicologo' ? 3 : 4 }>
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>RG</Form.Label>
                                            <Form.Control className={ errors?.account?.rg ? 'is-invalid' : '' } type="text" value={ account?.rg } onChange={e=>dispatch({
                                            type: 'INCLUDE_ACCOUNT_REGISTER_PATIENT',
                                            payload: {
                                                rg: e.target.value
                                            }
                                        })} placeholder="Digite o seu RG" />
                                            <div class="invalid-feedback">
                                                {
                                                    errors?.account?.rg
                                                }
                                            </div>
                                        </Form.Group>
                                    </Col>
                                )
                            }
                            <Col xs={ 12 } md={ root === '/carrinho/psicologo' ? 3 : 4 }>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="gender">Gênero</Form.Label>
                                    <Form.Select className={ errors?.account?.gender ? 'is-invalid' : '' } value={ account?.gender } onChange={e=>dispatch({
                                    type: 'INCLUDE_ACCOUNT_REGISTER_PATIENT',
                                    payload: {
                                        gender: e.target.value
                                    }
                                })} id="gender">
                                        <option value="">Selecione</option>
                                        <option value="M">Masculino</option>
                                        <option value="F">Feminino</option>
                                        <option value="O">Outro</option>
                                    </Form.Select>
                                    <div class="invalid-feedback">
                                        {
                                            errors?.account?.gender
                                        }
                                    </div>
                                </Form.Group>
                            </Col>
                            {
                                root !== '/carrinho/psicologo' && <>
                                    <Col xs={ 12 } md={ 4 }>
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>DDD + Telefone</Form.Label>
                                            <Form.Control className={ errors?.account?.telephone ? 'is-invalid' : '' } value={ account?.telephone } onChange={e=>dispatch({
                                            type: 'INCLUDE_ACCOUNT_REGISTER_PATIENT',
                                            payload: {
                                                telephone: e.target.value
                                            }
                                        })} type="text" placeholder="DDD + número" />
                                            <div class="invalid-feedback">
                                                {
                                                    errors?.account?.telephone
                                                }
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={ 12 } md={ 4 }>
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>DDD + Celular</Form.Label>
                                            <Form.Control className={ errors?.account?.cell ? 'is-invalid' : '' } value={ account?.cell } onChange={e=>dispatch({
                                            type: 'INCLUDE_ACCOUNT_REGISTER_PATIENT',
                                            payload: {
                                                cell: e.target.value
                                            }
                                        })} type="text" placeholder="DDD + número" />
                                            <div class="invalid-feedback">
                                                {
                                                    errors?.account?.cell
                                                }
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </>
                            }
                        </Row>
                        {
                            root === '/carrinho/psicologo' && (
                                <>
                                    <Row>
                                        <Col xs={ 12 } md={ 4 }>
                                            <Form.Group id='cep' className="mb-3" controlId="formCep">
                                                <Form.Label>CEP</Form.Label>
                                                <Form.Control className={ errors?.account?.cep ? 'is-invalid' : '' } value={ account?.cep } onChange={e=>dispatch({
                                                type: 'INCLUDE_ACCOUNT_REGISTER_PATIENT',
                                                payload: {
                                                    cep: e.target.value
                                                }
                                            })} id='formCep' type="text" placeholder="Digite o seu CEP" />
                                                <Button onClick={getCep}>
                                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                                </Button>
                                                <div class="invalid-feedback">
                                                    {
                                                        errors?.account?.cep
                                                    }
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={ 12 } md={ 4 }>
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label>Endereço</Form.Label>
                                                <Form.Control className={ errors?.account?.address ? 'is-invalid' : '' } value={ account?.address } onChange={e=>dispatch({
                                                type: 'INCLUDE_ACCOUNT_REGISTER_PATIENT',
                                                payload: {
                                                    address: e.target.value
                                                }
                                            })} type="text" placeholder="Digite o seu endereço" />
                                                <div class="invalid-feedback">
                                                    {
                                                        errors?.account?.address
                                                    }
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={ 12 } md={ 4 }>
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label>Nº</Form.Label>
                                                <Form.Control className={ errors?.account?.number ? 'is-invalid' : '' } type="text" value={ account?.number } onChange={e=>dispatch({
                                                type: 'INCLUDE_ACCOUNT_REGISTER_PATIENT',
                                                payload: {
                                                    number: e.target.value
                                                }
                                            })} placeholder="Digite o número do seu endereço" />
                                                <div class="invalid-feedback">
                                                    {
                                                        errors?.account?.number
                                                    }
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={ 12 } md={ 4 }>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Bairro</Form.Label>
                                                <Form.Control className={ errors?.account?.district ? 'is-invalid' : '' } value={ account?.district } onChange={e=>dispatch({
                                                type: 'INCLUDE_ACCOUNT_REGISTER_PATIENT',
                                                payload: {
                                                    district: e.target.value
                                                }
                                            })} type="text" placeholder="Digite o seu bairro" />
                                                <div class="invalid-feedback">
                                                    {
                                                        errors?.account?.district
                                                    }
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={ 12 } md={ 4 }>
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label>Complemento</Form.Label>
                                                <Form.Control type="text" value={ account?.complement } onChange={e=>dispatch({
                                                type: 'INCLUDE_ACCOUNT_REGISTER_PATIENT',
                                                payload: {
                                                    complement: e.target.value
                                                }
                                            })} placeholder="Digite o complemento" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={ 12 } md={ 4 }>
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label>Cidade</Form.Label>
                                                <Form.Control className={ errors?.account?.city ? 'is-invalid' : '' } value={ account?.city } onChange={e=>dispatch({
                                                type: 'INCLUDE_ACCOUNT_REGISTER_PATIENT',
                                                payload: {
                                                    city: e.target.value
                                                }
                                            })} type="text" placeholder="Digite a sua cidade" />
                                                <div class="invalid-feedback">
                                                    {
                                                        errors?.account?.city
                                                    }
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={ 12 } md={ 4 }>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Estado</Form.Label>
                                                <Form.Select className={ errors?.account?.state ? 'is-invalid' : '' } value={ account?.state } onChange={e=>dispatch({
                                                type: 'INCLUDE_ACCOUNT_REGISTER_PATIENT',
                                                payload: {
                                                    state: e.target.value
                                                }
                                            })} aria-label="Default select example">
                                                    <option>Selecione</option>
                                                    {
                                                        states.map(state=><option>{state.nome}</option>)
                                                    }
                                                </Form.Select>
                                                <div class="invalid-feedback">
                                                    {
                                                        errors?.account?.state
                                                    }
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={ 12 } md={ 4 }>
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label>DDD + Telefone</Form.Label>
                                                <Form.Control className={ errors?.account?.telephone ? 'is-invalid' : '' } value={ account?.telephone } onChange={e=>dispatch({
                                                type: 'INCLUDE_ACCOUNT_REGISTER_PATIENT',
                                                payload: {
                                                    telephone: e.target.value
                                                }
                                            })} type="text" placeholder="DDD + número" />
                                                <div class="invalid-feedback">
                                                    {
                                                        errors?.account?.telephone
                                                    }
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={ 12 } md={ 4 }>
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label>DDD + Celular</Form.Label>
                                                <Form.Control className={ errors?.account?.cell ? 'is-invalid' : '' } value={ account?.cell } onChange={e=>dispatch({
                                                type: 'INCLUDE_ACCOUNT_REGISTER_PATIENT',
                                                payload: {
                                                    cell: e.target.value
                                                }
                                            })} type="text" placeholder="DDD + número" />
                                                <div class="invalid-feedback">
                                                    {
                                                        errors?.account?.cell
                                                    }
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </>
                            )
                        }
                    </Container>
                </Form>
            </SectionWrap>
        </div>
    );
}

function CreateDependent(){

    const { errors, dependent } = useSelector(state=>state.registerPatientState.info);
    const dispatch = useDispatch();

    const formEl = useRef(null);

    useEffect(()=>{
        if(!Object.values(dependent).find(value=>Boolean(value))) formEl.current.reset();
    }, [dependent]);

    return (
        <SectionWrap id="create-account" title="Dados de Contato">
            <Form ref={ formEl }>
                <Container fluid>
                    <Row>
                        <Col xs={ 12 } md={ 8 }>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Nome</Form.Label>
                                <Form.Control value={ dependent?.name } className={ errors?.dependent?.name ? 'is-invalid' : '' } onChange={e=>dispatch({
                                    type: 'INCLUDE_DEPENDENT_REGISTER_PATIENT',
                                    payload: {
                                        name: e.target.value
                                    }
                                })} type="text" placeholder="Digite o seu nome" />
                                <div class="invalid-feedback">
                                    {
                                        errors?.dependent?.name
                                    }
                                </div>
                            </Form.Group>
                        </Col>
                        <Col xs={ 12 } md={ 4 }>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Data de nascimento</Form.Label>
                                <Form.Control value={ dependent?.birth } className={ errors?.dependent?.birth ? 'is-invalid' : '' } onChange={e=>dispatch({
                                    type: 'INCLUDE_DEPENDENT_REGISTER_PATIENT',
                                    payload: {
                                        birth: e.target.value
                                    }
                                })} type="date" />
                                <div class="invalid-feedback">
                                    {
                                        errors?.dependent?.birth
                                    }
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={ 12 } md={ 4 }>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>CPF</Form.Label>
                                <Form.Control value={ dependent?.cpf } className={ errors?.dependent?.cpf ? 'is-invalid' : '' } onChange={e=>dispatch({
                                    type: 'INCLUDE_DEPENDENT_REGISTER_PATIENT',
                                    payload: {
                                        cpf: e.target.value
                                    }
                                })} type="text" placeholder="Digite o seu CPF" />
                                <div class="invalid-feedback">
                                    {
                                        errors?.dependent?.cpf
                                    }
                                </div>
                            </Form.Group>
                        </Col>
                        <Col xs={ 12 } md={ 4 }>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>RG</Form.Label>
                                <Form.Control value={ dependent?.rg } className={ errors?.dependent?.rg ? 'is-invalid' : '' } onChange={e=>dispatch({
                                    type: 'INCLUDE_DEPENDENT_REGISTER_PATIENT',
                                    payload: {
                                        rg: e.target.value
                                    }
                                })} type="text" placeholder="Digite o seu RG" />
                                <div class="invalid-feedback">
                                    {
                                        errors?.dependent?.rg
                                    }
                                </div>
                            </Form.Group>
                        </Col>
                        <Col xs={ 12 } md={ 4 }>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="gender">Gênero</Form.Label>
                                <Form.Select value={ dependent?.gender } className={ errors?.dependent?.gender ? 'is-invalid' : '' } onChange={e=>dispatch({
                                    type: 'INCLUDE_DEPENDENT_REGISTER_PATIENT',
                                    payload: {
                                        gender: e.target.value
                                    }
                                })} id="gender">
                                    <option value="">Selecione</option>
                                    <option value="M">Masculino</option>
                                    <option value="F">Feminino</option>
                                    <option value="O">Outro</option>
                                </Form.Select>
                                <div class="invalid-feedback">
                                    {
                                        errors?.dependent?.gender
                                    }
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Form>
        </SectionWrap>
    );
}

export default function Cart(){

    const dispatch = useDispatch();
    const cartPlansRow = useRef(null);

    const { plans } = useSelector(state=>state.planState);

    const { errors, planId, modality, account, dependent, checkedTerm, unitId, reasonId, hours } = useSelector(state=>state.registerPatientState.info);
    const { isLogged, user } = useSelector(state=>state.accountState);
    const { payment, paymentInfo } = useSelector(state=>state.paymentRegisterPatientState);
    const { pathname } = useLocation();

    useEffect(()=>{
        dispatch({type: 'IS_LOADING_REGISTER_PATIENT', payload: false});
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const check = useRef(null);

    useEffect(()=>{
        if(errors.planId && !planId) cartPlansRow.current?.classList.add('cart-plans-row-is-invalid');
        else cartPlansRow.current?.classList.remove('cart-plans-row-is-invalid');
    }, [errors.planId, planId]);

    useEffect(()=>{
        utils.createNotification({
            type: 'info',
            title: 'Prossiga com o preenchimento dos campos abaixo!'
        });
        http.plan.findAll()
            .then(result=>{
                dispatch({
                    type: 'LOAD_PLAN',
                    payload: result.data.map((plan, id)=>{
                        let newObj = Object.create(plan);
                        if(id === 0){
                            newObj.image = PlanIndividual;
                            newObj.imageSelected = PlanIndividualSelected;
                        } else if(id === 1){
                            newObj.image = PlanCouple;
                            newObj.imageSelected = PlanCoupleSelected;
                        } else {
                            newObj.image = PlanChild;
                            newObj.imageSelected = PlanChildSelected;
                        }
                        return newObj;
                    })
                });
            })
            .catch(error=>utils.createNotification({
                type: 'error',
                title: 'Erro ao carregar planos',
                message: error.response.data
            }))
            .finally(()=>dispatch({
                type: 'IS_LOADING_PLAN',
                payload: false
            }));
    }, []);

    useEffect(()=>{
        dispatch({
            type: 'CLEAR_ERRORS_REGISTER_PATIENT'
        });
    }, [window.location.pathname]);
    
    const root = window.location.pathname.substring(
        window.location.pathname.indexOf('/'),
        window.location.pathname.length
    );

    const [isRegisterLoading, setIsRegisterLoading] = useState(false);

    /*const api = root === '/carrinho' ?  http.user.registerAndPayments({
        payment,
        paymentInfo,
        planId,
        modality,
        account,
        dependent,
        checkedTerm,
        unitId,
        reasonId,
        hours
    }) : http.user.registerPsychologist({
        account,
        checkedTerm
    });*/

    const register = ()=>{
        let api = null;
        setIsRegisterLoading(true);
        if(root === '/carrinho'){
            api = http.user.registerAndPayments({
                payment,
                paymentInfo,
                planId,
                modality,
                account,
                dependent,
                checkedTerm,
                unitId,
                reasonId,
                hours
            })

        } else {
            api = http.user.registerPsychologist({
                account,
                checkedTerm
            });
        }

        /*api
            .catch(error=>{
            dispatch({ type: 'SET_ERRORS_REGISTER_PATIENT', payload: error.response.data })
            utils.createNotification({
                type: 'error',
                title: 'Erro ao processar requisição',
                message: error.response.data.message || (
                    typeof error.response.data === 'object' && 'Há campos do formulário que precisam ser preenchidos!'
                ) || error?.response?.data
            });
        })*/
        
        api
            .then(()=>{
                utils.createNotification({
                    type: 'success',
                    title: 'Cadastro realizado!'
                });
                utils.createNotification({
                    type: 'success',
                    title: 'Próximos passos',
                    message: 'Entre no seu e-mail e clique no link que lhe enviamos para verificar a sua conta. Após isso, aguarde que a nossa equipe entrará em contato com você! Até mais...',
                    timing: 30000
                });
                dispatch({type: 'CLEAR_PAYMENT_REGISTER_PATIENT'});
                dispatch({type: 'CLEAR_REGISTER_PATIENT'});
            })
            .catch(error=>{
                dispatch({ type: 'SET_ERRORS_REGISTER_PATIENT', payload: error.response.data })
                utils.createNotification({
                    type: 'error',
                    title: 'Erro ao processar requisição',
                    message: error.response.data.message || (
                        typeof error.response.data === 'object' && 'Há campos do formulário que precisam ser preenchidos!'
                    ) || error?.response?.data
                });
            })
            .finally(()=>{
                setIsRegisterLoading(false);
                dispatch({type: 'IS_LOADING_REGISTER_PATIENT', payload: false})
            });
    }

    return (
        <Container id='cart' fluid>
            <Row id='section-header'>
                <Col>
                    <Header />
                </Col>
            </Row>
            <Row className='body'>
                <Col>
                    <div className='header-nav'>
                        {
                            root === '/carrinho/psicologo' ?
                                <Link to="/carrinho">Registrar como paciente</Link> :
                                <Link to="/carrinho/psicologo">Registrar como psicólogo</Link> 
                        }
                    </div>
                    {
                        root !== '/carrinho/psicologo' && (
                            <>
                                <p>1. Plano</p>
                                <p>Plano selecionado: { plans?.find(plan=>plan.id === Number(planId))?.name } { planId && '-'} { modality && utils.capitalize(modality) }</p>
                                {
                                    (isLogged && user.type === 'paciente') ?
                                    (
                                        <Row ref={ cartPlansRow }>
                                            {
                                                plans.map(plan=>(
                                                    <Col xs={ 12 } lg={ 4 }>
                                                        <Plan
                                                            id={ plan.id }
                                                            name={ plan.name }
                                                            image={ plan.image }
                                                            imageSelected={ plan.imageSelected }
                                                            description={ plan.description }
                                                            pricePresential={ plan.pricePresentialMonthly }
                                                            priceOnline={ plan.priceOnlineMonthly }
                                                        />
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                    ) : (
                                        <Home
                                            hideVideo={ true }
                                            disablePadding={ true }
                                        />
                                    )
                                }
                            </>
                        )
                    }
                    {
                        (!(isLogged && user.type === 'paciente') || root === '/carrinho/psicologo') && (
                            <>
                                <p>{root !== '/carrinho/psicologo' ? 2 : 1}. Crie a sua conta</p>
                                <CreateAccount />
                                {
                                    (root !== '/carrinho/psicologo' && Number(planId) === 3) && (
                                        <>
                                            <p>3. Dependente (caso o plano seja individual, esta seção é opcional)</p>
                                            <CreateDependent />
                                        </>
                                    )
                                }
                            </>
                        )
                    }
                    {
                        root !== '/carrinho/psicologo' && (
                            <>
                            {
                                isLogged && root === '/carrinho' && (
                                <>
                                    <p>{!(isLogged && user.type === 'paciente') ? (planId === 3 ? 4 : 3) : 2}. Selecionar forma de pagamento</p>
                                    <SelectPayment />
                                </>
                                )
                            }
                            </>
                        )
                    }
                </Col>
            </Row>
            {
                (root === '/carrinho/psicologo' ||
                (root === '/carrinho' && !isLogged)) && (
                    <>
                        <Row className='body mt-2'>
                            <Col>
                                <Form.Group className='accept-policy'>
                                <Form.Check ref={check} id="policy-check" checked={ checkedTerm } className={ 'mr-5' + (errors?.checkedTerm ? ' is-invalid' : '') } onChange={e=>dispatch({type: 'INCLUDE_POLICY_REGISTER_PATIENT', payload: { checkedTerm: e.target.checked }})} />
                                <label for="policy-check">Eu li e concordo com a <a id='policy-privacy' href='/politica-de-privacidade' target='_blank'>Política de Privacidade</a> e os <a id='policy-term' href='/termos-de-uso' target="_blank">Termos de Uso</a> da Psy Up.</label>
                                <div class="invalid-feedback">
                                    {
                                    errors?.checkedTerm
                                    }
                                </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='body finish-register'>
                            <Col>
                                {
                                    isRegisterLoading ? 
                                        <Spinner animation="border" role="status" /> :
                                        <Button onClick={()=>register()}>Registrar</Button>
                                }
                            </Col>
                        </Row>
                    </>
                )
            }
            <div className="mt-5">
                <Footer />
            </div>
        </Container>
    );
}