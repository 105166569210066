import React, { useEffect, useState } from "react";
import Header from './../../Components/HomePageHeader';
import { Container, Row, Col } from 'react-bootstrap';
import './style.css';
import BtnWhatsApp from './../../Components/ButtonWhatsApp';
import HomePageText from '../../Components/HomePageText';
import http from './../../services/http';
import utils from "../../utils";
import Footer from "../../Components/Footer";
import config from "../../config";

export default function HomePagePolicyPrivacy(){

    const [content, setContent] = useState('');

    useEffect(()=>{
        http.policy.privacy.findAll()
            .then(result=>{
                setContent(result && result.data);
            })
            .catch(error=>{
                utils.createNotification({
                    type: 'error',
                    title: 'Erro ao buscar políticas de privacidade!',
                    message: error.response.data
                })
            });
    }, []);

    return (
        <Container id='home-page' fluid>
            <BtnWhatsApp
                popupMessage="Olá, como podemos te ajudar?"
                phone={ config.phone }
            />
            <Row id='section-header'>
                <Col>
                    <Header />
                    <HomePageText type="title" text="Políticas de Privacidade" />
                    <HomePageText style={{minHeight: '100vh'}} text={ content } fullheight={ true } />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Footer />
                </Col>
            </Row>
        </Container>
    );
}